import { HttpClient } from 'helpers/http';

import { IRbacService, RolesResponse, ResourceResponse, PermissionsResponse } from 'types/services/rbac';

export class RbacService implements IRbacService {
  constructor(private client: HttpClient) {}

  API = {
    roles: '/api/rbac/roles',
    resources: '/api/rbac/resources',
    permissions: (role: string) => `/api/rbac/roles/${role}/permissions`,
  };

  getRoles = () => this.client.get<RolesResponse>(this.API.roles);

  getResources = () => this.client.get<ResourceResponse>(this.API.resources);

  getPermissions = (role: string) => {
    const url = this.API.permissions(role);
    return this.client.get<PermissionsResponse>(url);
  };

  updatePermissions = (role: string, permissions: PermissionsResponse) => {
    const url = this.API.permissions(role);
    return this.client.patch<string>(url, permissions);
  };
}
