import { Button, Spin, Modal, message } from 'antd';
import { useEffect, useState } from 'react';
import { DragDropContext, DraggingStyle, NotDraggingStyle, DropResult } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';

import { articleBlocksService } from '../../../config/services';
import { HttpError } from '../../../helpers/http';
import { ArticleBlock, ArticleBlockPayloadWithId, ArticleBlocks } from '../../../types/services/article-blocks';
import './index.scss';
import { reorder } from '../../../utility/dndHelpers';

import { ArticleBlockDrawer } from './ArticlesBlockDrawer';
import { ArticleBlocksList } from './components/ArticleBlocksList/ArticleBlocksList';

export const ArticleBlocksPage = () => {
  const { t } = useTranslation();

  const [isEditLayoutEnabled, setIsEditLayoutEnabled] = useState(false);
  const [isLoadingEditLayout, setIsLoadingEditLayout] = useState(false);
  const [oldArticleBlocks, setOldArticleBlocks] = useState<ArticleBlock[]>([]);
  const [newArticleBlocks, setNewArticleBlocks] = useState<ArticleBlock[]>([]);
  const [editedBlock, setEditedBlock] = useState<ArticleBlockPayloadWithId>();

  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [deleteBlockId, setDeleteBlockId] = useState(0);

  const [openDrawer, setOpenDrawer] = useState(false);

  const closeDrawer = () => {
    setOpenDrawer(false);
    setEditedBlock(undefined);
  };

  const createNew = () => {
    setOpenDrawer(true);
  };

  const {
    isLoading,
    isFetching,
    isError,
    refetch,
    data: response,
    error,
  } = useQuery<ArticleBlocks, HttpError>(['listBlocks'], () => articleBlocksService.list(), {
    refetchOnWindowFocus: !isEditLayoutEnabled,
  });

  const { isLoading: isDeletingBlock, mutateAsync: deleteBlock } = useMutation('deleteBlock', (id: number) =>
    articleBlocksService.delete(id),
  );

  useEffect(() => {
    if (response) {
      setOldArticleBlocks(response.data.blocks);
      setNewArticleBlocks(response.data.blocks);
    }
  }, [response]);

  if (isError) {
    return (
      <div>
        <pre>{JSON.stringify(error, undefined, 2)}</pre>
      </div>
    );
  }

  if (!response || isLoading || isFetching) {
    return (
      <div style={{ padding: '10px', textAlign: 'center' }}>
        <Spin size="large" />
      </div>
    );
  }

  if (response && !response.data.blocks.length) {
    return (
      <div className="no-data-container">
        <p>{t('articleBlock:No blocks description')}</p>
      </div>
    );
  }

  const onDragEnd = (result: DropResult) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const reorderedBlocks = reorder<ArticleBlock>(newArticleBlocks, result.source.index, result.destination.index);
    setNewArticleBlocks(reorderedBlocks);
  };

  const getListStyle = (isDraggingOver: boolean) => ({
    background: isDraggingOver ? '#efe4e3' : 'rgb(220,220,220)',
    opacity: isLoadingEditLayout && '0.5',
  });

  const getItemStyle = (
    isDragging: boolean,
    draggableStyle: DraggingStyle | NotDraggingStyle | undefined,
    block: ArticleBlock,
  ) => ({
    userSelect: 'none',
    background: isDragging ? '#dbf1e8' : '#fff',
    borderTop: isDragging && '2px solid #63b588',
    borderRight: isDragging && '2px solid #63b588',
    borderBottom: isDragging && '2px solid #63b588',
    borderLeft: `4px solid ${block.color}`,
    ...draggableStyle,
  });

  const showEditLayoutError = () => {
    message.error(t('articleBlock:Edit layout error description'));
  };

  const showDeleteBlockError = () => {
    message.error(t('articleBlock:Delete block error description'));
  };

  const showDeleteBlockSuccess = () => {
    message.success(
      `${newArticleBlocks.find((block) => block.id === deleteBlockId)?.name} ${t(
        'articleBlock:Delete block success description',
      )}`,
    );
  };

  const handleEditLayoutClicked = () => {
    setIsEditLayoutEnabled(true);
  };

  const handleCancelClicked = () => {
    setNewArticleBlocks(oldArticleBlocks);
    setIsEditLayoutEnabled(false);
  };

  const handleSaveClicked = async () => {
    setIsLoadingEditLayout(true);
    try {
      await articleBlocksService.editLayout(newArticleBlocks.map((block) => block.id));
      setOldArticleBlocks(newArticleBlocks);
    } catch (e) {
      showEditLayoutError();
      setNewArticleBlocks(oldArticleBlocks);
    } finally {
      setIsLoadingEditLayout(false);
      setIsEditLayoutEnabled(false);
    }
  };

  const handleBlockDelete = async () => {
    try {
      await deleteBlock(deleteBlockId);
      showDeleteBlockSuccess();
      setOldArticleBlocks(oldArticleBlocks.filter((block) => block.id !== deleteBlockId));
      setNewArticleBlocks(newArticleBlocks.filter((block) => block.id !== deleteBlockId));
    } catch (e) {
      showDeleteBlockError();
    } finally {
      setDeleteModalVisible(false);
    }
  };

  const handleBlockDeleteCancel = () => {
    setDeleteModalVisible(false);
  };

  const openDeleteModal = (id: number) => {
    setDeleteBlockId(id);
    setDeleteModalVisible(true);
  };

  // EDIT ARTICLE BLOCK

  const navigateToArticle = async (id: number) => {
    const response = await articleBlocksService.get(id);
    setEditedBlock({
      ...response,
      id: id,
      categoryId: response?.category?.id,
      typeId: response.type?.id,
    } as ArticleBlockPayloadWithId);
    setOpenDrawer(true);
  };

  return (
    <>
      <div className="article-block-actions">
        {isEditLayoutEnabled ? (
          <div>
            <Button type="default" onClick={() => handleCancelClicked()} className="cancel-btn">
              {t('common:Cancel')}
            </Button>
            <Button type="primary" onClick={() => handleSaveClicked()} loading={isLoadingEditLayout}>
              {t('common:Save')}
            </Button>
          </div>
        ) : (
          <>
            <Button type="primary" onClick={() => handleEditLayoutClicked()}>
              {t('articleBlock:Edit blocks layout')}
            </Button>
            <Button type="primary" className="add-btn" onClick={() => createNew()}>
              {t('menu:create-article-block')}
            </Button>
          </>
        )}
      </div>
      <ArticleBlockDrawer
        open={openDrawer}
        blockArticle={editedBlock}
        refetchArticleBlocks={refetch}
        onCancel={closeDrawer}
      />
      <DragDropContext onDragEnd={onDragEnd}>
        <ArticleBlocksList
          articleBlocks={newArticleBlocks}
          droppableId="articleBlocksDroppable"
          getListStyle={getListStyle}
          getItemStyle={getItemStyle}
          isDndDisabled={!isEditLayoutEnabled || isLoadingEditLayout}
          onDelete={openDeleteModal}
          onEdit={navigateToArticle}
        />
      </DragDropContext>
      <Modal
        title={t('common:Delete Resource', { resource: t('articleBlock:Article block'), genderSuffix: 'a' })}
        visible={deleteModalVisible}
        onOk={handleBlockDelete}
        confirmLoading={isDeletingBlock}
        onCancel={handleBlockDeleteCancel}
      >
        <p>
          {t('common:Delete Modal Text')}
          <b>{` ${newArticleBlocks.find((block) => block.id === deleteBlockId)?.name}?`}</b>
        </p>
      </Modal>
    </>
  );
};
