import { Response, Paginated, PaginationParams, TimeStampedEntity, PatchParams } from '../common';

import { ArticleBlock } from './article-blocks';
import { ArticleSubType } from './articleSubType';
import { Category } from './categories';
import { Image } from './images';
import { Tag } from './tags';
import { User } from './users';

export type TextBlock = {
  type: 'text';
  content: {
    body: string;
  };
};

export type QuoteBlock = {
  type: 'quote';
  content: {
    text: string;
    author: string;
  };
};

export type ImagesBlock = {
  type: 'images';
  content: {
    imageIds: number[];
  };
};

export type VideoBlock = {
  type: 'video';
  content: {
    src: string;
  };
};

export type RelatedArticleBlock = {
  type: 'related';
  content: {
    id: number;
    stamp?: string;
    title?: string;
    publicationTime?: Date | null;
    thumbnailImage?: Image;
  };
};

export type EmbeddedBlock = {
  type: 'embedded';
  content: {
    body: string;
  };
};

export type LineBreakBlock = {
  type: 'lineBreak';
  content: {
    body: string;
  };
};

export type BlockType =
  | TextBlock
  | QuoteBlock
  | ImagesBlock
  | VideoBlock
  | RelatedArticleBlock
  | EmbeddedBlock
  | LineBreakBlock;

export interface ArticleImage extends TimeStampedEntity {
  description: string;
  image: Image;
}

export const articleStatuses = ['pending', 'rejected', 'approved'] as const;
export type ArticleStatusArray = typeof articleStatuses; // readonly ['pending', 'rejected', 'approved']
export type ArticleStatus = ArticleStatusArray[number]; // "pending" | "rejected" | "approved"

export const articleTextTypes = [
  'Autorski tekst',
  'Agencijski tekst',
  'Tekst drugog novinara',
  'Prevedeni tekst',
  'Preuzeti tekst',
  'Promo tekst',
  'Native tekst',
] as const;
export type ArticleTextTypeArray = typeof articleTextTypes;
export type ArticleTextType = ArticleTextTypeArray[number];

export interface Article extends TimeStampedEntity {
  slug?: string;
  title: string;
  subtitle: string;
  stamp: string;
  body: BlockType[];
  category: Category;
  articleImages: ArticleImage[];
  thumbnailImage: Image | null;
  tags: Tag[];
  publicationTime?: Date | null;
  push: boolean;
  author: User;
  textType: ArticleTextType;
  block?: ArticleBlock;
  linkedArticles: RelatedArticle[];
  status: ArticleStatus;
  commentsDisabled: boolean;
  statusAudit: {
    comment?: string;
    status: ArticleStatus;
    authorName: string;
    authorId: number;
    createdAt: string;
  }[];
  articleStatistics: {
    createdAt: string;
    updatedAt: string;
    deletedAt: string | null;
    id: number;
    numberOfHits: number;
    articleId: number;
  };
  subTextType: ArticleSubType;
  hasAds: boolean;
  legacy: boolean;
  url: string;
  blockPosition?: number;
  articleType?: string;
}

export interface ArticleImagePayload {
  id: number;
  description: string;
}
export interface ArticlePayload {
  title: string;
  subtitle: string;
  body: BlockType[];
  tagIds: number[];
  images: ArticleImagePayload[];
  thumbnailImageId?: number;
  categoryId: number;
  publicationTime?: string;
  authorId?: number;
  stamp: string;
  subTextType: string;
  textType: string;
  preuzetiDescription: string;
  createdBy?: User | null;
  updatedBy?: User | null;
  hasAds: boolean;
  commentsDisabled: boolean;
}

export interface ArticleSubTypePayload {
  id?: number;
  textType: string;
  subTextType: string;
}

export interface ArticleOptions {
  id?: number | string;
  categoryIds?: number[];
  categoryId?: number;
  title?: string[];
  authorId?: number[];
  status?: string[];
  textType?: number[] | string[];
  dateFrom?: Date | string;
  dateTo?: Date | string;
  subTypeId?: number;
  email?: string;
}

export type ArticlePayloadWithId = PatchParams<ArticlePayload>;

export type PaginatedArticles = Paginated<Article>;

export type RelatedArticle = Pick<
  Article,
  'id' | 'title' | 'stamp' | 'createdAt' | 'thumbnailImage' | 'publicationTime'
> & {
  slug?: string;
};

export type ArticleStatusPayload = { comment?: string; status: ArticleStatus; body?: BlockType[]; message?: string };

export type ArticleStatusResponse = ArticleStatusPayload & { authorName: string; authorId: number; createdAt: string };

export interface IArticlesService {
  list: (params: PaginationParams) => Promise<PaginatedArticles>;
  get: (id: number) => Promise<Article>;
  delete: (id: number) => Promise<number>;
  create: (article: ArticlePayload, forcePublish: boolean) => Promise<Article>;
  patch: (article: ArticlePayloadWithId) => Promise<Article>;
  related: (tagIds: number[]) => Promise<Response<RelatedArticle[]>>;
  setStatus: (id: number, status: ArticleStatusPayload) => Promise<ArticleStatusResponse>;
}
