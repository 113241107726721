import { HttpClient } from 'helpers/http';

import { PaginationParams, PatchParams } from 'types/common';
import { PaginatedCustomers, ICustomersService, Customer } from 'types/services/customers';

export class CustomersService implements ICustomersService {
  constructor(private client: HttpClient) {}

  API = {
    list: '/api/customers',
  };

  patch = ({ id, ...data }: PatchParams<Customer>) => {
    const url = `${this.API.list}/${id}`;
    return this.client.patch<Customer>(url, data);
  };

  list = ({ page, perPage, filters }: PaginationParams) => {
    let url = `${this.API.list}?page=${page}&perPage=${perPage}`;
    // TODO: Extract to shared folder
    if (filters) {
      for (const [key, value] of Object.entries(filters)) {
        if (!!value) url += `&${key}=${value}`;
      }
    }
    return this.client.get<PaginatedCustomers>(url);
  };
  delete = (id: number) => {
    const url = `${this.API.list}/${id}`;
    return this.client.remove<Customer>(url);
  };
}
