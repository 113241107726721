import { SmileOutlined, UserOutlined } from '@ant-design/icons';
import { Form, Input, Button, notification } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { authService } from 'config/services';

import './index.scss';

export const InitResetPassword = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();
  const history = useHistory();

  const onFinish = async (values: any) => {
    const { email } = values;
    setIsLoading(true);
    await authService.initResetPassword(email);
    form.resetFields();
    openNotification();
    setIsLoading(false);
    history.push('/login');
  };

  const onFinishFailed = (errorInfo: any) => {
    // TODO: Add error handler
    // console.log('Failed:', errorInfo);
  };

  const openNotification = () => {
    notification.open({
      message: t('users:Password reset confirmed!'),
      description: t('users:Please check your inbox!'),
      icon: <SmileOutlined style={{ color: '#333' }} />,
    });
  };

  return (
    <div className="reset-container">
      <div className="container">
        <div className="reset-page">
          <Form
            name="normal_reset"
            form={form}
            className="reset-form"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <p style={{ paddingBottom: '10px' }} className="title">
              {t('users:Forgot password')}
            </p>
            <Form.Item name="email" rules={[{ required: true, message: t('users:Please input your Email!') }]}>
              <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Email" />
            </Form.Item>
            <Form.Item>
              <Button
                style={{ marginBottom: '24px' }}
                loading={isLoading}
                type="primary"
                htmlType="submit"
                className="reset-form-button"
              >
                {t('users:Send reset confirmation mail!')}
              </Button>
              <Button
                onClick={() => {
                  history.push('/login');
                }}
                style={{ marginTop: '10px' }}
              >
                {t('users:Back to login')}
              </Button>
            </Form.Item>
          </Form>
        </div>
        <div className="logo">
          <img alt="logo" src="/avaz-logo.png" />
        </div>
      </div>
    </div>
  );
};
