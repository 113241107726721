import { keyMirror } from 'helpers/keyMirror';

import { Status } from 'types/common';

export const ActionTypes = keyMirror({
  // SESSION

  LOGIN_REQUEST: undefined,
  LOGIN_SUCCESS: undefined,
  LOGIN_FAILURE: undefined,

  VALIDATE_TOKEN_REQUEST: undefined,
  VALIDATE_TOKEN_SUCCESS: undefined,
  VALIDATE_TOKEN_FAILURE: undefined,

  LOGOUT: undefined,

  LOAD_PERMISSIONS: undefined,

  // SIDEBAR

  REGISTER_ENTRIES: undefined,
  TOGGLE_SUBMENU: undefined,
  CLOSE_ALL_SUBMENUS: undefined,
});

export const STATUS: Status = {
  IDLE: 'idle',
  RUNNING: 'running',
  READY: 'ready',
  SUCCESS: 'success',
  ERROR: 'error',
};
