import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

import { IRoute } from 'types/common';
import { PermissionsMap } from 'types/services/auth';
import { StoreState } from 'types/store';

interface Props extends IRoute {
  isAuthenticated: boolean;
  to?: string;
}

export function RoutePrivate({ component: Component, isAuthenticated, to = '/login', ...rest }: Props) {
  const permissionMap = useSelector<StoreState, PermissionsMap>(({ session }) => session.permissions);
  const routeAccessible = rest.path ? permissionMap[rest.path as string]?.view ?? true : true;
  return (
    <Route
      {...rest}
      render={(props) => {
        return isAuthenticated ? (
          routeAccessible ? (
            Component && <Component {...props} />
          ) : null
        ) : (
          <Redirect
            to={{
              pathname: to,
              state: { redirect: props.location.pathname, isAuthenticated },
            }}
          />
        );
      }}
    />
  );
}
