import { PictureOutlined, EditOutlined, DeleteOutlined, EyeOutlined } from '@ant-design/icons';
import { Image, Tooltip } from 'antd';
import { MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';

import './ImageRow.scss';

interface ImageRowProps {
  uid: string;
  index?: number;
  src: string;
  text: string;
  onImageEdit: (uid: string) => void;
  onDelete?: Function;
  onThumbnailSelect?: Function;
}

const ImageRow = ({ index, uid, src, text, onImageEdit, onDelete, onThumbnailSelect }: ImageRowProps) => {
  const { t } = useTranslation();

  const stopEvent = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const handleEditImage: MouseEventHandler<HTMLDivElement> = (e) => {
    stopEvent(e);
    onImageEdit(uid);
  };

  const handleSetImageAsThumnailImage: MouseEventHandler<HTMLDivElement> = (e) => {
    stopEvent(e);
    onThumbnailSelect?.(parseInt(uid));
  };

  const handleDelete: MouseEventHandler<HTMLDivElement> = (e) => {
    stopEvent(e);
    onDelete?.();
  };

  return (
    <div className="image-row relative flex items-center">
      <span className="image-row-index">{index}</span>
      <Image
        src={src}
        height={35}
        width={35}
        preview={{
          mask: (
            <div className="ant-image-mask relative flex flex-wrap h-full w-full">
              <div className="image-action-btn" role="button" aria-hidden="true" tabIndex={0}>
                <Tooltip title={t('common:View')}>
                  <EyeOutlined />
                </Tooltip>
              </div>
            </div>
          ),
        }}
      />
      <a target="_blank" rel="noopener noreferrer" className="image-row-text" href={src}>
        {text || t('common:Download')}
      </a>
      <div className="image-row-actions">
        <Tooltip title={t('article:Cover image')}>
          <PictureOutlined onClick={handleSetImageAsThumnailImage} />
        </Tooltip>
        <Tooltip title={t('common:Edit')}>
          <EditOutlined onClick={handleEditImage} />
        </Tooltip>
        <Tooltip title={t('common:Delete')}>
          <DeleteOutlined onClick={handleDelete} />
        </Tooltip>
      </div>
    </div>
  );
};

export default ImageRow;
