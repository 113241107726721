import { Button } from 'antd';
import cx from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsCardImage } from 'react-icons/bs';
import { GrTextAlignFull } from 'react-icons/gr';
import { ImPageBreak } from 'react-icons/im';
import { MdFormatQuote } from 'react-icons/md';
import { RiTextWrap } from 'react-icons/ri';
import { RiCodeBoxLine } from 'react-icons/ri';
import { VscPlay, VscAdd } from 'react-icons/vsc';

interface ToolbarProps {
  disableGallery: boolean;
  onClick: (type: string) => void;
}

const Toolbar = ({ onClick, disableGallery }: ToolbarProps) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);

  const menuItems = [
    { icon: <GrTextAlignFull />, label: t('article:Text'), type: 'text' },
    { icon: <MdFormatQuote />, label: t('article:Quote'), type: 'quote' },
    { icon: <BsCardImage />, label: t('article:Image'), type: 'images' },
    { icon: <VscPlay />, label: t('article:Video'), type: 'video' },
    { icon: <RiTextWrap />, label: t('article:Related article'), type: 'related' },
    { icon: <RiCodeBoxLine />, label: t('article:External content'), type: 'embedded' },
    { icon: <ImPageBreak />, label: t('article:Line break'), type: 'lineBreak' },
  ];

  return (
    <menu className="flex blocks-editor-toolbar" key="menu-li">
      <li role="menuitem" key="first-li" className="blocks-editor-toolbar-item">
        <Button
          type="text"
          icon={<VscAdd style={{ color: '#8b8b8b' }} />}
          title={t(`common:${expanded ? 'Close' : 'Open'}`)}
          className={cx({ rotated: expanded })}
          onClick={() => setExpanded(() => !expanded)}
        />
      </li>

      <div className={cx('block-editor-toolbar-menu', { expanded })}>
        {menuItems.map((item) => (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events

          <li key={item.label} role="menuitem" className="blocks-editor-toolbar-item">
            <Button
              type="text"
              icon={item.icon}
              title={item.label}
              onClick={() => onClick(item.type)}
              style={{ color: '#8b8b8b' }}
            />
          </li>
        ))}
      </div>
    </menu>
  );
};

export default Toolbar;
