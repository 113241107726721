import { Modal, Table, Row } from 'antd';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

import { ArticleStatusResponse } from 'types/services/articles';

export interface StatusAuditModalProps {
  visible: boolean;
  statusAudit: ArticleStatusResponse[];
  onCancel: () => void;
}

const { Column } = Table;

const StatusAuditModal = ({ visible, statusAudit, onCancel }: StatusAuditModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal
      width={900}
      centered
      mask={true}
      maskClosable={false}
      onCancel={onCancel}
      visible={visible}
      footer={false}
      title={
        <div>
          <span>{t('article:Changes')}</span>
        </div>
      }
    >
      <div style={{ padding: '10px' }}>
        <Row gutter={[8, 16]}>
          <Table bordered sticky size="small" dataSource={statusAudit} pagination={{ hideOnSinglePage: true }}>
            <Column
              title={t('common:Updated At')}
              dataIndex="createdAt"
              width={100}
              render={(createdAt: string) =>
                DateTime.fromISO(createdAt).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)
              }
            />
            <Column
              title={t('article:Status')}
              dataIndex="status"
              width={100}
              render={(status: string) => t(`article:Status ${status}`)}
            />
            <Column title={t('article:Comment')} dataIndex="comment" />
            <Column title={t('article:Author')} dataIndex="authorName" width={100} />
          </Table>
        </Row>
      </div>
    </Modal>
  );
};

export default StatusAuditModal;
