export interface IRbacService {
  getRoles: () => Promise<RolesResponse>;
  getResources: () => Promise<ResourceResponse>;
  getPermissions: (role: string) => Promise<PermissionsResponse>;
  updatePermissions: (role: string, permissions: PermissionsResponse) => Promise<string>;
}

export interface Role {
  name: string;
  permissions: string[][];
}

export interface RolesResponse {
  roles: Role[];
}

export interface ResourceResponse {
  resources: {
    resource: string;
    actions: string[];
  }[];
}

export interface PermissionsResponse {
  permissions: string[][];
}

export const ADMIN_ROLE = 'ADMINISTRATOR';
