import { ConfigProvider } from 'antd';
import hr_HR from 'antd/lib/locale/hr_HR';
import { Settings } from 'luxon';
import React from 'react';
import { QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { Route, Router } from 'react-router-dom';
import { PersistGate } from 'redux-persist/lib/integration/react';

import { errorBus } from 'config/error-bus';
import { history } from 'config/history';
import { queryClient } from 'config/query-client';

import { ErrorBoundary } from 'containers/ErrorBoundary';

import { Root } from 'routes/Root';

import { configStore } from 'store/index';

import 'config/interceptor';
import 'i18n/index';

const { store, persistor } = configStore();

window.store = store;
window.onerror = (msgOrEvent: string | Event) => {
  errorBus.publish('WINDOW/ONERROR', new Error(typeof msgOrEvent === 'string' ? msgOrEvent : 'Unknown error'));
};

Settings.defaultLocale = hr_HR.locale;

export const App = () => (
  <React.StrictMode>
    <ErrorBoundary bus={errorBus}>
      <Provider store={store}>
        <PersistGate loading={<span>Loading...</span>} persistor={persistor}>
          <QueryClientProvider client={queryClient}>
            <Router history={history}>
              <ConfigProvider locale={hr_HR}>
                <Route component={Root}></Route>
              </ConfigProvider>
            </Router>
          </QueryClientProvider>
        </PersistGate>
      </Provider>
    </ErrorBoundary>
  </React.StrictMode>
);
