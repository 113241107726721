import { IRoute } from 'types/common';

import { Login } from './Login';

export const route: IRoute = {
  component: Login,
  exact: true,
  key: 'login',
  path: '/login',
};
