import { PictureOutlined } from '@ant-design/icons';
import { Button, Modal, Input, Spin, Pagination, message } from 'antd';
import { toNumber } from 'lodash';
import throttle from 'lodash/throttle';
import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { addOrRemove } from 'utility';

import { FileUploadButton } from 'components/FileUploadButton';

import { imagesService } from 'config/services';

import { HttpError } from 'helpers/http';
import { getBase64 } from 'helpers/shared';

import type { Pagination as PaginationType } from 'types/common';
import { FileExtended } from 'types/files';
import { Image, ImageUpdate, PaginatedImages } from 'types/services/images';

import { EditImageModal } from '../EditImageModal/EditImageModal';
import { SelectableImage } from '../SelectableImage/SelectableImage';

import { ImageDrawer } from './ImageDrawer';

import './style.scss';

const { Search } = Input;

export type SelectedImagesData = {
  images: Image[];
  selectedIds: number[];
};

interface ImageLibraryModalProps {
  open: boolean;
  alreadySelectedIds: number[];
  maxCount?: number;
  onClose: () => void;
  onConfirm: (data: SelectedImagesData) => void;
}

const initPagination = { page: 1, perPage: 40, total: 0 };

export const ImageLibraryModal = ({
  open,
  maxCount,
  alreadySelectedIds,
  onClose,
  onConfirm,
}: ImageLibraryModalProps) => {
  const { t } = useTranslation();
  const [pagination, setPagination] = useState<PaginationType>(initPagination);
  const [searchText, setSearchText] = useState('');
  const [selectedImageIds, setSelectedImageIds] = useState<number[]>([]);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [editedImage, setEditedImage] = useState<ImageUpdate>();
  const selectedImages = useRef<Image[]>([]);
  const [uploadedFile, setUploadedFile] = useState<FileExtended | undefined>();
  const {
    isLoading,
    data: paginatedImages,
    refetch,
  } = useQuery<PaginatedImages, HttpError>(['listImages'], () =>
    imagesService.list({ ...pagination, filters: { description: searchText } }),
  );
  const throttledRefetch = useRef(throttle(refetch, 500));

  const { data: images, pagination: responsePagination } = paginatedImages || {};

  useEffect(() => {
    throttledRefetch.current();
  }, [pagination, searchText]);

  useEffect(() => {
    setSelectedImageIds(alreadySelectedIds);
    selectedImages.current = [];
  }, [alreadySelectedIds]);

  const handlePaginationChange = (page: number, perPage: number) => {
    setPagination({ page, perPage, total: 0 });
  };

  const resetState = () => {
    setPagination(initPagination);
    setSearchText('');
    setSelectedImageIds([]);
    selectedImages.current = [];
  };

  const confirmSelection = () => {
    setSelectedImageIds([]);
    onConfirm({ images: selectedImages.current, selectedIds: selectedImageIds });
    resetState();
  };

  const onSearchChange = (e: any) => {
    setSearchText(e.target.value);
  };

  const handleImageSelection = (id: string) => {
    let ids = addOrRemove<number>(selectedImageIds, parseInt(id));
    if (maxCount && ids.length > maxCount) {
      ids = [Number(id)];
    }
    setSelectedImageIds(ids);
    const numId = parseInt(id);
    if (ids.includes(numId)) {
      const image = images?.find((i) => i.id === numId);
      image && selectedImages.current.push(image);
    } else {
      selectedImages.current = selectedImages.current.filter((i) => i.id !== numId);
    }
  };

  const onModalClose = () => {
    resetState();
    onClose();
  };

  const handleEditedImage = async (image: FileExtended) => {
    try {
      await imagesService.upload({ onProgress: () => {}, file: image });
      message.success(t('images:Successful image upload'));
      closeEditImageModal();
    } catch (error: any) {
      message.error(t('images:Failed image upload'));
    }
    const page = Math.ceil((responsePagination?.total || 0 + 1) / pagination.perPage);
    setSearchText('');
    setPagination({
      ...pagination,
      page,
    });
  };

  const handleSelectedFile = async (file: File) => {
    (file as FileExtended).url = await getBase64(file);
    setUploadedFile(file as FileExtended);
  };

  const closeEditImageModal = () => {
    setUploadedFile(undefined);
  };

  const editImage = (id: string) => {
    //go through images data and find the image you clicked on to be edited by image id
    const image = images?.find((i) => i.id === toNumber(id));
    setEditedImage(image as ImageUpdate);
    setOpenDrawer(true);
  };

  const closeDrawer = () => {
    setEditedImage(undefined);
    setOpenDrawer(false);
  };

  return (
    <>
      <Modal
        centered
        width={1540}
        bodyStyle={{ paddingLeft: 19 }}
        mask={true}
        maskClosable={false}
        onCancel={onModalClose}
        visible={open}
        title={
          <div>
            <PictureOutlined style={{ marginRight: '5px' }} />
            <span>{t('images:Image library')}</span>
          </div>
        }
        footer={null}
        // footer={[
        //   <Button key="submit" type="primary" onClick={confirmSelection}>
        //     {t('common:Confirm')}
        //   </Button>,
        // ]}
      >
        <div>
          <div>
            <Search
              className="image-search"
              enterButton
              value={searchText}
              onChange={onSearchChange}
              loading={isLoading}
            />
            <FileUploadButton handleFile={handleSelectedFile} />
          </div>

          {isLoading ? (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Spin size="large" />
            </div>
          ) : (
            <div>
              <div className="image-library-grid">
                {(images || []).map((image: Image) => (
                  <SelectableImage
                    id={`${image.id}`}
                    uid={image.id.toString()}
                    key={image.id}
                    height={150}
                    className="image-library-item"
                    selected={selectedImageIds.includes(image.id)}
                    width={150}
                    src={image.imageUrl}
                    onSelection={handleImageSelection}
                    onImageEdit={editImage}
                    createAt={image.createdAt}
                    description={image.description}
                  />
                ))}
              </div>
            </div>
          )}
        </div>
        <div className="flex mt-4 justify-center">
          <Pagination
            defaultCurrent={1}
            current={pagination.page}
            total={responsePagination?.total}
            pageSize={pagination.perPage}
            onChange={handlePaginationChange}
            hideOnSinglePage
            disabled={isLoading}
          />
          <div className="flex" style={{ marginLeft: '10px' }}>
            <Button key="submit" type="primary" onClick={confirmSelection}>
              {t('common:Confirm')}
            </Button>
          </div>
        </div>
      </Modal>
      <EditImageModal
        image={uploadedFile}
        visible={!!uploadedFile}
        onConfirm={handleEditedImage}
        onCancel={closeEditImageModal}
      />
      <ImageDrawer open={openDrawer} image={editedImage} refetchImages={refetch} onCancel={closeDrawer} />
    </>
  );
};
