import { createBrowserHistory } from 'history';

export const createHistory = () => {
  const history = createBrowserHistory();

  history.location = {
    ...history.location,
    state: {},
  };

  /* istanbul ignore next */
  history.listen(() => {
    history.location = {
      ...history.location,
      state: history.location.state || {},
    };
  });

  return history;
};
