import { Pie, PieConfig } from '@ant-design/plots';
import { Empty } from 'antd';
import { useMemo } from 'react';

export const PieChart = (categories: any) => {
  let allCategories = [
    { title: 'Vijesti', cnt: 0, color: '#ed1c24' },
    { title: 'Sport', cnt: 0, color: '#339933' },
    { title: 'Showbiz', cnt: 0, color: '#8b1e8a' },
    { title: 'Lifestyle', cnt: 0, color: '#f6c543' },
    { title: 'Sci-tech', cnt: 0, color: '#5895c2' },
  ];
  const data = useMemo(() => {
    const data = [];
    //let totalCount = 0;

    if (categories.props !== undefined) {
      for (let i = 0; i < allCategories.length; i++) {
        for (let j = 0; j < categories.props.data.length; j++) {
          if (categories.props.data[j].title === allCategories[i].title) {
            allCategories[i].cnt = categories.props.data[j].cnt;
          }
        }
      }

      // for (let i = 0; i < categories.props.data.length; i++) {
      //   data[i].title += ' ' + ((Number(data[i].value) / totalCount) * 100).toFixed(1) + '%';
      // }
    }

    for (let i = 0; i < allCategories.length; i++) {
      data.push({
        title: allCategories[i].title,
        value: Number(allCategories[i].cnt),
        modifiedTitle: '',
      });
      //totalCount += Number(categories.props.data[i].cnt);
    }

    return data;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories]);

  const config: PieConfig = {
    animation: {
      update: false,
      enter: false,
      leave: false,
      appear: false,
    },
    appendPadding: 10,
    data,
    legend: {
      layout: 'horizontal',
      position: 'bottom',
      flipPage: false,
      content: ({ current }: any) => `${current} + 100`,
    },
    tooltip: {
      //showContent: false,
    },
    angleField: 'value',
    colorField: 'title',
    color: ['#ed1c24', '#339933', '#8b1e8a', '#f6c543', '#5895c2'] /*({ title }) => {
      for (let i = 0; i < allCategories.length; i++) {
        //title.substring(0, title.indexOf(' ')) je islo umjesto title
        if (title === allCategories[i].title) return allCategories[i].color;
      }
    },*/,
    radius: 0.9,
    label: {
      type: 'inner',
      offset: '-30%',
      content: ({ percent }) => `${(percent * 100).toFixed(1)}%`,
      style: {
        fontSize: 14,
        textAlign: 'center',
      },
    },
    interactions: [
      {
        type: 'element-active',
      },
      {
        type: 'legend-filter',
        enable: false,
      },
    ],
  };

  return <>{data && data.length > 0 ? <Pie {...config} /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}</>;
};
