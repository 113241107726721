import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { SessionState, StoreState } from 'types/store';

export const ArticleType = () => {
  const history = useHistory();
  //TODO:provjeriti da li postoji bolji i efikasniji nacin za promjenu rute
  const changeRoute = (type: string) => {
    history.push('/article/new?type=' + type);
  };
  const { user } = useSelector<StoreState, SessionState>(({ session }) => session);
  return (
    <>
      <span className="header-style">Odaberite vrstu članka</span>
      <div className={`${user?.role !== 'COMMERCIALIST' ? 'container-types' : 'container-types-commercialist'}`}>
        <div
          className={`${user?.role !== 'COMMERCIALIST' ? 'type-image' : 'type-image-comm'}`}
          style={{ paddingLeft: '75px' }}
          onClick={() => changeRoute('standard')}
          role="button"
          aria-hidden
        >
          <img src="/type1.png" alt="type1"></img>
          <span>Standardni članak</span>
        </div>
        {user?.role !== 'COMMERCIALIST' && user?.role !== 'ALFATV' && (
          <div className="type-image" onClick={() => changeRoute('mega')} role="button" aria-hidden>
            <img src="/type2.png" alt="type2"></img>
            <span>Mega članak</span>
          </div>
        )}
        {user?.role !== 'COMMERCIALIST' && user?.role !== 'ALFATV' && (
          <div className="type-image" onClick={() => changeRoute('video')} role="button" aria-hidden>
            <img src="/type3.png" alt="type3"></img>
            <span>Video članak</span>
          </div>
        )}
        {user?.role !== 'ALFATV' && (
          <div
            className="type-image"
            style={{ paddingRight: '75px' }}
            onClick={() => changeRoute('special')}
            role="button"
            aria-hidden
          >
            <img src="/type4.png" alt="type4"></img>
            <span>Specijalni promo članak</span>
          </div>
        )}
      </div>
    </>
  );
};
