import { EditTwoTone, EyeOutlined } from '@ant-design/icons';
import { Row, Table, Spin, DatePicker, Tag, Tooltip, Button } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { activityLogService, usersService } from 'config/services';

import { HttpError } from 'helpers/http';

import { Articles } from 'routes/articles/Articles';
import { routes } from 'routes/routes';

import { PaginationParams } from 'types/common';
import { ActivityLog, ActivityLogOptions, PaginatedActivityLog } from 'types/services/activity-log';
import { PaginatedUsers } from 'types/services/users';

import { transformLogs } from './utils';

export const ActivityLogDetail = () => {
  const { Column } = Table;
  const { t } = useTranslation();
  const history = useHistory();
  const { id } = useParams<{ id?: string }>();
  const [pagination, setPagination] = useState<PaginationParams>({ page: 1, perPage: 10 });
  const [filters, setFilters] = useState<ActivityLogOptions>({});
  const [articleId, setArticleId] = useState<any>(null);

  const [isBottomVisible, setIsBottomVisible] = useState(false);

  const {
    isLoading: isLoadingSingle,
    isFetching: isFetchingSingle,
    isError: isErrorSingle,
    data: responseSingle,
    error: errorSingle,
    refetch: refetchSingle,
  } = useQuery<ActivityLog, HttpError>(['get'], () => activityLogService.get(Number(id)), {
    enabled: typeof id !== undefined,
  });

  const responseSingleArticleId = responseSingle?.article.id;

  const {
    isLoading,
    isFetching,
    isError,
    data: response,
    error,
    refetch,
  } = useQuery<PaginatedActivityLog, HttpError>(
    ['list', Number(responseSingleArticleId)],
    () => activityLogService.list(pagination, { articleId: Number(responseSingleArticleId) }),
    {
      // The query will not execute until the userId exists
      enabled: !!responseSingle && typeof id !== undefined,
    },
  );

  const {
    isError: isErrorUsers,
    data: paginatedUsers,
    error: usersError,
  } = useQuery<PaginatedUsers, HttpError>('listUsers', () =>
    usersService.list({ page: 1, perPage: 1000, filters: {} }),
  );

  useEffect(() => {
    refetch();
  }, [pagination, filters, refetch]);

  function onPaginationChange(page: number) {
    setPagination((previousPagination) => ({ ...previousPagination, page }));
  }

  function onShowSizeChange(current: number, pageSize: number) {
    setPagination({ ...pagination, page: current, perPage: pageSize });
  }

  let userArr: Array<number> = [];

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    setFilters({ user: filters.user });
    setPagination((p) => ({ ...p, page: 1 }));
  };

  if (isError || isErrorUsers || isErrorSingle) {
    return (
      <div>
        <pre>{JSON.stringify(error || usersError, undefined, 2)}</pre>
      </div>
    );
  }

  if (!response || !paginatedUsers || !responseSingle) {
    return (
      <div style={{ padding: '10px', textAlign: 'center' }}>
        <Spin size="large" />
      </div>
    );
  }

  const users = paginatedUsers.data;
  const userFilterOptions = users.map((user) => ({ text: `${user.firstName} ${user.lastName}`, value: user.id }));

  userFilterOptions.map((u) => userArr.push(u.value));

  function transform(logs: ActivityLog[]) {
    return logs.map((log) => transformLogs(log));
  }

  function transformSingle(body: any) {
    const changes = [];
    for (var key of Object.keys(body)) {
      const element = body[key];
      for (var key2 of Object.keys(element)) {
        const newValue = element[key2];
        const temp = {
          key: key2,
          oldValue: '',
          type: key,
          newValue: '',
        };
        if (key === 'added') {
          temp.newValue = JSON.stringify(newValue, null, 2);
        } else if (key === 'deleted') {
          temp.oldValue = JSON.stringify(body.oldDeleted[key2], null, 2);
          temp.newValue = '';
        } else if (key === 'updated') {
          temp.oldValue = JSON.stringify(body.oldValues[key2], null, 2);
          temp.newValue = JSON.stringify(newValue, null, 2);
        }
        changes.push(temp);
      }
    }

    return changes;
  }

  const translateEvent = (event: string) => {
    let color = 'green';
    if (event === 'delete') {
      color = 'red';
    } else if (event === 'update') {
      color = 'orange';
    }
    return <Tag color={color}>{t(`article:${event}`)}</Tag>;
  };

  const { data: logs } = response;

  return (
    <>
      <div style={{ display: 'flex' }} className={'logTable'}>
        <div style={{ padding: '10px', textAlign: 'center', width: '70%' }}>
          <Row gutter={[8, 16]}>
            <Table
              bordered
              sticky
              size="middle"
              loading={isLoadingSingle || isFetchingSingle}
              dataSource={transformSingle(responseSingle.body)}
              pagination={{
                position: ['bottomCenter'],
              }}
            >
              <Column
                key="key"
                dataIndex="key"
                title={t('common:Description')}
                //filters={userFilterOptions}
                //filterSearch
                width={'120px'}
              />
              <Column
                key="oldValue"
                dataIndex="oldValue"
                title={t('common:Old value')}
                //filters={userFilterOptions}
                //filterSearch
                render={(text, record: any) => (
                  <>
                    <pre>{record.oldValue}</pre>
                  </>
                )}
              />
              <Column
                key="type"
                dataIndex="type"
                title={t('common:Action')}
                width={'70px'}
                //filters={userFilterOptions}
                //filterSearch
              />
              <Column
                key="newValue"
                dataIndex="newValue"
                title={t('common:New value')}
                //filters={userFilterOptions}
                //filterSearch
                render={(text, record: any) => (
                  <>
                    <pre>{record.newValue}</pre>
                  </>
                )}
              />

              {/* <Column
                key="createdAt"
                dataIndex="createdAt"
                title={t('common:Actions')}
                //filters={userFilterOptions}
                filterSearch
                render={(text, record: any) => (
                  <a style={{ color: '#598ad9' }} href={'/activity-log/' + record.id}>
                    <Tooltip title={t('common:Edit')}>
                      <Button shape="round" icon={<EditTwoTone />} />
                    </Tooltip>
                  </a>
                )}
              /> */}
            </Table>
          </Row>
        </div>
        <div style={{ padding: '10px', textAlign: 'center', width: '30%' }}>
          <Row gutter={[8, 16]} style={{ minHeight: '70%' }}>
            <Table
              bordered
              sticky
              size="middle"
              loading={isLoading || isFetching}
              dataSource={transform(logs)}
              pagination={{
                onChange: onPaginationChange,
                onShowSizeChange,
                size: 'default',
                position: ['bottomCenter'],
                showSizeChanger: true,
                showLessItems: true,
                defaultPageSize: 10,
              }}
              onChange={handleTableChange}
            >
              <Column
                key="event"
                dataIndex="event"
                title={t('common:Action')}
                //filters={userFilterOptions}
                filterSearch
                render={translateEvent}
                align={'center'}
                width={'80px'}
              />
              <Column key="user" dataIndex="user" title={t('common:User')} filters={userFilterOptions} filterSearch />
              {/* <Column
              key="body"
              dataIndex="body"
              title={t('common:Changes')}
              filters={userFilterOptions}
              filterSearch
            /> */}
              <Column
                key="createdAt"
                dataIndex="createdAt"
                title={t('common:Created At')}
                //filters={userFilterOptions}
                filterSearch
              />
              <Column
                key="createdAt"
                dataIndex="createdAt"
                title={t('common:Actions')}
                //filters={userFilterOptions}
                filterSearch
                width={'80px'}
                align={'center'}
                render={(text, record: any) => (
                  <a style={{ color: '#598ad9' }} href={'/activity-log/' + record.id}>
                    <Tooltip title={t('common:Open')}>
                      <Button shape="round" icon={<EyeOutlined />} />
                    </Tooltip>
                  </a>
                )}
              />
            </Table>
          </Row>
        </div>
      </div>
    </>
  );
};
