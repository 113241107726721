import { TableOutlined } from '@ant-design/icons';

import { withNavigation } from 'containers/Navigation';

import { IRoute, Submenu } from 'types/common';

import { ArticleBlockPage } from './ArticleBlockPage/ArticleBlockPage';
import { ArticleBlocksEdit } from './ArticleBlocksEdit/ArticleBlocksEdit';
import { ArticleBlocksPage } from './ArticlesBlocksPage/ArticleBlocksPage';

export const routes: IRoute[] = [
  {
    component: withNavigation(ArticleBlocksEdit),
    exact: true,
    key: 'edit-blocks',
    path: '/article-blocks/edit',
    isPrivate: true,
  },
  {
    component: withNavigation(ArticleBlocksPage),
    exact: true,
    key: 'list-article-blocks',
    path: '/article-blocks',
    isPrivate: true,
  },
  {
    component: withNavigation(ArticleBlockPage),
    exact: true,
    key: 'article-block',
    path: '/article-block/:id',
    isPrivate: true,
  },
];

export const submenu: Submenu = {
  key: 'index',
  icon: TableOutlined,
  rank: 3,
  items: routes.slice(0, routes.length - 1).map((route) => ({ key: route.key, path: route.path as string })),
};
