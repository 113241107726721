import axios, { AxiosRequestConfig, AxiosInstance, AxiosResponse, AxiosError } from 'axios';

import { HttpError } from './HttpError';

export class HttpClient {
  instance: AxiosInstance;

  constructor(config: AxiosRequestConfig) {
    this.instance = axios.create(config);

    this.instance.interceptors.response.use((response) => Promise.resolve(this.onResponse(response)));
    this.instance.interceptors.request.use((request) => Promise.resolve(this.onRequest(request)));
  }

  // PRIVATE METHODS

  private onResponse = (response: AxiosResponse) => {
    if (!response.data) {
      response.data = {};
    }
    return response;
  };

  private onRequest = (request: AxiosRequestConfig) => {
    if (!request.headers) {
      request.headers = {};
    }

    return request;
  };

  // PUBLIC METHODS

  convertError = (error: AxiosError) => {
    if (error.response) {
      const { data: payload, status, statusText } = error.response;
      return new HttpError({ payload, status, message: statusText });
    }

    const { message } = error;

    // Canceled by user action
    if (axios.isCancel(error)) {
      return new HttpError({ message, canceled: true, network: true });
    }

    // Timeout exceeded
    if (error.code === 'ECONNABORTED') {
      return new HttpError({ message, timeout: true, network: true });
    }

    //  Network error
    return new HttpError({ message, network: false });
  };

  execute = async <T>(options: AxiosRequestConfig = {}) => {
    const response = await this.instance(options);
    return response.data as T;
  };

  get = <T>(url: string, options?: AxiosRequestConfig) => {
    return this.execute<T>({ url, ...options });
  };

  post = <T>(url: string, data?: Object, options?: AxiosRequestConfig) => {
    const request = Object.assign({}, options, { data, method: 'POST' });
    return this.execute<T>({ url, ...request });
  };

  put = <T>(url: string, data?: Object, options?: AxiosRequestConfig) => {
    const request = Object.assign({}, options, { data, method: 'PUT' });
    return this.execute<T>({ url, ...request });
  };

  patch = <T>(url: string, data?: Object, options?: AxiosRequestConfig) => {
    const request = Object.assign({}, options, { data, method: 'PATCH' });
    return this.execute<T>({ url, ...request });
  };

  remove = <T>(url: string, options?: AxiosRequestConfig) => {
    const request = Object.assign({}, options, { method: 'DELETE' });
    return this.execute<T>({ url, ...request });
  };
}
