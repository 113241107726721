import { AxiosRequestConfig } from 'axios';
import { objectToParamString } from 'utility';

import { HttpClient } from 'helpers/http';

import { PaginationParams } from 'types/common';
import { ICoverImagesService, CoverImage, PaginatedImages } from 'types/services/coverImages';

export class CoverImagesService implements ICoverImagesService {
  constructor(private client: HttpClient) {}

  API = {
    root: '/api/coverImage',
  };

  get = () => {
    const url = this.API.root;
    return this.client.get<CoverImage>(url);
  };

  create = async (options: any) => {
    const { onProgress, file } = options;
    const url = this.API.root;

    const imageData = [{ description: file.description || '', source: file.source }];

    const formData = new FormData();
    formData.append('images', file);
    formData.append('imagesData', JSON.stringify(imageData));

    const config: AxiosRequestConfig = {
      headers: { 'content-type': 'multipart/form-data' },
      onUploadProgress: onProgress,
    };

    return this.client.post<{ image: CoverImage }>(url, formData, config);
  };

  list = ({ page, perPage, filters }: PaginationParams) => {
    let url = objectToParamString(filters, `${this.API.root}/list?page=${page}&perPage=${perPage}`);
    return this.client.get<PaginatedImages>(url);
  };

  remove = (id: number) => this.client.remove<void>(`${this.API.root}/${id}`);
}
