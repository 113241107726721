import { DeleteTwoTone } from '@ant-design/icons';
import { Button, Input, InputNumber, message, Modal, Row, Space, Spin, Table, Tooltip } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useLocation, useParams } from 'react-router-dom';
import './index.scss';

import { commentsService } from '../../../config/services';
import { HttpError } from '../../../helpers/http';
import { PaginationParams } from '../../../types/common';
import { Comment, PaginatedComments } from '../../../types/services/comments';
import { transformResource } from '../../../utility/transformResource';

import { Popularity } from './components/Popularity/Popularity';
import { ReportTypes } from './components/ReportTypes/ReportTypes';

const { Column } = Table;

export const Comments = () => {
  const { t } = useTranslation();
  const [pagination, setPagination] = useState<PaginationParams>({ page: 1, perPage: 10 });
  const { id: articleId } = useParams<{ id?: string }>();
  const s = useLocation().search;
  const getQueryParams = (s?: string): Map<string, string> => {
    if (!s || typeof s !== 'string' || s.length < 2) {
      return new Map();
    }
    const a: [string, string][] = s
      .slice(1) // remove `?`
      .split('&') // split by `&`
      .map((x) => {
        const a = x.split('=');
        return [a[0], a[1]];
      }); // split by `=`
    return new Map(a);
  };
  const c = getQueryParams(s);
  const commentId = c.get('commentId');

  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [deleteCommentId, setDeleteCommentId] = useState(0);
  const [isDeletingComment, setIsDeletingComment] = useState(false);
  const [filters, setFilters] = useState<any>({ commentId: commentId ? commentId : null });
  const filterInputNode = useRef<typeof Input>(null);

  const {
    isLoading,
    isFetching,
    isError,
    data: response,
    error,
    refetch,
  } = useQuery<PaginatedComments, HttpError>(['listComments'], () =>
    commentsService.list(Number(articleId), pagination, { sort: 'numberOfReports', commentId: filters.commentId }),
  );

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      refetch();
    }, 1000);
    return () => clearTimeout(timeOutId);
  }, [pagination, refetch, filters]);

  function onPaginationChange(page: number) {
    setPagination((previousPagination) => ({ ...previousPagination, page }));
  }

  function onShowSizeChange(current: number, pageSize: number) {
    setPagination({ ...pagination, page: current, perPage: pageSize });
  }

  const transformCommentResource = (resource: Comment) => {
    resource = transformResource(resource);
    return {
      ...resource,
      numberOfReports: resource.numberOfReports,
      popularity: { numberOfLikes: resource.numberOfLikes, numberOfDislikes: resource.numberOfDislikes },
      reportTypes: resource.reports ? resource.reports.map((r) => r.reportType) : [],
    };
  };

  function transform(comments: Comment[]): any {
    return comments.map((comment) => {
      return {
        ...transformCommentResource(comment),
        children: comment.children?.length ? transform(comment.children) : null,
      };
    });
  }

  const showDeleteCommentError = () => {
    message.error(t('comment:Delete comment error description'));
  };

  const showDeleteCommentSuccess = () => {
    message.success(` ${t('comment:Delete comment success description')}`);
  };

  const handleCommentDelete = async () => {
    try {
      setIsDeletingComment(true);
      await commentsService.delete(deleteCommentId);
      await refetch();
      showDeleteCommentSuccess();
    } catch (e) {
      showDeleteCommentError();
    } finally {
      setIsDeletingComment(false);
      setDeleteModalVisible(false);
    }
  };

  const handleCommentDeleteCancel = () => {
    setDeleteModalVisible(false);
  };

  const openDeleteModal = (id: number) => {
    setDeleteCommentId(id);
    setDeleteModalVisible(true);
  };

  const idFilterDropdown = () => (
    <div style={{ padding: 8 }}>
      <InputNumber
        value={filters.id}
        placeholder={t('common:Search term')}
        onChange={(target) => {
          if (target) {
            setFilters((f: any) => ({ ...f, commentId: target }));
          } else {
            setFilters(() => ({}));
          }
        }}
        ref={(node) => {
          (filterInputNode as any).current = node;
        }}
      />
    </div>
  );

  if (isError) {
    return (
      <div>
        <pre>{JSON.stringify(error, undefined, 2)}</pre>
      </div>
    );
  }

  if (!response) {
    return (
      <div style={{ padding: '10px', textAlign: 'center' }}>
        <Spin size="large" />
      </div>
    );
  }

  const { data: comments } = response;
  const { total } = response.pagination;

  return (
    <>
      <div style={{ padding: '10px', textAlign: 'center' }}>
        <Row gutter={[8, 16]}>
          <Table
            bordered
            sticky
            size="middle"
            loading={isLoading || isFetching}
            dataSource={transform(comments)}
            pagination={{
              onChange: onPaginationChange,
              onShowSizeChange,
              size: 'default',
              position: ['topRight'],
              showSizeChanger: true,
              showLessItems: true,
              current: pagination.page,
              pageSize: pagination.perPage,
              total,
            }}
          >
            <Column key="id" dataIndex="id" title={t('comment:id')} filterDropdown={idFilterDropdown} />
            <Column key="text" dataIndex="text" title={t('comment:text')} />
            <Column key="author" dataIndex="author" title={t('comment:author')} />
            <Column key="createdAt" dataIndex="createdAt" title={t('common:Created At')} />
            <Column key="numberOfReports" dataIndex="numberOfReports" title={t('comment:Number of reports')} />
            <Column
              key="reportTypes"
              dataIndex="reportTypes"
              title={t('comment:Report types')}
              render={(reportTypes) => <ReportTypes reportTypes={reportTypes} />}
            />
            <Column
              key="popularity"
              dataIndex="popularity"
              title={t('comment:Popularity')}
              render={({ numberOfLikes, numberOfDislikes }) => (
                <Popularity numberOfLikes={numberOfLikes} numberOfDislikes={numberOfDislikes} />
              )}
            />
            <Column
              title={t('common:Options')}
              align="center"
              fixed="right"
              width="80px"
              key="edit"
              render={(_, comment: { key: React.Key } & Comment) => (
                <Space>
                  <Tooltip title={t('common:Delete')}>
                    <Button
                      shape="round"
                      icon={<DeleteTwoTone twoToneColor="#eb2f96" />}
                      onClick={() => openDeleteModal(comment.id)}
                    />
                  </Tooltip>
                </Space>
              )}
            />
          </Table>
        </Row>
      </div>
      <Modal
        title={t('comment:Delete comment')}
        visible={deleteModalVisible}
        onOk={handleCommentDelete}
        confirmLoading={isDeletingComment}
        onCancel={handleCommentDeleteCancel}
      >
        <p>{`${t('common:Delete Modal Text')} komentar?`}</p>
      </Modal>
    </>
  );
};
