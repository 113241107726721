import { EyeOutlined, DeleteOutlined } from '@ant-design/icons';
import { Image, ImageProps, Tooltip } from 'antd';
import { useSingleAndDoubleClick } from 'hooks/useSingleAndDoubleClick';
import { DateTime } from 'luxon';
import { useRef, MutableRefObject, MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';

import './style.scss';
export const SelectableCoverImage = (
  props: ImageProps & {
    uid: string;
    createAt: string;
    selected?: boolean;
    onSelection: (image: any) => void;
    onImageDelete: (uid: string) => void;
  },
) => {
  const { t } = useTranslation();
  const { uid, selected, onSelection, onImageDelete, createAt, ...rest } = props;
  const maskRef = useRef(null) as MutableRefObject<HTMLDivElement | null>;

  const onClick = (e: MouseEvent) => {};

  const stopEvent = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const handleDeleteImage: MouseEventHandler<HTMLDivElement> = (e) => {
    stopEvent(e);
    onImageDelete(uid);
  };

  const onDoubleClick = () => {
    onSelection(props.id);
  };
  const handleClick = useSingleAndDoubleClick(onClick, onDoubleClick, maskRef);
  return (
    <div className="relative flex flex-col">
      <Image
        preview={{
          mask: (
            <div
              ref={maskRef}
              className="ant-image-mask relative flex flex-wrap h-full w-full"
              role="button"
              aria-hidden="true"
              tabIndex={0}
              onClick={handleClick}
            >
              <div className="image-action-btn h-1/2 w-1/2" role="button" aria-hidden="true" tabIndex={0}>
                <Tooltip title={t('common:View')}>
                  <EyeOutlined />
                </Tooltip>
              </div>
              <div
                className="image-action-btn h-1/2 w-1/2"
                role="button"
                aria-hidden="true"
                tabIndex={0}
                onClick={handleDeleteImage}
              >
                <Tooltip title={t('common:Delete')}>
                  <DeleteOutlined />
                </Tooltip>
              </div>
            </div>
          ),
        }}
        {...rest}
      />
      <span className="text-center">{DateTime.fromISO(createAt).toLocaleString(DateTime.DATE_SHORT)}</span>
    </div>
  );
};
