import { original } from 'immer';

import { authService } from 'config/services';

import { createReducer } from 'helpers/redux';

import { ActionTypes } from 'literals/index';

import { StoreAction } from 'types/helpers/redux';
import { Role } from 'types/services/auth';
import { SessionState } from 'types/store';

export const sessionState: SessionState = {
  isAuthenticated: false,
  isLoading: false,
  isPristine: true,
  user: null,
  permissions: authService.defaultPermissions(),
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  session: createReducer<SessionState>(
    {
      // LOGIN

      [ActionTypes.LOGIN_REQUEST]: (draft: SessionState, action: StoreAction<any>) => {
        draft.isAuthenticated = false;
        draft.isLoading = true;
      },
      [ActionTypes.LOGIN_SUCCESS]: (draft: SessionState, action: StoreAction<any>) => {
        draft.isAuthenticated = true;
        draft.isLoading = false;
        draft.user = action.payload.user;
      },
      [ActionTypes.LOGIN_FAILURE]: (draft: SessionState, action: StoreAction<any>) => {
        draft.isAuthenticated = false;
        draft.isLoading = false;
      },

      [ActionTypes.LOAD_PERMISSIONS]: (draft: SessionState, action: StoreAction<any>) => {
        const user = original(draft.user);
        draft.permissions = user
          ? authService.permissions(user.role as Role, action.payload.permissions)
          : authService.defaultPermissions();
      },

      // LOGOUT

      [ActionTypes.LOGOUT]: (draft: SessionState, action: StoreAction<any>) => {
        draft.isAuthenticated = false;
      },

      // VALIDATE TOKEN

      [ActionTypes.VALIDATE_TOKEN_REQUEST]: (draft: SessionState, action: StoreAction<any>) => {
        draft.isAuthenticated = false;
        draft.isPristine = true;
      },
      [ActionTypes.VALIDATE_TOKEN_SUCCESS]: (draft: SessionState, action: StoreAction<any>) => {
        draft.isAuthenticated = true;
        draft.isPristine = false;
      },
      [ActionTypes.VALIDATE_TOKEN_FAILURE]: (draft: SessionState, action: StoreAction<any>) => {
        draft.isAuthenticated = false;
        draft.isPristine = false;
      },
    },
    sessionState,
  ),
};
