import './CustomLabel.scss';

interface CustomLabelProps {
  label: string;
  count?: number;
}

const CustomLabel = ({ label, count }: CustomLabelProps) => {
  return (
    <span>
      <span className="hashtag">#</span> <span className="tag-name">{label}</span>{' '}
      {count ? <span className="label-badge">({count})</span> : ''}
    </span>
  );
};

export default CustomLabel;
