import { FormOutlined } from '@ant-design/icons';
import { Button, Modal, Slider } from 'antd';
import round from 'lodash/round';
import mime from 'mime-types';
import { useState, useCallback, useEffect, useRef } from 'react';
import Cropper from 'react-easy-crop';
import { useTranslation } from 'react-i18next';

import { FileExtended, UploadFile } from 'types/files';

import { getCroppedImg, loadImageAsync } from './helpers';

import 'react-easy-crop/react-easy-crop.css';

export type ModalPromise = {
  resolve: (image: FileExtended | UploadFile) => void;
  reject: (image?: FileExtended | UploadFile) => void;
};

export interface EditImageModalProps {
  visible: boolean;
  image?: FileExtended | UploadFile;
  modalPromise?: ModalPromise;
  isNewImage?: boolean;
  squareThumbnailEdit?: boolean;
  onConfirm?: (image: FileExtended) => void;
  onCancel: () => void;
  isCover: boolean;
  isCrossword?: boolean;
  isCrosswordBackground?: boolean;
}

const aspectRations = [
  { value: 1, label: '1' },
  { value: round(16 / 9, 6), label: '16/9' },
  { value: round(9 / 16, 6), label: '9/16' },
];

const aspectRationsCW = [{ value: round(16 / 10, 6), label: '16/10' }];

export const EditCoverImageModal = ({
  image,
  visible,
  isNewImage,
  squareThumbnailEdit = false,
  onCancel,
  onConfirm,
  modalPromise,
  isCover,
  isCrossword = false,
  isCrosswordBackground = false,
}: EditImageModalProps) => {
  const { t } = useTranslation();
  const [crop, setCrop] = useState<any>({ x: 0, y: 0 });
  const [cropSize] = useState(
    isCover
      ? { width: 580, height: 800 }
      : isCrosswordBackground
      ? { width: 1440, height: 900 }
      : isCrossword
      ? { width: 300, height: 300 }
      : { width: 150, height: 150 },
  );
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const defaultAspectRatio = isCrosswordBackground
    ? aspectRationsCW[0].value
    : aspectRations[squareThumbnailEdit ? 0 : 1].value;
  const [aspectRatio, setAspectRation] = useState(defaultAspectRatio);
  const [localImage, setLocalImage] = useState<string | undefined>(image?.url);
  const [imageData, setImageData] = useState({ description: '', source: '', caption: '' });
  const orginalAspectRatio = useRef<number | undefined>();
  const imageSize = useRef<{ width: number; height: number } | null>(null);
  const [imageWidthWarning, setImageWidthWarning] = useState(false);

  useEffect(() => {
    setLocalImage(image?.url);
    setImageData({ description: image?.description || '', source: image?.source || '', caption: image?.caption || '' });
    setAspectRation(defaultAspectRatio);
  }, [defaultAspectRatio, image]);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const confirmImageChanges = useCallback(async () => {
    try {
      if (image && croppedAreaPixels) {
        const rawImg = await loadImageAsync(localImage as string);

        let { width: cropWidth, height: cropHeight } = croppedAreaPixels;
        const { naturalWidth: rawWidth, naturalHeight: rawHeight } = rawImg;
        if (cropWidth < 1440 && isCrosswordBackground) {
          setImageWidthWarning(true);
        } else {
          const type = image.type || (image as UploadFile).response.mimeType;
          const imageNotEdited =
            orginalAspectRatio.current?.toFixed(2) === aspectRatio.toFixed(2) &&
            zoom === 1 &&
            rotation === 0 &&
            // crop is not precise
            Math.abs(cropWidth - rawWidth) < 5 &&
            Math.abs(cropHeight - rawHeight) < 5;

          const croppedImage = imageNotEdited
            ? null
            : await getCroppedImg(rawImg, type, croppedAreaPixels, rotation, zoom);

          let file: unknown;
          if (croppedImage) {
            file = new File([croppedImage], `${image.name}.${mime.extension(croppedImage.type)}`, {
              lastModified: new Date().getTime(),
              type: croppedImage.type,
            });
            (file as FileExtended).description = imageData.description;
            (file as FileExtended).caption = imageData.caption;
            (file as FileExtended).source = imageData.source;
            (file as FileExtended).url = '';
          }
          image.description = imageData.description;
          image.source = imageData.source;
          image.caption = imageData.caption;

          if (modalPromise) {
            file || isNewImage ? modalPromise?.resolve((file || image) as FileExtended) : modalPromise?.reject(image);
          } else {
            onConfirm?.((file || image) as FileExtended);
          }
          resetState();
        }
      }
    } catch (e) {
      console.error(e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [image, croppedAreaPixels, rotation, modalPromise, imageData]);

  const resetState = () => {
    setRotation(0);
    setZoom(1);
    setAspectRation(defaultAspectRatio);
    setImageWidthWarning(false);
  };

  const resetImage = () => {
    setLocalImage(image?.url);
    resetState();
  };

  const handleCancel = () => {
    if (modalPromise) {
      modalPromise?.reject();
    } else {
      onCancel();
    }

    resetState();
  };

  const onMediaLoaded = (mediaSize: { width: number; height: number; naturalWidth: number; naturalHeight: number }) => {
    const { naturalWidth, naturalHeight } = mediaSize;
    imageSize.current = mediaSize;
    const imageAspectRatio = round(naturalWidth / naturalHeight, 6);
    const closest = aspectRations.find((ar) => Math.abs(ar.value - imageAspectRatio) < 0.001)?.value;
    orginalAspectRatio.current = closest || imageAspectRatio;
  };

  return (
    <Modal
      width={1600}
      centered
      mask={true}
      maskClosable={false}
      onCancel={handleCancel}
      visible={visible}
      title={
        <div>
          <FormOutlined style={{ marginRight: '5px' }} />
          <span>{t('images:Edit image')}</span>
        </div>
      }
      footer={[
        <Button key="submit" type="primary" onClick={confirmImageChanges}>
          {t('common:Confirm')}
        </Button>,
      ]}
      //zIndex={1005}
    >
      <div className="flex">
        {image && (
          <div
            className="crop-container"
            style={{
              position: 'relative',
              height: isCrosswordBackground ? 900 : 800,
              width: isCrosswordBackground ? 1440 : 1200,
            }}
          >
            <Cropper
              disableAutomaticStylesInjection
              image={localImage}
              crop={crop}
              cropSize={cropSize}
              zoom={zoom}
              rotation={rotation}
              aspect={aspectRatio}
              onMediaLoaded={onMediaLoaded}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />
          </div>
        )}
        <div style={{ width: 300, padding: 10 }}>
          {t('images:Rotation')}
          <Slider min={-180} max={180} value={rotation} onChange={setRotation} />
          {t('images:Zoom')}
          <Slider min={0.5} max={3} step={0.1} value={zoom} onChange={setZoom} />
          <Button style={{ marginTop: 10, display: 'block' }} onClick={resetImage}>
            {t('images:Undo changes')}
          </Button>
        </div>
      </div>
      {imageWidthWarning && (
        <p style={{ color: 'red', textAlign: 'center', fontWeight: 'bolder' }}>
          Minimalna širina slike za pozadinu je 1440 piksela!
        </p>
      )}
    </Modal>
  );
};
