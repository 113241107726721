import { SettingOutlined } from '@ant-design/icons';

import { withNavigation } from 'containers/Navigation';

import { IRoute, Submenu } from 'types/common';

import { UploadCoverImages } from '../coverImage/UploadCoverImage';

import { Categories } from './categories/Categories';
import { Tags } from './Tags';

export const routes: IRoute[] = [
  {
    component: withNavigation(Tags),
    exact: true,
    key: 'settings-tags',
    path: '/settings/tags',
    isPrivate: true,
  },
  {
    component: withNavigation(Categories),
    exact: true,
    key: 'settings-categories',
    path: '/settings/categories',
    isPrivate: true,
  },
  {
    component: withNavigation(UploadCoverImages),
    exact: true,
    key: 'settings-coverImage',
    path: '/settings/coverImage',
    isPrivate: true,
  },
];

export const submenu: Submenu = {
  key: 'settings',
  icon: SettingOutlined,
  rank: 4,
  items: routes.map((route) => ({ key: route.key, path: route.path as string })),
};
