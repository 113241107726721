import { HttpClient } from 'helpers/http';

import { PaginationParams, Response } from 'types/common';
import {
  PaginatedArticles,
  IArticlesService,
  Article,
  ArticlePayload,
  ArticlePayloadWithId,
  ArticleOptions,
  RelatedArticle,
  ArticleStatusPayload,
  ArticleStatusResponse,
  ArticleTextType,
} from 'types/services/articles';

export class ArticlesService implements IArticlesService {
  constructor(private client: HttpClient) {}

  API = {
    articles: '/api/articles',
    multiple: '/api/statistics/multiple',
  };

  create = (article: ArticlePayload, forcePub: boolean) => {
    let sufix = forcePub ? '?forcePub=true' : '';
    return this.client.post<Article>(this.API.articles + sufix, { article });
  };

  patch = (article: ArticlePayloadWithId) => {
    const url = `${this.API.articles}/${article.id}`;
    return this.client.patch<Article>(url, { article });
  };

  list = ({ page, perPage }: PaginationParams, options: ArticleOptions = {}) => {
    let url = `${this.API.articles}?`;
    const params: Record<string, any> = { page, perPage, ...options };

    url =
      url +
      Object.keys(params)
        .map((key) => {
          const value = params[key];
          return Array.isArray(value)
            ? value.map((v) => `${key}=${encodeURIComponent(v)}`).join('&')
            : `${key}=${encodeURIComponent(value)}`;
        })
        .join('&');
    return this.client.get<PaginatedArticles>(url);
  };
  delete = (id: number, email?: string) => {
    let url = `${this.API.articles}/${id}`;
    if (email) url += '?user=' + email;
    return this.client.remove<number>(url);
  };

  get = (id: number) => {
    const url = `${this.API.articles}/${id}`;
    return this.client.get<Article>(url);
  };

  push = (id: number) => {
    const url = `${this.API.articles}/sendNotification/${id}`;
    return this.client.get<Article>(url);
  };

  related = (tagIds: number[]) => {
    const url = `${this.API.articles}/get-linked`;
    return this.client.post<Response<RelatedArticle[]>>(url, { tagIds });
  };

  relatedByAuthor = (authorId: number, articleId: number) => {
    const url = `${this.API.articles}/get-linked-author`;
    return this.client.post<Response<RelatedArticle[]>>(url, { authorId, articleId });
  };

  setStatus = (id: number, status: ArticleStatusPayload) => {
    const url = `${this.API.articles}/${id}/set-status`;
    return this.client.post<ArticleStatusResponse>(url, { status });
  };

  getTextTypes = () => {
    const url = `${this.API.articles}/get-text-types`;
    return this.client.get<ArticleTextType>(url);
  };

  multiple = (ids: number[]) => {
    let idNew = '';
    ids.forEach((i) => {
      idNew += i + ',';
    });

    const url = `${this.API.multiple}/${idNew.slice(0, -1)}`;
    return this.client.get<any>(url);
  };
}
