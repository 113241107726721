import { PictureOutlined } from '@ant-design/icons';
import { Modal, Spin, Pagination, message } from 'antd';
import { toNumber } from 'lodash';
import throttle from 'lodash/throttle';
import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { addOrRemove } from 'utility';

import { coverImagesService } from 'config/services';

import { HttpError } from 'helpers/http';

import type { Pagination as PaginationType } from 'types/common';
import { CoverImage, PaginatedImages } from 'types/services/coverImages';

import { SelectableCoverImage } from '../../components/SelectableCoverImage/SelectableCoverImage';

import './style.scss';

export type SelectedImagesData = {
  images: CoverImage[];
  selectedIds: number[];
};

interface CoverImageLibraryModalProps {
  open: boolean;
  refresh: boolean;
  alreadySelectedIds: number[];
  maxCount?: number;
  onClose: () => void;
  onConfirm: (data: SelectedImagesData) => void;
}

const initPagination = { page: 1, perPage: 40, total: 0 };

export const CoverImageLibraryModal = ({
  open,
  refresh,
  maxCount,
  alreadySelectedIds,
  onClose,
  onConfirm,
}: CoverImageLibraryModalProps) => {
  const { t } = useTranslation();
  const [pagination, setPagination] = useState<PaginationType>(initPagination);
  const [searchText, setSearchText] = useState('');
  const [selectedImageIds, setSelectedImageIds] = useState<number[]>([]);
  const [coverImageForDeleting, setCoverImageForDeleting] = useState<CoverImage | null>(null);

  const { isLoading: isDeletingCoverImage, mutateAsync: deleteCoverImage } = useMutation(
    'deleteCoverImage',
    (id: number) => coverImagesService.remove(id),
  );

  const selectedImages = useRef<CoverImage[]>([]);
  const {
    isLoading,
    data: paginatedImages,
    refetch,
  } = useQuery<PaginatedImages, HttpError>(['listImages'], () =>
    coverImagesService.list({ ...pagination, filters: { description: searchText } }),
  );
  const throttledRefetch = useRef(throttle(refetch, 500));

  const { data: images, pagination: responsePagination } = paginatedImages || {};

  useEffect(() => {
    throttledRefetch.current();
  }, [pagination, searchText]);

  useEffect(() => {
    refetch();
  }, [refresh, refetch]);

  useEffect(() => {
    setSelectedImageIds(alreadySelectedIds);
    selectedImages.current = [];
  }, [alreadySelectedIds]);

  const handlePaginationChange = (page: number, perPage: number) => {
    setPagination({ page, perPage, total: 0 });
  };

  const resetState = () => {
    setPagination(initPagination);
    setSearchText('');
    setSelectedImageIds([]);
    selectedImages.current = [];
  };

  const handleImageSelection = (id: string) => {
    const ids = addOrRemove<number>(selectedImageIds, parseInt(id));
    if (maxCount && ids.length > maxCount) {
      return;
    }
    setSelectedImageIds(ids);
    const numId = parseInt(id);
    if (ids.includes(numId)) {
      const image = images?.find((i) => i.id === numId);
      image && selectedImages.current.push(image);
    } else {
      selectedImages.current = selectedImages.current.filter((i) => i.id !== numId);
    }
  };

  const onModalClose = () => {
    resetState();
    onClose();
  };

  const deleteImage = (id: string) => {
    //go through images data and find the image you clicked on to be deleted by image id
    const image = images?.find((i) => i.id === toNumber(id));
    setCoverImageForDeleting(image as CoverImage);
  };

  const confirmDeleting = () => {
    if (!coverImageForDeleting) return;
    deleteCoverImage(coverImageForDeleting.id)
      .then(() => {
        message.success(t('common:Successfully Deleted', { resource: t('images:Cover image'), genderSuffix: 'a' }));
        refetch();
        setCoverImageForDeleting(null);
      })
      .catch(() => {
        message.error(t('common:Action failed', { action: t('common:Deletion') }));
      });
  };

  const cancelDeleting = () => {
    if (isDeletingCoverImage) return;
    setCoverImageForDeleting(null);
  };

  return (
    <>
      <Modal
        centered
        width={1540}
        bodyStyle={{ paddingLeft: 19 }}
        mask={true}
        maskClosable={false}
        onCancel={onModalClose}
        visible={open}
        title={
          <div>
            <PictureOutlined style={{ marginRight: '5px' }} />
            <span>{t('images:Cover image library')}</span>
          </div>
        }
        footer={null}
      >
        <div>
          {isLoading ? (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Spin size="large" />
            </div>
          ) : (
            <div>
              <div className="image-library-grid">
                {(images || []).map((image: CoverImage) => (
                  <SelectableCoverImage
                    id={`${image.id}`}
                    uid={image.id.toString()}
                    key={image.id}
                    height={150}
                    className="image-library-item"
                    selected={selectedImageIds.includes(image.id)}
                    width={150}
                    src={image.imageUrl}
                    onSelection={handleImageSelection}
                    onImageDelete={deleteImage}
                    createAt={image.createdAt}
                  />
                ))}
              </div>
            </div>
          )}
        </div>
        <div
          className="flex"
          style={{ alignItems: 'center', justifyContent: 'center', position: 'relative', marginTop: '10px' }}
        >
          <div>
            <Pagination
              defaultCurrent={1}
              current={pagination.page}
              total={responsePagination?.total}
              pageSize={pagination.perPage}
              onChange={handlePaginationChange}
              hideOnSinglePage
              disabled={isLoading}
            />
          </div>
          {/* <div style={{ marginLeft: '10px' }}>
            <Button key="submit" type="primary" onClick={confirmSelection}>
              {t('common:Confirm')}
            </Button>
          </div> */}
        </div>
      </Modal>
      <Modal
        title={t('images:Delete cover image')}
        visible={!!coverImageForDeleting}
        onOk={confirmDeleting}
        confirmLoading={isDeletingCoverImage}
        onCancel={cancelDeleting}
      >
        <p>
          {t('common:Delete Modal Text')}
          {t('images:cover image')}
        </p>
      </Modal>
    </>
  );
};
