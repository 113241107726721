import { Resources, AvailableActions, Roles } from 'types/permissions';

const {
  ARTICLES,
  ARTICLE_BLOCKS,
  ARTICLE_BLOCKS_TYPES,
  CATEGORIES,
  USERS,
  IMAGES,
  TAGS,
  ROLES,
  PERMISSIONS,
  RESOURCES,
  COMMENTS,
  SETTINGS,
  REPORTS,
  COVER_IMAGES,
  CROSSWORDS,
} = Resources;
const { LIST, GET, REMOVE, UPDATE, UPDATE_LIST, CREATE, MERGE, EDIT_LAYOUT, APPROVE } = AvailableActions;
const { ADMINISTRATOR, EDITOR, JOURNALIST, CORRESPONDENT, COMMERCIALIST, ALFATV } = Roles;

const permissionsMap: {
  [key: string]: { [key: string]: string[][] | string[] };
} = {
  '/articles': {
    roles: [ADMINISTRATOR, EDITOR, JOURNALIST, COMMERCIALIST, ALFATV],
    view: [[ARTICLES, LIST]],
    update: [
      [ARTICLES, GET],
      [ARTICLES, UPDATE],
      [CATEGORIES, LIST],
      [USERS, LIST],
      [IMAGES, LIST],
      [TAGS, LIST],
      [TAGS, CREATE],
    ],
    delete: [[ARTICLES, REMOVE]],
    approve: [[ARTICLES, APPROVE]],
  },
  '/article/new': {
    roles: [ADMINISTRATOR, EDITOR, JOURNALIST, COMMERCIALIST, ALFATV],
    view: [
      [ARTICLES, GET],
      [ARTICLES, CREATE],
      [CATEGORIES, LIST],
      [USERS, LIST],
    ],
  },
  '/article/:id': {
    roles: [ADMINISTRATOR, EDITOR, JOURNALIST, COMMERCIALIST, ALFATV],
  },
  '/article/:id/comments': {
    roles: [ADMINISTRATOR, EDITOR, JOURNALIST, COMMERCIALIST, ALFATV],
    view: [[COMMENTS, LIST]],
    delete: [[COMMENTS, REMOVE]],
  },
  '/crosswords': {
    roles: [ADMINISTRATOR, EDITOR, JOURNALIST],
    view: [[CATEGORIES, LIST]],
  },
  '/crossword/new': {
    roles: [ADMINISTRATOR, EDITOR, JOURNALIST],
    view: [[CATEGORIES, LIST]],
  },
  '/crosswords/:id': {
    roles: [ADMINISTRATOR, EDITOR, JOURNALIST],
    view: [[CATEGORIES, LIST]],
  },
  '/settings': {
    roles: [ADMINISTRATOR, EDITOR],
    view: [[SETTINGS]],
    create: [[TAGS, CREATE]],
    merge: [[TAGS, MERGE]],
    delete: [[TAGS, REMOVE]],
  },
  '/settings/tags': {
    roles: [ADMINISTRATOR, EDITOR],
    view: [[TAGS, LIST]],
    create: [[TAGS, CREATE]],
    merge: [[TAGS, MERGE]],
    delete: [[TAGS, REMOVE]],
  },
  '/settings/categories': {
    roles: [ADMINISTRATOR, EDITOR],
    view: [[CATEGORIES, LIST]],
    create: [[CATEGORIES, CREATE]],
    update: [[CATEGORIES, UPDATE]],
    delete: [[CATEGORIES, REMOVE]],
    order: [[CATEGORIES, UPDATE_LIST]],
  },
  '/settings/coverImage': {
    roles: [ADMINISTRATOR, EDITOR],
    view: [[CATEGORIES, LIST]],
    create: [[CATEGORIES, CREATE]],
    update: [[CATEGORIES, UPDATE]],
    delete: [[CATEGORIES, REMOVE]],
    order: [[CATEGORIES, UPDATE_LIST]],
  },
  '/article-blocks/edit': {
    roles: [ADMINISTRATOR, EDITOR],
    view: [
      [ARTICLE_BLOCKS, LIST],
      [ARTICLES, LIST],
      [CATEGORIES, LIST],
    ],
    update: [[ARTICLE_BLOCKS, UPDATE_LIST]],
  },
  '/article-block/new': {
    roles: [ADMINISTRATOR, EDITOR],
    view: [
      [ARTICLE_BLOCKS, GET],
      [ARTICLE_BLOCKS, CREATE],
      [ARTICLE_BLOCKS_TYPES, LIST],
      [CATEGORIES, LIST],
    ],
  },
  '/article-block/:id': {
    roles: [ADMINISTRATOR, EDITOR],
    view: [
      [ARTICLE_BLOCKS, GET],
      [ARTICLE_BLOCKS, UPDATE],
      [ARTICLE_BLOCKS_TYPES, LIST],
      [CATEGORIES, LIST],
    ],
  },
  '/activity-log': {
    roles: [ADMINISTRATOR, EDITOR],
    view: [
      [ARTICLE_BLOCKS, GET],
      [ARTICLE_BLOCKS, UPDATE],
      [ARTICLE_BLOCKS_TYPES, LIST],
      [CATEGORIES, LIST],
    ],
  },
  '/reports/statistics': {
    roles: [ADMINISTRATOR, EDITOR],
    view: [
      [ARTICLE_BLOCKS, GET],
      [ARTICLE_BLOCKS, UPDATE],
      [ARTICLE_BLOCKS_TYPES, LIST],
      [CATEGORIES, LIST],
    ],
  },
  '/article-blocks': {
    roles: [ADMINISTRATOR, EDITOR],
    view: [[ARTICLE_BLOCKS, LIST]],
    delete: [[ARTICLE_BLOCKS, REMOVE]],
    edit_layout: [[ARTICLE_BLOCKS, EDIT_LAYOUT]],
  },
  '/users/list': {
    roles: [ADMINISTRATOR, EDITOR, COMMERCIALIST, ALFATV],
    view: [
      [USERS, LIST],
      [ROLES, LIST],
    ],
    update: [
      [USERS, UPDATE],
      [ROLES, LIST],
    ],
    delete: [[USERS, REMOVE]],
  },
  '/users/roles': {
    roles: [ADMINISTRATOR, EDITOR],
    view: [[ROLES, LIST]],
    update: [
      [ROLES, LIST],
      [RESOURCES, LIST],
      [PERMISSIONS, LIST],
      [PERMISSIONS, UPDATE],
    ],
  },
};

permissionsMap['/article/:id'].view = permissionsMap['/articles'].update;
permissionsMap['/article/:id'].approve = permissionsMap['/articles'].approve;
permissionsMap['/article/:id'].update = permissionsMap['/articles'].update;
permissionsMap['/articles'].comments = permissionsMap['/article/:id/comments'].view;
permissionsMap['/article-blocks'].update = (permissionsMap['/article-blocks/edit'].update as string[][]).concat(
  permissionsMap['/article-blocks/edit'].view,
);

export default permissionsMap;
