import { AxiosRequestConfig } from 'axios';

import { HttpClient } from 'helpers/http';

import { PaginationParams, PatchParams } from 'types/common';
import { PaginatedUsers, IUsersService, User, CreateUser, PaginatedUsersFront, UserFront } from 'types/services/users';

export class UsersService implements IUsersService {
  constructor(private client: HttpClient) {}

  API = {
    list: '/api/users',
    listDeleted: '/api/users/listDeleted',
    create: '/api/users/create',
    createPhoto: '/api/users/createPhoto',
    listUsersFront: '/api/frontusers/list-users',
    patchUsersFront: '/api/frontusers/postBan',
    getByEmail: '/api/users/email',
  };

  patch = ({ id, ...data }: PatchParams<User>) => {
    const url = `${this.API.list}/${id}`;
    return this.client.patch<User>(url, data);
  };

  patchUserFront = ({ id, ...data }: PatchParams<UserFront>) => {
    const url = `${this.API.patchUsersFront}/${id}`;
    return this.client.patch<UserFront>(url, data);
  };

  listUsersFront = ({ page, perPage, filters }: PaginationParams) => {
    let url = `${this.API.listUsersFront}?page=${page}&perPage=${perPage}`;
    if (filters) {
      for (const [key, value] of Object.entries(filters)) {
        if (!!value) url += `&${key}=${value}`;
      }
    }
    return this.client.get<PaginatedUsersFront>(url);
  };

  list = ({ page, perPage, filters, admin }: PaginationParams) => {
    let url = `${this.API.list}?page=${page}&perPage=${perPage}&admin=${admin}`;
    if (filters) {
      for (const [key, value] of Object.entries(filters)) {
        if (!!value) url += `&${key}=${value}`;
      }
    }
    return this.client.get<PaginatedUsers>(url);
  };
  listDeleted = ({ page, perPage, filters }: PaginationParams) => {
    console.log(page, perPage, 'iz servisa');
    let url = `${this.API.listDeleted}?page=${page}&perPage=${perPage}`;
    if (filters) {
      for (const [key, value] of Object.entries(filters)) {
        if (!!value) url += `&${key}=${value}`;
      }
    }
    return this.client.get<PaginatedUsers>(url);
  };

  delete = (id: number) => {
    const url = `${this.API.list}/${id}`;
    return this.client.remove<User>(url);
  };

  recover = (id: number) => {
    const url = `${this.API.list}/${id}`;
    return this.client.post<User>(url);
  };

  create = (user: CreateUser) => {
    return this.client.post<CreateUser>(this.API.create, user);
  };

  createPhoto = async (options: any) => {
    const { onProgress, file } = options;
    const url = this.API.createPhoto;

    const imageData = [{ description: file.description || '', source: file.source }];

    const formData = new FormData();
    formData.append('images', file);
    formData.append('imagesData', JSON.stringify(imageData));

    const config: AxiosRequestConfig = {
      headers: { 'content-type': 'multipart/form-data' },
      onUploadProgress: onProgress,
    };

    return this.client.post<{ thumbnailUrl: string }>(url, formData, config);
  };

  getByEmail = async (email: string | undefined) => {
    const url = `${this.API.getByEmail}/${email}`;
    return this.client.get<User>(url);
  };
}
