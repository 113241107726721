import { HttpClient } from 'helpers/http';

import { PaginationParams } from 'types/common';
import {
  AuthorStatisticSubType,
  IStatisticsService,
  PaginatedByDay,
  PaginatedCategories,
  PaginatedStatistics,
  StatisticsOptions,
} from 'types/services/statistics';

export class StatisticsService implements IStatisticsService {
  constructor(private client: HttpClient) {}

  API = {
    statistics: '/api/articles/statistics',
    subTypeStatistics: '/api/articles/get-sub-type-count',
    categories: '/api/articles/get-hits-by-category',
    byDay: '/api/articles/get-count-articles',
  };

  list = ({ page, perPage }: PaginationParams, options: StatisticsOptions = {}) => {
    let url = `${this.API.statistics}?`;
    const params: Record<string, any> = { page, perPage, ...options };

    url =
      url +
      Object.keys(params)
        .filter((key) => !!params[key])
        .map((key) => {
          const value = params[key];
          return Array.isArray(value)
            ? value.map((v) => `${key}=${encodeURIComponent(v)}`).join('&')
            : `${key}=${encodeURIComponent(value)}`;
        })
        .join('&');

    return this.client.get<PaginatedStatistics>(url);
  };

  listSubTextType = (options: StatisticsOptions = {}) => {
    let url = `${this.API.subTypeStatistics}?`;
    const params: Record<string, any> = { ...options };

    url =
      url +
      Object.keys(params)
        .filter((key) => !!params[key])
        .map((key) => {
          const value = params[key];
          return Array.isArray(value)
            ? value.map((v) => `${key}=${encodeURIComponent(v)}`).join('&')
            : `${key}=${encodeURIComponent(value)}`;
        })
        .join('&');

    return this.client.get<AuthorStatisticSubType>(url);
  };

  categoryStats = (options: StatisticsOptions = {}) => {
    let url = `${this.API.categories}?`;
    const params: Record<string, any> = { ...options };

    url =
      url +
      Object.keys(params)
        .filter((key) => !!params[key])
        .map((key) => {
          const value = params[key];
          return Array.isArray(value)
            ? value.map((v) => `${key}=${encodeURIComponent(v)}`).join('&')
            : `${key}=${encodeURIComponent(value)}`;
        })
        .join('&');

    return this.client.get<PaginatedCategories>(url);
  };

  byDay = (options: StatisticsOptions = {}) => {
    let url = `${this.API.byDay}?`;
    const params: Record<string, any> = { ...options };

    url =
      url +
      Object.keys(params)
        .filter((key) => !!params[key])
        .map((key) => {
          const value = params[key];
          return Array.isArray(value)
            ? value.map((v) => `${key}=${encodeURIComponent(v)}`).join('&')
            : `${key}=${encodeURIComponent(value)}`;
        })
        .join('&');

    return this.client.get<PaginatedByDay>(url);
  };
}
