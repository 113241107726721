import { Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { MdFormatQuote } from 'react-icons/md';

import { QuoteBlock } from 'types/services/articles';

interface QuoteBlockInteface {
  content: QuoteBlock['content'];
  onChange: (content: any) => void;
}

const QuoteBlockComponent = ({ content, onChange }: QuoteBlockInteface) => {
  const { t } = useTranslation();
  const handleChange = (e: any) => {
    onChange({ ...content, [e.target.name]: e.target.value });
  };

  return (
    <div className="quote-block">
      <MdFormatQuote />
      <Input.TextArea
        name="text"
        onChange={handleChange}
        defaultValue={content.text}
        placeholder={t('article:Quote')}
        className="quote-text"
        style={{ background: 'rgb(249, 249, 249)' }}
      />

      <Input
        name="author"
        className="quote-author"
        onChange={handleChange}
        defaultValue={content.author}
        placeholder={t('article:Source')}
        style={{ background: 'rgb(249, 249, 249)' }}
      />
    </div>
  );
};

export default QuoteBlockComponent;
