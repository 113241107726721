import { Article } from 'types/services/articles';

export const transformResource = (resource: Article, stats: any) => {
  let click = 0;
  if (stats !== undefined && stats.resultsAll) {
    for (var i = 0; i < stats.resultsAll.length; i++) {
      if (stats.resultsAll[i].id === resource.id) {
        click = stats.resultsAll[i].clicks;
      }
    }
  }
  return {
    key: resource.id,
    ...resource,
    createdAt: resource.createdAt
      .slice(8, 10)
      .concat(
        '.',
        resource.createdAt.slice(5, 7),
        '.',
        resource.createdAt.slice(0, 4),
        ' - ',
        resource.createdAt.slice(11, 16),
      ), //DateTime.fromISO(resource.createdAt).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS),
    updatedAt: resource.updatedAt
      .slice(8, 10)
      .concat(
        '.',
        resource.updatedAt.slice(5, 7),
        '.',
        resource.updatedAt.slice(0, 4),
        ' - ',
        resource.updatedAt.slice(11, 16),
      ), //DateTime.fromISO(resource.updatedAt).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS),
    author: resource.author ? `${resource?.author?.firstName} ${resource?.author?.lastName}` : null,
    status: resource.status,
    category: resource?.category?.title,
    createdBy: `${resource?.author?.firstName} ${resource?.author?.lastName}`,
    numberOfHits: resource?.articleStatistics?.numberOfHits,
    external_link: articleUrl(resource),
    openRate: click,
  };
};

export const articleUrl = (article: Article) => {
  let url = '';
  let parent = '';
  if (article?.category?.parent) parent = '/' + article.category.parent.slug;
  url = parent + '/' + article.category.slug + '/' + article.id + '/' + article.slug;
  return url;
};
export const textTypes = [
  'Preuzeti tekst',
  'Tekst drugog novinara',
  'Agencijski tekst',
  'Autorski tekst',
  'Prevedeni tekst',
  'Promo tekst',
];

export const textTypesWithNative = [
  'Preuzeti tekst',
  'Tekst drugog novinara',
  'Agencijski tekst',
  'Autorski tekst',
  'Prevedeni tekst',
  'Promo tekst',
  'Native tekst',
];
