import { EyeOutlined, FormOutlined, PictureOutlined, DeleteOutlined } from '@ant-design/icons';
import { Image, ImageProps, Tooltip } from 'antd';
import { useRef, MutableRefObject, MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';

import './style.scss';

export const ImageItem = (
  props: ImageProps & {
    uid: string;
    onImageEdit: (uid: string) => void;
    onDelete?: Function;
    onThumbnailSelect?: Function;
  },
) => {
  const { t } = useTranslation();
  const { uid, onImageEdit, onDelete, onThumbnailSelect, ...rest } = props;
  const maskRef = useRef(null) as MutableRefObject<HTMLDivElement | null>;

  const stopEvent = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const handleEditImage: MouseEventHandler<HTMLDivElement> = (e) => {
    stopEvent(e);
    onImageEdit(uid);
  };

  const handleSetImageAsThumnailImage: MouseEventHandler<HTMLDivElement> = (e) => {
    stopEvent(e);
    onThumbnailSelect?.(parseInt(uid));
  };

  const handleDelete: MouseEventHandler<HTMLDivElement> = (e) => {
    stopEvent(e);
    onDelete?.();
  };

  return (
    <Image
      preview={{
        mask: (
          <div ref={maskRef} className="ant-image-mask relative flex flex-wrap h-full w-full">
            {onThumbnailSelect && (
              <div
                className="image-action-btn h-1/2 w-1/2"
                role="button"
                aria-hidden="true"
                tabIndex={0}
                onClick={handleSetImageAsThumnailImage}
              >
                <Tooltip title={t('article:Cover image')}>
                  <PictureOutlined />
                </Tooltip>
              </div>
            )}
            <div
              className={`image-action-btn ${onDelete ? 'h-1/2 w-1/2' : 'h-1/2 w-full'}`}
              role="button"
              aria-hidden="true"
              tabIndex={0}
              onClick={handleEditImage}
            >
              <Tooltip title={t('common:Edit')}>
                <FormOutlined />
              </Tooltip>
            </div>
            {!onThumbnailSelect && onDelete && (
              <div
                className="image-action-btn h-1/2 w-1/2"
                role="button"
                aria-hidden="true"
                tabIndex={0}
                onClick={handleDelete}
              >
                <Tooltip title={t('common:Delete')}>
                  <DeleteOutlined />
                </Tooltip>
              </div>
            )}
            <div
              className={`image-action-btn ${onThumbnailSelect ? 'h-1/2 w-1/2' : 'h-1/2 w-full'}`}
              role="button"
              aria-hidden="true"
              tabIndex={0}
            >
              <Tooltip title={t('common:View')}>
                <EyeOutlined />
              </Tooltip>
            </div>

            {onThumbnailSelect && (
              <div
                className="image-action-btn h-1/2 w-1/2"
                role="button"
                aria-hidden="true"
                tabIndex={0}
                onClick={handleDelete}
              >
                <Tooltip title={t('common:Delete')}>
                  <DeleteOutlined />
                </Tooltip>
              </div>
            )}
          </div>
        ),
      }}
      {...rest}
    />
  );
};
