import articleBlock from './article-block.json';
import article from './article.json';
import comment from './comment.json';
import common from './common.json';
import customers from './customers.json';
import demo from './demo.json';
import images from './images.json';
import menu from './menu.json';
import settings from './settings.json';
import users from './users.json';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  common,
  customers,
  demo,
  menu,
  users,
  article,
  images,
  settings,
  articleBlock,
  comment,
};
