import { isNumber } from 'lodash';
import PubSub from 'pubsub-js';

import { HttpError } from './http';

type ErrorType =
  | 'HTTP/400'
  | 'HTTP/401'
  | 'HTTP/403'
  | 'HTTP/404'
  | 'HTTP/5XX'
  | 'HTTP/TIMEOUT'
  | 'HTTP/OFFLINE'
  | 'HTTP/NETWORK'
  | 'APP/UNCAUGHT'
  | 'WINDOW/ONERROR';

export class ErrorBus {
  pubSub = PubSub;

  publishHttp(error: HttpError) {
    if (!navigator.onLine) {
      return this.pubSub.publish('HTTP/OFFLINE', error);
    }

    if (isNumber(error.status) && error.status >= 500) {
      return this.pubSub.publish('HTTP/5XX', error);
    }

    if (error.status === 400) {
      return this.pubSub.publish('HTTP/400', error);
    }

    if (error.status === 401) {
      return this.pubSub.publish('HTTP/401', error);
    }

    if (error.status === 403) {
      return this.pubSub.publish('HTTP/403', error);
    }

    if (error.status === 404) {
      return this.pubSub.publish('HTTP/404', error);
    }

    if (!error.network) {
      return this.pubSub.publish('HTTP/NETWORK', error);
    }

    if (error.timeout) {
      return this.pubSub.publish('HTTP/TIMEOUT', error);
    }
  }

  publish(topic: ErrorType, data: Error) {
    return this.pubSub.publish(topic, data);
  }

  subscribe(topic: ErrorType, listener: (message: string, data?: any) => any) {
    return this.pubSub.subscribe(topic, listener);
  }

  subscribeOnce(topic: ErrorType, listener: (message: string, data?: any) => any) {
    return this.pubSub.subscribeOnce(topic, listener);
  }

  unsubscribe(token: string) {
    return this.pubSub.unsubscribe(token);
  }
}
