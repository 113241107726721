import { FileTextOutlined } from '@ant-design/icons';

import { withNavigation } from 'containers/Navigation';

import { IRoute, Submenu } from 'types/common';

import { ArticlePage } from './Article';
import { Articles } from './Articles';
import { ArticleType } from './ArticleType';
import { Comments } from './comments/Comments';

export const routes: IRoute[] = [
  {
    component: withNavigation(ArticleType),
    exact: true,
    key: 'create-article',
    path: '/article/type',
    isPrivate: true,
  },
  {
    component: withNavigation(Articles),
    exact: true,
    key: 'list-articles',
    path: '/articles',
    isPrivate: true,
  },
  {
    component: withNavigation(ArticlePage),
    exact: true,
    key: 'article',
    path: '/article/:id',
    isPrivate: true,
  },
  {
    component: withNavigation(Comments),
    exact: true,
    key: 'article-comments',
    path: '/article/:id/comments',
    isPrivate: true,
  },
];

export const submenu: Submenu = {
  key: 'articles',
  icon: FileTextOutlined,
  rank: 2,
  items: routes.slice(0, routes.length - 2).map((route) => ({ key: route.key, path: route.path as string })),
};
