import { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';

const type = 'DragableUploadList';

interface DraggableItemProps {
  children: React.ReactElement;
  dragDropKey?: string;
  moveRow: (dragIndex: number, hoverIndex: number) => void;
  index: number;
}

const DraggableItem = ({ children, dragDropKey, moveRow, index }: DraggableItemProps) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: dragDropKey || type,
    collect: (monitor) => {
      const { index: dragIndex }: any = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
      };
    },
    drop: (item: any) => {
      moveRow(item.index, index);
    },
  });
  const [, drag] = useDrag({
    type: dragDropKey || type,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drop(drag(ref));
  return (
    <div ref={ref} className={`draggable-list-item ${isOver ? dropClassName : ''}`} style={{ cursor: 'move' }}>
      {children}
    </div>
  );
};

export default DraggableItem;
