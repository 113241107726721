import {
  LogoutOutlined,
  UserOutlined,
  SaveOutlined,
  PlusOutlined,
  UploadOutlined,
  HomeOutlined,
} from '@ant-design/icons';
import { Layout, Menu, Avatar, Dropdown, Badge, Form, Drawer, Button, Input, Select, Upload, message } from 'antd';
import { Image as Picture } from 'antd';
import MenuDivider from 'antd/lib/menu/MenuDivider';
import classnames from 'classnames';
import { DateTime } from 'luxon';
import { ReactNode, useEffect, useState, useRef, useCallback } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useTranslation } from 'react-i18next';
import { useQuery, useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import {
  logout as logoutAction,
  toggleSubmenu as toggleSubmenuAction,
  closeAllSubmenus as closeAllSubmenusAction,
} from 'actions/index';

import AvazLogoSmall from 'assets/avaz-sm.png';
import AvazLogo from 'assets/avaz.svg';

import { EditCoverImageModal } from 'components/EditImageModal/EditCoverImageModal';

import { notificationsService } from 'config/services';
import { usersService } from 'config/services';

import { HttpError } from 'helpers/http';
import { capitalizeFirstLetter, getBase64 } from 'helpers/shared';

import { Item as NavUtilItem, Submenu as NavUtilSubmenu, isSubmenu } from 'types/common';
import { PatchParams } from 'types/common';
import { FileExtended, UploadFile } from 'types/files';
import { PermissionsMap } from 'types/services/auth';
import { Image } from 'types/services/images';
import { Role } from 'types/services/rbac';
import { User } from 'types/services/users';
import { SessionState, StoreState } from 'types/store';

import './index.scss';

const { Header, Content, Sider } = Layout;
const { SubMenu } = Menu;

interface Props extends RouteComponentProps {
  children: ReactNode;
  mode?: 'row' | 'card';
  maxCount?: number;
  multiple?: boolean;
  value?: Image | Image[];
  onChangeProp?: (images: Image[]) => void;
  noti?: boolean;
}

export type ModalPromise = {
  resolve: (image: FileExtended | UploadFile) => void;
  reject: (image?: FileExtended | UploadFile) => void;
};

export const Navigation = withRouter(
  ({ children, history, mode = 'card', maxCount, multiple, onChangeProp, noti }: Props) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [isCollapsed, collapse] = useState(false);
    const [notiCount, setNotiCount] = useState(0);
    const [toSeen, setToSeen] = useState(false);
    const [formEdit] = Form.useForm<User>();
    const [thumbnailUrl, setThumbnailUrl] = useState<string | null>();
    const [isDrawerVisible, setIsDrawerVisible] = useState(false);
    const [squareImageEdit] = useState(false);
    const [warning, setWarning] = useState(false);
    const isCardMode = mode === 'card';
    const [fileList, setFileList] = useState<UploadFile[]>([]);

    const { data: getuser, refetch: refetchEmail } = useQuery<any, HttpError>({
      queryKey: ['getUserByEmail'],
      queryFn: () => usersService.getByEmail(user?.email),
      enabled: noti,
    });

    const [selectedImage, setSelectedImage] = useState<{ isNewImage: boolean; image?: FileExtended | UploadFile }>();
    const editImageModalPromise = useRef<ModalPromise>();
    const [warningType, setWarningType] = useState<any>([]);
    const [roles, setRoles] = useState<Role[]>([]);

    const { Option } = Select;

    const { isLoading: isEditing, mutateAsync } = useMutation<User, HttpError, PatchParams<User>>(
      'editUser',
      (params) => usersService.patch(params),
    );

    const { user } = useSelector<StoreState, SessionState>(({ session }) => session);

    const forward = (path: string) => history.push(path);
    const onCollapse = () => {
      collapse(!isCollapsed);
      dispatch(closeAllSubmenusAction());
    };

    const { data: notificationsCount, refetch } = useQuery<any, HttpError>(
      ['fetchNotifications'],
      () => notificationsService.fetchNotifications(toSeen),
      {
        enabled: noti,
        onSuccess: (data) => {
          data.notifications = data.notifications.map((dt: any) => {
            let temp = dt;
            let tempLink = '';
            if (temp.status === 'updated' || temp.status === 'approved' || temp.status === 'newComment') {
              tempLink = process.env.SHAZAM_APP_API_URL + '/';
              if (temp.parent_category) tempLink += temp.parent_category + '/';
              tempLink += temp.article_category;
              tempLink += '/' + temp.id + '/' + temp.slug;
            } else if (temp.status === 'newCommentReport') {
              tempLink += '/article/' + temp.article.id + '/comments?commentId=' + temp.id;
            } else {
              tempLink += '/article/' + temp.id;
            }
            temp.link = tempLink;
            return temp;
          });
        },
        refetchInterval: 20 * 1000,
      },
    );

    useEffect(() => {
      setToSeen(false);
      setNotiCount(notificationsCount?.notifications.filter((i: any) => i.seen === false).length);
    }, [notificationsCount]);

    const logout = () => dispatch(logoutAction());
    const toggleSubmenu = (submenu: string) => dispatch(toggleSubmenuAction(submenu));

    const entries = useSelector<StoreState, (NavUtilItem | NavUtilSubmenu)[]>(({ sidebar }) => sidebar.entries);
    const activeSubmenuKeys = useSelector<StoreState, string[] | undefined>(({ sidebar }) => sidebar.activeSubmenuKeys);
    const permissionMap = useSelector<StoreState, PermissionsMap>(({ session }) => session.permissions);

    function shouldBeVisible(entry: NavUtilItem) {
      return permissionMap[entry.path]?.view === true ? permissionMap[entry.path].view : false;
    }

    function getEntries() {
      return [...entries]
        .filter((entry) => (isSubmenu(entry) ? entry.items.some(shouldBeVisible) : shouldBeVisible(entry)))
        .sort((a, b) => (a.rank || 0) - (b.rank || 0));
    }

    function getSelectedItemKeys(path: string) {
      return entries
        .flatMap((entry) => (isSubmenu(entry) ? entry.items : [entry]))
        .filter((entry) => entry.path === path)
        .map((entry) => entry.key);
    }

    const clearNotifications = (e: any) => {
      setToSeen(true);
      setNotiCount(0);
      e.preventDefault();
      if (notiCount > 0) {
      }
    };

    /**
     * @name renderItem
     */
    const renderItem = ({ key, path, icon: Icon }: NavUtilItem) => (
      <Menu.Item key={key} icon={Icon && <Icon />} onClick={() => forward(path as string)}>
        {t(`menu:${key}`)}
      </Menu.Item>
    );

    /**
     * @name renderSubmenu
     */
    const renderSubmenu = ({ key, items, icon: Icon }: NavUtilSubmenu) => (
      <SubMenu key={key} title={t(`menu:${key}`)} icon={Icon && <Icon />} onTitleClick={() => toggleSubmenu(key)}>
        {items.map((item) => renderItem(item))}
      </SubMenu>
    );

    /**
     * @name renderEntry
     * @param entry
     */
    const renderEntry = (entry: NavUtilItem | NavUtilSubmenu) =>
      isSubmenu(entry) ? renderSubmenu(entry) : renderItem(entry);

    const NoNotificationsDropDownMenu = (
      <Menu>
        <div style={{ padding: '15px' }}>
          <span style={{ fontFamily: 'DM Sans', fontWeight: '700', fontSize: '20px', color: '#8B8B8B' }}>
            NOTIFIKACIJE
          </span>
        </div>
        <Menu.Item key={'notikey'}>
          <div
            style={{
              overflowY: 'auto',
              height: '100%',
              width: '360px',
              background: '#FFFFFF',
              borderRadius: '5px',
            }}
          >
            <div>
              <>
                <div
                  style={{
                    borderBottom: '1px solid #F3F3F3',
                    borderRadius: '5px',
                    marginBottom: '10px',
                    marginRight: '10px',
                  }}
                >
                  <div style={{ color: 'black', fontFamily: 'DM Sans' }}>{t('common:No new notifications')}</div>
                </div>
              </>
            </div>
          </div>
        </Menu.Item>
      </Menu>
    );
    // TODO: create different notification style for editor
    const NotificationsDropdownMenu = (
      <div
        style={{
          zIndex: 99999,
          backgroundColor: '#FFFFFF',
          border: ' 1px solid #E3E3E3',
          borderRadius: '5px',
          right: '35px',
          top: '75px',
          height: '470px',
        }}
      >
        <div
          style={{
            overflowY: 'auto',
            height: '100%',
            width: '360px',
            background: '#FFFFFF',
            borderRadius: '5px',
            padding: '7px 20px',
          }}
        >
          <div>
            {notificationsCount?.notifications.map((notification: any) => {
              if (notification.status === 'newCommentReport') {
                return (
                  <div style={{ paddingTop: '12px' }}>
                    <div
                      style={{
                        borderRadius: '5px',
                        marginBottom: '10px',
                        marginRight: '10px',
                        display: 'flex',
                        fontSize: '12px',
                      }}
                    >
                      <div className={notification.status}>{t('common:' + notification.status)}</div>
                      <div style={{ marginLeft: 'auto', color: '#E3E3E3' }}>
                        {DateTime.fromISO(notification.date_created, { locale: 'bs' }).setLocale('bs').toRelative()}
                      </div>
                    </div>
                    <div
                      style={{
                        fontFamily: 'Roboto',
                        fontWeight: '500',
                        paddingBottom: '5px',
                        borderBottom: '1px solid #F3F3F3',
                        fontSize: '16px',
                        color: '#333',
                      }}
                    >
                      <a href={notification.link} style={{ color: '#333' }}>
                        <p className="reportTextComment">
                          {notification.reportType} - {notification.text}
                        </p>
                      </a>
                    </div>
                  </div>
                );
              } else {
                return (
                  <div style={{ paddingTop: '12px' }}>
                    <div
                      style={{
                        borderRadius: '5px',
                        marginBottom: '10px',
                        marginRight: '10px',
                        display: 'flex',
                        fontSize: '12px',
                      }}
                    >
                      <div className={notification.status}>{t('common:' + notification.status)}</div>
                      <div style={{ marginLeft: 'auto', color: '#E3E3E3' }}>
                        {DateTime.fromISO(notification.date_created, { locale: 'bs' }).setLocale('bs').toRelative()}
                      </div>
                    </div>
                    <div
                      style={{
                        fontFamily: 'Roboto',
                        fontWeight: '500',
                        paddingBottom: '5px',
                        borderBottom: '1px solid #F3F3F3',
                        fontSize: '16px',
                        color: '#333',
                      }}
                    >
                      <a href={notification.link} style={{ color: '#333' }}>
                        {notification.article_title}
                      </a>
                    </div>
                  </div>
                );
              }
            })}
          </div>
          {/* <button
            style={{ border: 'none', marginTop: '5px', marginRight: '10px', float: 'right', borderRadius: '5px' }}
            onClick={() => notificationsService.clearNotifications()}
          >
            {t('common:Clear notifications')}
          </button> */}
        </div>
      </div>
    );

    useEffect(() => {
      if (getuser !== undefined) {
        formEdit.setFieldsValue({
          role: getuser.data.role,
          email: getuser.data.email,
          firstName: getuser.data.firstName,
          lastName: getuser.data.lastName,
          id: getuser.data.id,
          facebook: getuser.data.facebook,
          twitter: getuser.data.twitter,
          username: getuser.data.username,
          thumbnailUrl: getuser.data.thumbnailUrl,
        });
        setThumbnailUrl(getuser.data.thumbnailUrl);
      }
    }, [getuser]);

    function openDrawer(data: any) {
      refetchEmail();
      if (getuser !== undefined) {
        formEdit.setFieldsValue({
          role: data.role,
          email: data.email,
          firstName: getuser.data.firstName,
          lastName: getuser.data.lastName,
          id: getuser.data.id,
          facebook: getuser.data.facebook,
          twitter: getuser.data.twitter,
          username: getuser.data.username,
          thumbnailUrl: getuser.data.thumbnailUrl,
        });
        setThumbnailUrl(getuser.data.thumbnailUrl);
      }
      setIsDrawerVisible(true);
    }

    function closeDrawer() {
      setWarningType([]);
      setWarning(false);
      setThumbnailUrl(null);
      formEdit.resetFields();
      setIsDrawerVisible(false);
    }

    const closeEditImageModal = () => {
      setSelectedImage(undefined);
    };

    async function onFormSubmit() {
      const values = { ...formEdit.getFieldsValue(), thumbnailUrl: thumbnailUrl } as User;
      await mutateAsync(values)
        .then((resp: any) => {
          if (resp.errors) {
            setIsDrawerVisible(true);
            setWarningType(resp.errors);
            setWarning(true);
            message.error(t(`common:Invalid data`));
          } else {
            refetch();
            setIsDrawerVisible(false);
            setWarning(false);
            message.success(t(`common:Successfully Updated`, { resource: t('common:User') }));
            formEdit.resetFields();
            setFileList([]);
            setThumbnailUrl(null);
            logout();
          }
        })
        .catch((e) => {});
    }

    const handleUploadChange = (info: any) => {
      const { fileList, file } = info;
      if (file.percent === 100) {
        const image = fileList.find((i: UploadFile) => i.uid === file.uid);
        image.url = file.response.imageUrl;
        image.uid = file.response.id;
        image.description = file.response.description;
        image.caption = file.response.caption;
        image.source = file.response.source;
      }
      handleChange(fileList);
    };

    const handleChange = useCallback(
      (files: UploadFile[]) => {
        setFileList(files);

        if (files.every((f) => f.status === 'done')) {
          onChangeProp?.(files.map((f) => f.response as Image));
        }
      },
      [onChangeProp],
    );

    const customRequest = async (options: any) => {
      const { onSuccess = () => {}, onError = () => {} } = options;

      try {
        const { thumbnailUrl } = await usersService.createPhoto(options);
        setThumbnailUrl(thumbnailUrl);
        onSuccess({
          thumbnailUrl,
        });

        refetch();
      } catch (error: any) {
        onError(error);
      }
    };

    const attachData = (file: UploadFile) => {
      return new Promise<Record<string, any>>(async (resolve, reject) => {
        if (!file.url) {
          const base64String = await getBase64(file as unknown as Blob);
          file.url = base64String;
        }
        resolve(file);
      });
    };

    const beforeUpload = async (file: File, fileList: File[]) => {
      const modalPromise = new Promise<any>((resolve, reject) => {
        editImageModalPromise.current = { resolve, reject };
      });
      if (fileList.length > 1) {
        return Promise.resolve(true);
      }
      modalPromise.finally(closeEditImageModal);

      const fileExtended = file as unknown as FileExtended;
      const base64String = await getBase64(file);
      fileExtended.url = base64String;

      setSelectedImage({ isNewImage: true, image: fileExtended });
      return modalPromise;
    };

    // RENDER
    return (
      <Layout>
        <Sider className="site-sider" theme="light" collapsible collapsed={isCollapsed} onCollapse={onCollapse}>
          <div className="site-logo-container">
            <img
              alt="logo"
              src={isCollapsed ? AvazLogoSmall : AvazLogo}
              className={classnames({
                'site-logo': true,
                'site-logo--collapsed': isCollapsed,
              })}
            />
          </div>
          <Menu
            theme="light"
            mode="inline"
            openKeys={activeSubmenuKeys}
            selectedKeys={getSelectedItemKeys(history.location.pathname)}
            className={'menuScrol'}
          >
            <Menu.Item
              key={'homeItem'}
              onClick={() => (window.location.href = '/')}
              id={'homeItem'}
              icon={<HomeOutlined style={{ paddingLeft: '10px' }} />}
              style={{ paddingLeft: '15px' }}
            >
              Početna
            </Menu.Item>
            {getEntries().map(renderEntry)}
          </Menu>
          <Menu>
            <Menu.Item
              key={'notix'}
              title=""
              style={{
                display: 'flex',
                justifyContent: 'center',
                position: 'absolute',
                bottom: 0,
                marginBottom: '60px',
                width: '100%',
                zIndex: 999,
              }}
              onClick={() => openDrawer(user as any)}
            >
              <Avatar icon={<UserOutlined />} />
              <span style={!isCollapsed ? { paddingLeft: '14px' } : { display: 'none' }}>{user?.name}</span>
            </Menu.Item>
            <MenuDivider />
            <Menu.Item
              key={'notiKey3'}
              onClick={logout}
              id={'logout'}
              icon={<LogoutOutlined style={{ paddingLeft: '10px' }} />}
            >
              {t(`common:Logout`)}
            </Menu.Item>
          </Menu>
        </Sider>

        <Layout className={`site-layout ${isCollapsed ? 'site-layout--closed' : ''}`}>
          <Header className="site-layout__header">
            <div style={{ display: 'flex', float: 'right', marginTop: '20px' }}>
              <button
                onClick={() => {
                  window.location.href = '/article/new';
                }}
                className="nav-create-article"
              >
                + {t(`common:Create article`)}
              </button>

              <div className="notificationsDrop">
                <Dropdown
                  overlay={
                    notificationsCount?.notifications.length !== 0
                      ? NotificationsDropdownMenu
                      : NoNotificationsDropDownMenu
                  }
                  trigger={['click']}
                  overlayClassName={'notification_dropdown'}
                >
                  <span style={{ marginLeft: '15px' }}>
                    <a className="ant-dropdown-link" href="/" onClick={(e) => clearNotifications(e)}>
                      <Badge count={notiCount}>
                        <div
                          style={{
                            border: '1px solid #E3E3E3',
                            borderRadius: '5px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '35px',
                            width: '35px',
                          }}
                        >
                          <svg
                            width="16"
                            height="20"
                            viewBox="0 0 16 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M8 20C9.1 20 10 19.1 10 18H6C6 19.1 6.9 20 8 20ZM14 14V9C14 5.93 12.37 3.36 9.5 2.68V2C9.5 1.17 8.83 0.5 8 0.5C7.17 0.5 6.5 1.17 6.5 2V2.68C3.64 3.36 2 5.92 2 9V14L0 16V17H16V16L14 14ZM12 15H4V9C4 6.52 5.51 4.5 8 4.5C10.49 4.5 12 6.52 12 9V15Z"
                              fill="black"
                            />
                          </svg>
                        </div>
                      </Badge>
                    </a>
                  </span>
                </Dropdown>
              </div>
            </div>
          </Header>
          <Content className="site-layout__content relative">
            <div className="site-layout-background">{children}</div>
          </Content>
        </Layout>

        {/* ================ */}
        {/* EDIT USER DRAWER */}
        {/* ================ */}

        <Drawer
          width="500"
          placement="right"
          onClose={closeDrawer}
          visible={isDrawerVisible}
          title={
            <div>
              <UserOutlined style={{ marginRight: '5px' }} />
              {<span>{t('users:Edit user')}</span>}
            </div>
          }
          footer={[
            <div style={{ float: 'right' }}>
              <Button style={{ marginRight: '10px' }} key="back" disabled={isEditing} onClick={closeDrawer}>
                {t('common:Cancel')}
              </Button>

              <Button
                key="submit"
                type="primary"
                disabled={isEditing}
                loading={isEditing}
                icon={<SaveOutlined />}
                onClick={() => {
                  formEdit.submit();
                }}
              >
                {t('common:Save')}
              </Button>
            </div>,
          ]}
        >
          <EditCoverImageModal
            image={selectedImage?.image}
            visible={!!selectedImage}
            isNewImage={selectedImage?.isNewImage}
            squareThumbnailEdit={squareImageEdit}
            modalPromise={editImageModalPromise.current}
            onCancel={closeEditImageModal}
            isCover={false}
          />
          {/* ================== */}
          {/* EDIT USER FORM */}
          {/* ================== */}
          <div>
            <Form
              form={formEdit}
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 18 }}
              layout="horizontal"
              size="middle"
              onFinish={onFormSubmit}
            >
              {warning && (
                <Form.Item name="warningMessage" style={{ fontFamily: 'DM Sans' }}>
                  {warningType.map((role: any, i: any) => (
                    <>
                      <span key={`role-${i}`}>{t('users:' + capitalizeFirstLetter(role))}</span>
                      <br></br>
                    </>
                  ))}
                  <br></br>
                  <label style={{ color: 'red' }}>{t('users:Wrong credentials')}</label>
                </Form.Item>
              )}
              <Form.Item name="id" label="ID">
                <Input disabled readOnly />
              </Form.Item>
              <Form.Item
                name="firstName"
                rules={[{ required: true, message: t('article:First name required') }]}
                label={t('users:First Name')}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="lastName"
                rules={[{ required: true, message: t('article:Last name required') }]}
                label={t('users:Last Name')}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="email"
                rules={[{ required: true, message: t('article:Email required') }]}
                label={t('users:Email')}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="facebook"
                rules={[{ required: false, message: t('article:Facebook required') }]}
                label={t('users:Facebook')}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="twitter"
                rules={[{ required: false, message: t('article:Twitter required') }]}
                label={t('users:Twitter')}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="username"
                rules={[{ required: true, message: t('article:Username required') }]}
                label={t('users:Username')}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="role"
                rules={[{ required: true, message: t('article:Role required') }]}
                label={t('users:Role')}
              >
                <Select disabled={true}>
                  {roles.map((role) => (
                    <Option key={`role-${role.name}`} value={role.name}>
                      {capitalizeFirstLetter(role.name)}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item name="password" label={t('users:New password')}>
                {/* TODO: Add info popover */}
                <Input.Password />
              </Form.Item>

              <Form.Item name="userImage" label={t('users:User image')}>
                <div className="userRow">
                  <DndProvider backend={HTML5Backend}>
                    <Upload
                      listType={isCardMode ? 'picture-card' : 'picture'}
                      fileList={fileList}
                      onChange={handleUploadChange}
                      customRequest={customRequest}
                      data={attachData}
                      beforeUpload={beforeUpload}
                      maxCount={maxCount}
                      multiple={multiple}
                    >
                      {isCardMode ? (
                        <div>
                          <PlusOutlined />
                          <div style={{ marginTop: 8 }}>{t('images:upload')}</div>
                        </div>
                      ) : (
                        <Button icon={<UploadOutlined />}>{t('images:upload')}</Button>
                      )}
                    </Upload>
                  </DndProvider>
                </div>
                {thumbnailUrl && (
                  <div className="currentUser">
                    <Picture src={thumbnailUrl} />
                  </div>
                )}
              </Form.Item>
            </Form>
          </div>
        </Drawer>
      </Layout>
    );
  },
);
