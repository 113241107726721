import { FileDoneOutlined } from '@ant-design/icons';

import { withNavigation } from 'containers/Navigation';

import { ActivityLogDetail } from 'routes/statistics/ActivityLogDetail';
import { AuthorStatistics } from 'routes/statistics/AuthorStatistics';
import { Category } from 'routes/statistics/Category';

import { IRoute, Submenu } from 'types/common';

import { ActivityLogs } from '../statistics/ActivityLogs';
import { Statistics } from '../statistics/Statistics';

export const routes: IRoute[] = [
  {
    component: withNavigation(Statistics),
    exact: true,
    key: 'reports-statistics',
    path: '/reports/statistics',
    isPrivate: true,
  },
  {
    component: withNavigation(Category),
    exact: true,
    key: 'Kategorije / Po danu',
    path: '/categoryStatistics',
    isPrivate: true,
  },
  {
    component: withNavigation(ActivityLogs),
    exact: true,
    key: 'Activity logs',
    path: '/activity-log',
    isPrivate: true,
  },
  {
    component: withNavigation(ActivityLogDetail),
    exact: true,
    key: 'activity-log',
    path: '/activity-log/:id',
    isPrivate: true,
  },
  {
    component: withNavigation(AuthorStatistics),
    exact: true,
    isPrivate: true,
    key: 'authorStatistics',
    path: '/authorStatistics/:dateOne/:dateTwo/:authorId/:count',
  },
];

export const submenu: Submenu = {
  key: 'reports',
  icon: FileDoneOutlined,
  rank: 5,
  items: routes.slice(0, 3).map((route) => ({ key: route.key, path: route.path as string })),
};
