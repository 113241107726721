import { HttpClient } from 'helpers/http';

import {
  ICategoriesService,
  Category,
  CategoryResponse,
  CategoryOptions,
  CategoryUpdate,
} from 'types/services/categories';

export class CategoriesService implements ICategoriesService {
  constructor(private client: HttpClient) {}

  API = {
    categories: '/api/categories',
  };

  list = (options: CategoryOptions = {}) => {
    let url = this.API.categories;
    if (options?.onlyRoots) url = url + `?onlyRoots=${options.onlyRoots}`;
    return this.client.get<CategoryResponse>(url);
  };

  create = (title: string, color: string, hasAds: boolean, parentId?: number) =>
    this.client.post<Category>(this.API.categories, { category: { title, parentId, color, hasAds } });

  updateCategories = (categories: CategoryUpdate[]) =>
    this.client.post<void>(`${this.API.categories}/update`, { categories });

  remove = (id: number) => this.client.remove<void>(`${this.API.categories}/${id}`);

  update = (category: CategoryUpdate) => this.client.put<void>(`${this.API.categories}/${category.id}`, { category });
}
