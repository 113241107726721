import { Select, Spin } from 'antd';
import { SelectProps, RefSelectProps } from 'antd/es/select';
import throttle from 'lodash/throttle';
import { Ref, useState, useRef, useMemo, useCallback } from 'react';

export interface ThrottledSelectProps<ValueType = any> extends Omit<SelectProps<ValueType>, 'options' | 'children'> {
  fetchOptions: (search: string) => Promise<ValueType[]>;
  throttleTimeout?: number;
  hideSelected?: boolean;
  onOptionsFetched?: (options: ValueType[]) => void;
  innerRef?: Ref<RefSelectProps>;
}

export function ThrottledSelect<
  ValueType extends { key?: string; label: React.ReactNode; value: string | number } = any,
>({
  fetchOptions,
  throttleTimeout = 400,
  hideSelected = false,
  value,
  fieldNames,
  onOptionsFetched,
  innerRef,
  onSearch,
  ...props
}: ThrottledSelectProps) {
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState<ValueType[]>([]);
  const fetchRef = useRef(0);

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      if (onOptionsFetched) {
        onOptionsFetched([]);
      }
      setFetching(true);

      fetchOptions(value).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return;
        }
        if (onOptionsFetched) {
          onOptionsFetched(newOptions);
        }
        setOptions(newOptions);
        setFetching(false);
      });
    };

    return throttle(loadOptions, throttleTimeout);
  }, [fetchOptions, throttleTimeout, onOptionsFetched]);

  const handleSearch = useCallback(
    (value) => {
      onSearch?.(value);
      debounceFetcher(value);
    },
    [debounceFetcher, onSearch],
  );

  const filterKey: string = fieldNames?.value || 'value';
  const filteredOptions = hideSelected
    ? options.filter((o: any) => !value.find((v: any) => v[filterKey] === o[filterKey]))
    : options;
  return (
    <Select<ValueType>
      labelInValue
      filterOption={false}
      onSearch={handleSearch}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      {...props}
      fieldNames={fieldNames}
      value={value}
      options={filteredOptions}
      ref={innerRef}
    />
  );
}
