import { Spin } from 'antd';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import { useEffectOnce } from 'react-use';

import { validateToken, loadPermissions } from 'actions/index';

import { NotFound } from 'components/NotFound';

import { rbacService } from 'config/services';

import { RoutePrivate } from 'containers/RoutePrivate';
import { RoutePublic } from 'containers/RoutePublic';

import { IRoute } from 'types/common';
import { ADMIN_ROLE } from 'types/services/rbac';
import { StoreState, SessionState } from 'types/store';

import { routes } from './routes';

export function Root() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { isAuthenticated, isPristine, user } = useSelector<StoreState, SessionState>(({ session }) => session);

  useEffectOnce(() => {
    dispatch(validateToken());
  });

  useEffect(() => {
    if (user) {
      if (user.role !== ADMIN_ROLE) {
        setLoading(true);
        rbacService.getPermissions(user.role).then((permissions) => {
          setLoading(false);
          dispatch(loadPermissions(permissions));
        });
      } else {
        dispatch(loadPermissions({ permissions: [] }));
      }
    }
  }, [dispatch, user]);

  /**
   * @name renderRoute
   * @param route
   */
  const renderRoute = (route: IRoute) => {
    const { isPrivate, ...props } = route;
    const Wrapper = isPrivate ? RoutePrivate : RoutePublic;
    return <Wrapper isAuthenticated={isAuthenticated} {...props} />;
  };

  /**
   * @name renderRoutes
   * @param routes
   */
  const renderRoutes = (routes: IRoute[]) => routes.map(renderRoute);

  if (isPristine) {
    return null;
  }

  return loading ? (
    <Spin size="large" style={{ position: 'fixed', left: 'calc(50% - 16px)', top: 'calc(50% - 19px)' }} />
  ) : (
    <Switch>
      {renderRoutes(routes)}
      <Route component={NotFound}></Route>
    </Switch>
  );
}
