import { SaveOutlined } from '@ant-design/icons';
import { Form, Input, Button, Spin, message, Switch, Select, Drawer } from 'antd';
import { SelectValue } from 'antd/es/select';
import { capitalize } from 'lodash';
import { useEffect, useState } from 'react';
import { TwitterPicker } from 'react-color';
import { useTranslation } from 'react-i18next';
import { useQuery, useMutation } from 'react-query';
import { useParams } from 'react-router-dom';

import { articleBlocksService, articleBlockTypesService, categoriesService } from 'config/services';

import { HttpError } from 'helpers/http';

import './index.scss';
import { PaginationParams } from '../../../types/common';
import {
  ArticleBlock,
  ArticleBlockPayload,
  ArticleBlockPayloadWithId,
  ArticleBlockType,
  ArticleBlockTypes,
} from '../../../types/services/article-blocks';
import { Article, ArticleOptions } from '../../../types/services/articles';
import { Category, CategoryResponse } from '../../../types/services/categories';

interface ArticleBlockDrawerProps {
  open: boolean;
  refetchArticleBlocks: () => {};
  blockArticle?: ArticleBlockPayloadWithId;
  onCancel: () => void;
}

export const ArticleBlockDrawer = ({ open, refetchArticleBlocks, blockArticle, onCancel }: ArticleBlockDrawerProps) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { id } = useParams<{ id?: string }>();
  const { Option } = Select;

  const {
    isLoading: isCategoriesLoading,
    isError: isCategoriesError,
    data: categoriesData,
  } = useQuery<CategoryResponse, HttpError>('listCategories', () => categoriesService.list({ onlyRoots: true }));

  const {
    isLoading: isTypesLoading,
    isError: isTypesError,
    data: typesData,
  } = useQuery<ArticleBlockTypes, HttpError>('listTypes', () => articleBlockTypesService.list());

  const {
    isLoading,
    error,
    isError,
    data: articleBlock,
  } = useQuery<ArticleBlock, HttpError>(['getArticleBlock', id], () => articleBlocksService.get(parseInt(id || '')), {
    enabled: typeof id !== 'undefined',
  });

  const { mutateAsync: createArticleBlock } = useMutation(
    'createArticleBlock',
    (articleBlockPayload: ArticleBlockPayload) => articleBlocksService.create(articleBlockPayload),
  );

  const { mutateAsync: updateArticleBlock } = useMutation(
    'updateArticleBlock',
    (blockArticle: ArticleBlockPayloadWithId) => articleBlocksService.patch(blockArticle),
  );

  const categories: Category[] = categoriesData?.data || [];
  const types: ArticleBlockType[] = typesData?.data || [];

  const [pagination, setPagination] = useState<PaginationParams>({ page: 1, perPage: 10 });
  const [articleOptions, setArticleOptions] = useState<ArticleOptions>({});
  const [blockArticles, setBlockArticles] = useState<Article[]>([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState<number>(0);

  useEffect(() => {
    if (blockArticle) {
      form.setFieldsValue({
        name: blockArticle.name,
        header: blockArticle.header,
        color: blockArticle.color,
        active: blockArticle.active,
        automatic: blockArticle.automatic,
        typeId: blockArticle.typeId,
        categoryId: blockArticle.categoryId,
        articleIds: blockArticle.articleIds,
      });
    }
  }, [blockArticle, form]);

  useEffect(() => {
    if (!isLoading && articleBlock && categories && types) {
      const { category, typeId, articles } = articleBlock;
      form.setFieldsValue({
        ...articleBlock,
        category: category?.id ? category?.id : 0,
        type: typeId,
      });
      setBlockArticles(articles);
      category?.id ? setSelectedCategoryId(category?.id) : setSelectedCategoryId(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [articleBlock, form, categories, types]);

  useEffect(() => {
    if (selectedCategoryId !== 0) {
      const fields = form.getFieldsValue();
      form.setFieldsValue({
        ...fields,
        header: categories.find((c) => c.id === selectedCategoryId)?.title || fields.header,
        color: { hex: categories.find((c) => c.id === selectedCategoryId)?.color || fields.color },
      });
    }
    setArticleOptions({
      ...articleOptions,
      categoryId: selectedCategoryId === 0 ? undefined : selectedCategoryId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategoryId]);

  // PAGINATION

  useEffect(() => {
    setPagination({ ...pagination, page: 1 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [articleOptions]);

  if (isError || isCategoriesError || isTypesError) {
    return (
      <div>
        <pre>{JSON.stringify(error, undefined, 2)}</pre>
      </div>
    );
  }

  if (isLoading || isCategoriesLoading || isTypesLoading) {
    return (
      <div style={{ padding: '10px', textAlign: 'center' }}>
        <Spin size="large" />
      </div>
    );
  }

  const onFinish = async (values: any) => {
    const articleBlockPayload = {
      name: values.name,
      header: values.header,
      color: values?.color?.hex ? values.color?.hex : values.color,
      active: values.active,
      automatic: values.automatic,
      categoryId: values.categoryId === 0 ? null : values.categoryId,
      typeId: values.typeId,
      articleIds: blockArticles.map((a) => a.id),
    };
    const isUpdate = blockArticle?.id;
    try {
      isUpdate
        ? await updateArticleBlock({ id: blockArticle?.id, ...articleBlockPayload })
        : await createArticleBlock(articleBlockPayload);
      refetchArticleBlocks();
      handleCancel();
      message.success(
        t(`common:Successfully ${isUpdate ? 'Updated' : 'Created'}`, {
          resource: capitalize(t('articleBlock:Article block')),
        }),
      );
      if (!isUpdate) {
        refetchArticleBlocks();
        handleCancel();
      }
    } catch (e) {
      message.error(
        t('common:Action failed', {
          action: t(`common:${isUpdate ? 'Update' : 'Creation'}`),
          resource: t('articleBlock:Article block'),
          suffix: 'a',
        }),
      );
    }
  };

  const onCategoryChange = (value: SelectValue) => {
    // set header automatically to be category title
    setSelectedCategoryId(Number(value));
    setArticleOptions({
      ...articleOptions,
      categoryId: Number(value) === 0 ? undefined : Number(value),
    });
  };

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  return (
    <Drawer
      width="500"
      placement="right"
      onClose={handleCancel}
      visible={open}
      footer={[
        <div style={{ float: 'right' }}>
          <Button style={{ marginRight: '10px' }} key="back" onClick={handleCancel}>
            {t('common:Cancel')}
          </Button>

          <Button key="submit" type="primary" icon={<SaveOutlined />} onClick={form.submit}>
            {t('common:Save')}
          </Button>
        </div>,
      ]}
    >
      <Form layout="vertical" form={form} name="article-block-form" onFinish={onFinish} className="article-block-form">
        <Form.Item name="name" label={t('articleBlock:name')} rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item name="header" label={t('articleBlock:header')} rules={[{ required: true }]}>
          <Input disabled={selectedCategoryId !== 0} />
        </Form.Item>
        <Form.Item
          name="color"
          initialValue="#fff"
          valuePropName="color"
          label={t('articleBlock:color')}
          rules={[{ required: true }]}
          hidden={selectedCategoryId !== 0}
        >
          <TwitterPicker width="100%" />
        </Form.Item>
        <Form.Item initialValue={false} valuePropName="checked" name="active" label={t('articleBlock:active')}>
          <Switch />
        </Form.Item>
        <Form.Item initialValue={false} valuePropName="checked" name="automatic" label={t('articleBlock:automatic')}>
          <Switch />
        </Form.Item>
        <Form.Item name="typeId" label={t('articleBlock:type')} rules={[{ required: true }]}>
          <Select>
            {types.map((t) => (
              <Option key={t.id} value={t.id}>
                {t.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="categoryId" label={t('articleBlock:category')} initialValue={0}>
          <Select onChange={onCategoryChange}>
            <Option value={0}>{t('common:No category')}</Option>
            {categories.map((c) => (
              <Option key={c.id} value={c.id}>
                {c.title}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Drawer>
  );
};
