import { AxiosInstance, AxiosResponse, AxiosRequestConfig, AxiosError } from 'axios';
import { AnyAction, Store } from 'redux';

import { ErrorBus } from 'helpers/ErrorBus';
import { HttpClient } from 'helpers/http';
import { decode } from 'helpers/jwt';
import { getUnixtime } from 'helpers/shared';
import { StorageDriver } from 'helpers/storage';

import { ActionTypes } from 'literals/index';

import { AuthService } from 'services/auth';

import { client } from './client';
import { errorBus } from './error-bus';
import { authService } from './services';
import { storageDriver } from './storage';

/*
 * @name setupInterceptors
 * @param instance
 * @param onResponse
 * @param onErrorResponse
 * @param onRequest
 */
export const setupInterceptors = (
  instance: AxiosInstance,
  onResponse: (response: AxiosResponse) => Promise<AxiosResponse>,
  onErrorResponse: (error: AxiosError) => Promise<AxiosResponse>,
  onRequest: (request: AxiosRequestConfig) => Promise<AxiosRequestConfig>,
) => {
  const responseInterceptor = instance.interceptors.response.use(onResponse, onErrorResponse);
  const requestInterceptor = instance.interceptors.request.use(onRequest);
  return { responseInterceptor, requestInterceptor };
};

/**
 * @name onResponseSetup
 * @param driver
 */
export const onResponseSetup = (driver: StorageDriver) => (response: AxiosResponse) => {
  if (response.headers) {
    const token = response.headers[driver.getAuthKey()];
    driver.updateAuthToken(token);
  }

  return Promise.resolve(response);
};

/**
 * @name onErrorResponseSetup
 * @param authService
 * @param bus
 * @param client
 * @param deauthenticate
 * @param driver
 */
export const onErrorResponseSetup =
  (authService: AuthService, bus: ErrorBus, client: HttpClient, deauthenticate: () => void, driver: StorageDriver) =>
  async (error: AxiosError) => {
    const originalRequest = error.config;
    const httpError = client.convertError(error);

    if (httpError.status === 401 && !(originalRequest as any)._retry) {
      (originalRequest as any)._retry = true;
      const token = driver.getAuthToken();

      if (token && originalRequest.url !== authService.API.refreshToken) {
        const claims = decode(token.accessToken);
        if (claims.exp < getUnixtime() + 5) {
          const newToken = await authService.refreshToken({ email: claims.email, refreshToken: token.refreshToken });
          driver.setAuthToken(newToken);
          originalRequest.headers[driver.getAuthKey()] = newToken.accessToken;
          return client.instance(originalRequest);
        }
      }

      driver.setAuthToken(null);
      deauthenticate();
    }

    bus.publishHttp(httpError);
    return Promise.reject(httpError);
  };

/**
 * @name onRequestSetup
 * @param driver
 */
export const onRequestSetup = (driver: StorageDriver) => (request: AxiosRequestConfig) => {
  const token = driver.getAuthToken();

  if (token) {
    request.headers[driver.getAuthKey()] = `${token.tokenType} ${token.accessToken}`;
  }

  return Promise.resolve(request);
};

// RUN INTERCEPTORS SETUP

const deauthenticate = () =>
  (window.store as Store<any, AnyAction>).dispatch({ type: ActionTypes.VALIDATE_TOKEN_FAILURE });

export const onResponse = onResponseSetup(storageDriver);
export const onErrorResponse = onErrorResponseSetup(authService, errorBus, client, deauthenticate, storageDriver);
export const onRequest = onRequestSetup(storageDriver);

setupInterceptors(client.instance, onResponse, onErrorResponse, onRequest);
