export enum Resources {
  USERS = 'USERS',
  CUSTOMERS = 'CUSTOMERS',
  IMAGES = 'IMAGES',
  CATEGORIES = 'CATEGORIES',
  TAGS = 'TAGS',
  ARTICLES = 'ARTICLES',
  ARTICLE_BLOCKS = 'ARTICLE_BLOCKS',
  ARTICLE_BLOCKS_TYPES = 'ARTICLE_BLOCKS_TYPES',
  RESOURCES = 'RESOURCES',
  PERMISSIONS = 'PERMISSIONS',
  ROLES = 'ROLES',
  COMMENTS = 'COMMENTS',
  SETTINGS = 'SETTINGS',
  REPORTS = 'REPORTS',
  COVER_IMAGES = 'COVER_IMAGES',
  CROSSWORDS = 'CROSSWORDS',
}

export enum AvailableActions {
  GET = 'GET',
  LIST = 'LIST',
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  UPDATE_LIST = 'UPDATE_LIST',
  REMOVE = 'REMOVE',
  GET_HISTORY = 'GET_HISTORY',
  GENERATE_MOCK = 'GENERATE_MOCK',
  MERGE = 'MERGE',
  EDIT_LAYOUT = 'EDIT_LAYOUT',
  APPROVE = 'APPROVE',
}

export enum Roles {
  ADMINISTRATOR = 'ADMINISTRATOR',
  EDITOR = 'EDITOR',
  JOURNALIST = 'JOURNALIST',
  CORRESPONDENT = 'CORRESPONDENT',
  COMMERCIALIST = 'COMMERCIALIST',
  ALFATV = 'ALFATV',
}
