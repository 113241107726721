import { MutableRefObject, useRef } from 'react';
import { delay } from 'utility';
import { CancelablePromise } from 'utility/makeCancelablePromise';

export const useSingleAndDoubleClick = (
  onClick: Function,
  onDoubleClick: Function,
  ref?: MutableRefObject<HTMLDivElement | null>,
) => {
  const clickCount = useRef(0);
  const prevClick = useRef<CancelablePromise<string>>();
  const letHappenNext = useRef(false);

  const handleClicks = (e: any) => {
    if (letHappenNext.current) {
      letHappenNext.current = false;
      return;
    }
    e.stopPropagation();
    e.preventDefault();
    clickCount.current = clickCount.current + 1;
    if (clickCount.current === 1) {
      prevClick.current = delay(300);
      prevClick.current.then(() => {
        clickCount.current = 0;
        onClick();
        ref?.current?.click();
        letHappenNext.current = true;
      });
    } else if (clickCount.current === 2) {
      if (prevClick.current?.cancel) {
        prevClick.current.cancel();
      }
      clickCount.current = 0;
      onDoubleClick();
    }
  };

  return handleClicks;
};
