import { Token } from 'types/services/auth';

import { getItem, getStorage, setItem, ProviderName } from './methods';

export class StorageDriver {
  private provider: ProviderName = 'localStorage';

  constructor(private STORAGE_AUTH_TOKEN_KEY: string, private STORAGE_PROVIDER_KEY: string) {
    const providerName = getItem<ProviderName>(this.STORAGE_PROVIDER_KEY, sessionStorage);
    this.setProvider(providerName);
  }

  public isLocalStorageSession() {
    return this.provider === 'localStorage';
  }

  public getAuthKey() {
    return this.STORAGE_AUTH_TOKEN_KEY;
  }

  public setProvider(name?: ProviderName) {
    this.provider = name && getStorage(name) ? name : 'localStorage';
    setItem(this.STORAGE_PROVIDER_KEY, this.provider, sessionStorage);
  }

  public getAuthToken(): Token | undefined {
    return getItem<Token>(this.STORAGE_AUTH_TOKEN_KEY, getStorage(this.provider));
  }

  public setAuthToken(token: Token | null) {
    return setItem(this.STORAGE_AUTH_TOKEN_KEY, token, getStorage(this.provider));
  }

  public updateAuthToken(token: Token | undefined) {
    if (token) {
      const prevToken = this.getAuthToken();
      if (token !== prevToken) {
        this.setAuthToken(token);
      }
    }
  }
}
