export const loadImageAsync = (url: string): Promise<HTMLImageElement> =>
  new Promise(function (resolve, reject) {
    var image = new Image();
    image.crossOrigin = '*';
    image.onload = function () {
      resolve(image);
    };
    image.onerror = function () {
      reject(new Error('Could not load image at ' + url));
    };
    image.src = url;
  });

export async function getCroppedImg(
  rawImg: HTMLImageElement,
  fileType: string,
  pixelCrop: { height: number; width: number; x: number; y: number },
  rotation: number,
  zoom: number = 1,
): Promise<Blob | null> {
  //const rawImg: HTMLImageElement | null = document.querySelector('.crop-container img');

  let { width: cropWidth, height: cropHeight, x: cropX, y: cropY } = pixelCrop;

  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  if (zoom !== 1 && rawImg && ctx) {
    const { naturalWidth: rawWidth, naturalHeight: rawHeight } = rawImg;
    // resize the canvas to that size
    canvas.width = rawWidth * zoom;
    canvas.height = rawHeight * zoom;
    // important! this makes sure canvas doesn't try to smooth
    // the pixels
    ctx.imageSmoothingEnabled = false;
    // scale the context's coordinate system
    ctx?.scale(zoom, zoom);
    // paint the image
    ctx?.drawImage(rawImg, 0, 0);
  }

  if (rotation !== 0 && rawImg) {
    // make canvas to cover the rotated image
    const { naturalWidth: rawWidth, naturalHeight: rawHeight } = rawImg;

    let boxSize = Math.sqrt(Math.pow(rawWidth, 2) + Math.pow(rawHeight, 2));
    let imgWidth = rawWidth;
    let imgHeight = rawHeight;

    // fit the long image
    if (boxSize > 4096) {
      const ratio = 4096 / boxSize;

      boxSize = 4096;
      imgWidth = rawWidth * ratio;
      imgHeight = rawHeight * ratio;

      cropWidth = cropWidth * ratio;
      cropHeight = cropHeight * ratio;
      cropX = cropX * ratio;
      cropY = cropY * ratio;
    }

    canvas.width = boxSize;
    canvas.height = boxSize;

    // rotate image
    const half = boxSize / 2;
    ctx?.translate(half, half);
    ctx?.rotate((Math.PI / 180) * rotation);
    ctx?.translate(-half, -half);

    // draw rotated image to canvas center
    ctx?.fillRect(0, 0, boxSize, boxSize);

    const imgX = (boxSize - imgWidth) / 2;
    const imgY = (boxSize - imgHeight) / 2;

    ctx?.drawImage(rawImg, 0, 0, rawWidth, rawHeight, imgX, imgY, imgWidth, imgHeight);
    const rotatedImg = ctx?.getImageData(0, 0, boxSize, boxSize);

    // resize canvas to crop size
    canvas.width = cropWidth;
    canvas.height = cropHeight;

    ctx?.fillRect(0, 0, cropWidth, cropHeight);
    if (rotatedImg) {
      ctx?.putImageData(rotatedImg, -(imgX + cropX), -(imgY + cropY));
    }
  } else {
    canvas.width = cropWidth;
    canvas.height = cropHeight;

    ctx?.fillRect(0, 0, cropWidth, cropHeight);
    if (rawImg) {
      ctx?.drawImage(rawImg, cropX, cropY, cropWidth, cropHeight, 0, 0, cropWidth, cropHeight);
    }
  }

  // As Base64 string
  // return canvas.toDataURL('image/jpeg');

  // As a blob
  return new Promise((resolve, reject) => {
    canvas.toBlob(
      (blob) => {
        resolve(blob);
      },
      fileType,
      1,
    );
  });
}

export async function getNewCroppedImg(
  rawImg: HTMLImageElement,
  fileType: string,
  pixelCrop: { height: number; width: number; x: number; y: number },
  rotation: number,
  zoom: number = 1,
): Promise<Blob | null> {
  //const rawImg: HTMLImageElement | null = document.querySelector('.crop-container img');

  let { width: cropWidth, height: cropHeight, x: cropX, y: cropY } = pixelCrop;

  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  // if (zoom !== 1 && rawImg && ctx) {
  //   const { naturalWidth: rawWidth, naturalHeight: rawHeight } = rawImg;
  //   // resize the canvas to that size
  //   canvas.width = rawWidth * zoom;
  //   canvas.height = rawHeight * zoom;
  //   // important! this makes sure canvas doesn't try to smooth
  //   // the pixels
  //   ctx.imageSmoothingEnabled = false;
  //   // scale the context's coordinate system
  //   ctx?.scale(zoom, zoom);
  //   // paint the image
  //   ctx?.drawImage(rawImg, 0, 0);
  // }

  // if (rotation !== 0 && rawImg) {
  //   // make canvas to cover the rotated image
  //   const { naturalWidth: rawWidth, naturalHeight: rawHeight } = rawImg;

  //   let boxSize = Math.sqrt(Math.pow(rawWidth, 2) + Math.pow(rawHeight, 2));
  //   let imgWidth = rawWidth;
  //   let imgHeight = rawHeight;

  //   // fit the long image
  //   if (boxSize > 4096) {
  //     const ratio = 4096 / boxSize;

  //     boxSize = 4096;
  //     imgWidth = rawWidth * ratio;
  //     imgHeight = rawHeight * ratio;

  //     cropWidth = cropWidth * ratio;
  //     cropHeight = cropHeight * ratio;
  //     cropX = cropX * ratio;
  //     cropY = cropY * ratio;
  //   }

  //   canvas.width = boxSize;
  //   canvas.height = boxSize;

  //   // rotate image
  //   const half = boxSize / 2;
  //   ctx?.translate(half, half);
  //   ctx?.rotate((Math.PI / 180) * rotation);
  //   ctx?.translate(-half, -half);

  //   // draw rotated image to canvas center
  //   ctx?.fillRect(0, 0, boxSize, boxSize);

  //   const imgX = (boxSize - imgWidth) / 2;
  //   const imgY = (boxSize - imgHeight) / 2;

  //   ctx?.drawImage(rawImg, 0, 0, rawWidth, rawHeight, imgX, imgY, imgWidth, imgHeight);
  //   const rotatedImg = ctx?.getImageData(0, 0, boxSize, boxSize);

  //   // resize canvas to crop size
  //   canvas.width = cropWidth;
  //   canvas.height = cropHeight;

  //   ctx?.fillRect(0, 0, cropWidth, cropHeight);
  //   if (rotatedImg) {
  //     ctx?.putImageData(rotatedImg, -(imgX + cropX), -(imgY + cropY));
  //   }
  // } else {
  canvas.width = cropWidth;
  canvas.height = cropHeight;

  ctx?.fillRect(0, 0, cropWidth, cropHeight);
  if (rawImg) {
    ctx?.drawImage(rawImg, 0, 0);
  }
  // }

  // As Base64 string
  // return canvas.toDataURL('image/jpeg');

  // As a blob
  return new Promise((resolve, reject) => {
    canvas.toBlob(
      (blob) => {
        resolve(blob);
      },
      fileType,
      1,
    );
  });
}
