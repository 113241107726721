import { all, put, takeLatest } from 'redux-saga/effects';

import { authService } from 'config/services';
import { storageDriver } from 'config/storage';

import { decode } from 'helpers/jwt';
import { getUnixtime } from 'helpers/shared';

import { ActionTypes } from 'literals/index';

import { StoreAction } from 'types/helpers/redux';
import { LoginCredentials, Token } from 'types/services/auth';

export function* login({ payload: credentials }: StoreAction<LoginCredentials>) {
  try {
    if (!credentials) {
      yield put({ type: ActionTypes.LOGIN_FAILURE });
      return;
    }

    const token: Token = yield authService.login(credentials);

    storageDriver.setAuthToken(token);
    const claims = decode(token.accessToken);
    yield put({
      type: ActionTypes.LOGIN_SUCCESS,
      payload: { user: { role: claims.role, email: claims.email, name: claims.name } },
    });
  } catch (err) {
    storageDriver.setAuthToken(null);
    yield put({ type: ActionTypes.LOGIN_FAILURE });
  }
}

export function* logout(): Generator<any, any, any> {
  // TODO: Invalidate token
  yield storageDriver.setAuthToken(null);
}

export function* validateToken(): Generator<any, any, any> {
  try {
    const token = storageDriver.getAuthToken();

    if (!token) {
      yield put({ type: ActionTypes.VALIDATE_TOKEN_FAILURE });
      return;
    }

    const claims = decode(token.accessToken);

    if (claims.exp < getUnixtime() + 5) {
      const newToken: Token = yield authService.refreshToken({
        email: claims.email,
        refreshToken: token.refreshToken,
      });
      storageDriver.setAuthToken(newToken);
    }

    yield put({ type: ActionTypes.VALIDATE_TOKEN_SUCCESS });
    yield put({
      type: ActionTypes.LOGIN_SUCCESS,
      payload: { user: { role: claims.role, email: claims.email, name: claims.name } },
    });
  } catch (err) {
    storageDriver.setAuthToken(null);
    yield put({ type: ActionTypes.VALIDATE_TOKEN_FAILURE });
  }
}

/**
 * Session Sagas
 */
export default function* root() {
  yield all([
    takeLatest(ActionTypes.LOGIN_REQUEST, login),
    takeLatest(ActionTypes.LOGOUT, logout),
    takeLatest(ActionTypes.VALIDATE_TOKEN_REQUEST, validateToken),
  ]);
}
