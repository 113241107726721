import { HttpClient } from 'helpers/http';

import { ITagService, TagPaginationParams, PaginatedTags, Tag } from 'types/services/tags';

export class TagService implements ITagService {
  constructor(private client: HttpClient) {}

  API = {
    tags: '/api/tags',
  };

  list = ({ page, perPage, name }: TagPaginationParams) => {
    let url = `${this.API.tags}?page=${page}&perPage=${perPage}&name=${name}`;
    return this.client.get<PaginatedTags>(url);
  };

  create = (name: string) => this.client.post<Tag>(this.API.tags, { tag: { name } });

  merge = (tagIds: number[], id: number) => this.client.post<string>(`${this.API.tags}/merge`, { tagIds, id });

  remove = (id: number) => this.client.remove<void>(`${this.API.tags}/${id}`);
}
