import { applyMiddleware, compose, createStore, Middleware, Store } from 'redux';
import { persistCombineReducers, persistStore } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/lib/storage';

import reducers from 'reducers/index';

import rootSaga from 'sagas/index';

import { StoreState } from 'types/store';

import middleware, { sagaMiddleware } from './middleware';

const rootReducer = persistCombineReducers<StoreState>(
  {
    key: 'root',
    storage,
    blacklist: ['session', 'sidebar'],
    timeout: 0,
    stateReconciler: autoMergeLevel2,
  },
  reducers,
);

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const configStore = (initialState: any = {}, additionalMiddleware: Middleware[] = []) => {
  const store: Store = createStore(
    rootReducer,
    initialState,
    composeEnhancer(applyMiddleware(...additionalMiddleware, ...middleware)),
  );

  sagaMiddleware.run(rootSaga);

  if (module.hot) {
    module.hot.accept('reducers', () => {
      store.replaceReducer(rootReducer);
    });
  }

  return {
    persistor: persistStore(store),
    store,
  };
};
