import { DateTime } from 'luxon';

export const transformResource = (resource: any) => {
  return {
    key: resource.id,
    ...resource,
    createdAt: DateTime.fromISO(resource.createdAt).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS),
    updatedAt: DateTime.fromISO(resource.updatedAt).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS),
    author: resource.authoredBy ? `${resource.authoredBy.email}` : null,
    //createdBy: `${resource.createdBy.firstName} ${resource.createdBy.lastName}`,
  };
};
