import permissionsMap from 'config/permissions';

import { HttpClient } from 'helpers/http';

import { Roles } from 'types/permissions';
import {
  LoginCredentials,
  Token,
  RefreshTokenCredentials,
  IAuthService,
  Role,
  PermissionsMap,
} from 'types/services/auth';

export class AuthService implements IAuthService {
  constructor(private client: HttpClient) {}

  API = {
    login: '/api/auth/login',
    refreshToken: '/api/auth/refresh-token',
    initResetPassword: '/api/auth/reset-password/init',
    resetPassword: '/api/auth/reset-password',
  };

  login = (credentials: LoginCredentials) => this.client.post<Token>(this.API.login, credentials);
  refreshToken = (credentials: RefreshTokenCredentials) => this.client.post<Token>(this.API.refreshToken, credentials);
  initResetPassword = (email: string) => this.client.post<void>(this.API.initResetPassword, { email });
  resetPassword = (password: string, token: any) => {
    const url = `${this.API.resetPassword}?token=${token}`;
    return this.client.post<void>(url, { password });
  };
  permissions = (role: Role, permissions: string[][]) => {
    const result: PermissionsMap = {};
    Object.keys(permissionsMap).forEach((path) => {
      const pathPermissionsMap = permissionsMap[path];
      const { roles, ...actions } = pathPermissionsMap;
      result[path] = {};
      Object.keys(actions).forEach((action: string) => {
        result[path][action] =
          role === Roles.ADMINISTRATOR ||
          (actions as { [action: string]: string[][] })[action].every((permissionPair: string[]) =>
            permissions.find(
              (permission) => permission[0] === permissionPair[0] && permission[1] === permissionPair[1],
            ),
          );
      });
      result[path].view = (roles as string[]).includes(role) && result[path].view;
    });
    return result;
  };
  defaultPermissions = () => {
    const result: PermissionsMap = {};
    Object.keys(permissionsMap).forEach((path) => {
      result[path] = { view: false };
    });
    return result;
  };
}
