import { Input } from 'antd';
import { memo, useEffect } from 'react';

import { EmbeddedBlock } from 'types/services/articles';

interface EmbeddedBlockInteface {
  content: EmbeddedBlock['content'];
  isEditing: boolean;
  onChange: (content: any) => void;
}

const EmbeddedBlockComponent = ({ content, onChange, isEditing }: EmbeddedBlockInteface) => {
  const checkJwPlayer = (string: string) => {
    if (
      string?.includes('cdn.jw') &&
      !string.includes('youtube') &&
      !string.includes('facebook') &&
      !string.includes('iframe')
    ) {
      if (string.startsWith('<script')) {
        string = '<iframe srcdoc="' + string + '"</iframe>';
      } else {
        string = string.replace('<script src="', '<iframe srcdoc="<script src=');
        string = string.replace('.js"></script>', '.js></script>"></iframe>');
      }
    }
    return string;
  };
  useEffect(() => {
    content.body = checkJwPlayer(content.body);
  }, [content]);

  const handleChange = (e: any) => {
    onChange({ body: checkJwPlayer(e.target.value) });
  };

  return isEditing ? (
    <div className="editing-container">
      <label htmlFor="row-html">Embed</label>
      <Input.TextArea id="row-html" onChange={handleChange} defaultValue={content.body} />
    </div>
  ) : (
    <div dangerouslySetInnerHTML={{ __html: content.body }} />
  );
};

export default memo(EmbeddedBlockComponent);
