import { PlusOutlined, DeleteTwoTone, EditTwoTone } from '@ant-design/icons';
import { Tree, Button, Tooltip, Spin, Modal, message } from 'antd';
import { AntTreeNodeDropEvent, AntTreeNodeProps } from 'antd/es/tree/Tree';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useMutation } from 'react-query';

import { categoriesService } from 'config/services';

import { HttpError } from 'helpers/http';

import { CategoryResponse, CategoryUpdate, Category } from 'types/services/categories';

import { CategoryDrawer } from './CategoryDrawer';
import { loop, transform, flattenCategories, DataNodeExpand } from './helpers';

import './style.scss';

// issue with ts, doesn't see props from extended class
interface DropEvent extends Omit<AntTreeNodeDropEvent, 'node' | 'dragNode'> {
  node: AntTreeNodeProps;
  dragNode: AntTreeNodeProps;
}

export const Categories = () => {
  const { t } = useTranslation();
  const [expandedKeys, setExpandedKeys] = useState<number[]>([]);
  const [treeData, setTreeData] = useState<DataNodeExpand[]>([]);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [categoryForDeleting, setCategoryForDeleting] = useState<Category | null>(null);
  const [editedCategory, setEditedCategory] = useState<CategoryUpdate>();

  const {
    isLoading: categoriesLoading,
    data: categoriesData,
    refetch,
  } = useQuery<CategoryResponse, HttpError>('listCategories', () => categoriesService.list());

  const { mutateAsync: updateCategories } = useMutation('updateCategories', (categories: CategoryUpdate[]) =>
    categoriesService.updateCategories(categories),
  );

  const { isLoading: isDeletingCategory, mutateAsync: deleteCategory } = useMutation('deleteCategory', (id: number) =>
    categoriesService.remove(id),
  );

  //recursive function for sorting all the categories
  const sortCategories = (categories: Category[]) => {
    categories.sort((a, b) => a.position - b.position);
    categories.forEach((mainCategory, index) => {
      if (mainCategory.children !== undefined && mainCategory.children.length !== 0) {
        sortCategories(mainCategory.children);
      }
    });
  };

  //recursive function for modifying categories positions and colors if necessary
  const modifyCategories = (categories: DataNodeExpand[], parent: DataNodeExpand | undefined) => {
    categories.forEach((category: DataNodeExpand, index) => {
      if (category.position !== index) {
        category.position = index;
        category.modified = true;
      }
      if (category.color !== parent?.color && parent !== undefined) {
        category.color = parent.color;
        category.modified = true;
      }
      if (category.children !== undefined && category.children.length !== 0) {
        modifyCategories(category.children, category);
      }
    });
  };

  useEffect(() => {
    if (categoriesData?.data) {
      sortCategories(categoriesData.data);
      setTreeData(categoriesData.data.map(transform));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoriesData]);

  const onDragEnter = (info: any) => {
    const keys: number[] = [info.expandedKeys];
    if (!keys.includes(info.node.id)) {
      keys.push(info.node.id);
    }
    setExpandedKeys(keys);
  };

  const onDrop = (info: DropEvent) => {
    const dropKey = info.node.key as number;
    const dragKey = info.dragNode.key as number;
    const dropPos = info.node.pos.split('-');
    const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);

    const data = JSON.parse(JSON.stringify(treeData));

    // Find dragObject
    let dragObj: DataNodeExpand;
    loop(data, dragKey, (item, index, arr) => {
      arr.splice(index, 1);
      dragObj = item;
    });
    //@ts-ignore
    dragObj.modified = true;

    if (!info.dropToGap) {
      // Drop on the content
      loop(data, dropKey, (item) => {
        item.children = item.children || [];
        // where to insert
        item.children.unshift(dragObj);
      });
    } else if (
      (info.node.props.children || []).length > 0 && // Has children
      info.node.props.expanded && // Is expanded
      dropPosition === 1 // On the bottom gap
    ) {
      loop(data, dropKey, (item) => {
        item.children = item.children || [];
        // where to insert
        item.children.unshift(dragObj);
        // in previous version, we use item.children.push(dragObj) to insert the
        // item to the tail of the children
      });
    } else {
      let ar: DataNodeExpand[];
      let i: number;
      loop(data, dropKey, (item, index, arr) => {
        ar = arr;
        i = index;
      });
      if (dropPosition === -1) {
        //@ts-ignore
        ar.splice(i, 0, dragObj);
      } else {
        //@ts-ignore
        ar.splice(i + 1, 0, dragObj);
      }
    }
    modifyCategories(data, undefined);
    updateCategories(flattenCategories(data))
      .then(() => {
        refetch();
      })
      .catch(() => {
        message.error(t('common:Action failed', { action: t('common:Update') }));
      });
  };

  const edit = (id: number) => {
    loop(treeData, id, (item: DataNodeExpand, index: number, arr: DataNodeExpand[], parent?: DataNodeExpand) => {
      setEditedCategory({ ...item, parentId: parent?.id || null } as CategoryUpdate);
      setOpenDrawer(true);
    });
  };

  const closeDrawer = () => {
    setEditedCategory(undefined);
    setOpenDrawer(false);
  };

  const createNew = () => {
    setOpenDrawer(true);
  };

  const handleDelete = (id: number) => {
    loop(treeData, id, (item, index, arr) => {
      setCategoryForDeleting(item as unknown as Category);
    });
  };

  const confirmDeleting = () => {
    if (!categoryForDeleting) return;
    deleteCategory(categoryForDeleting.id)
      .then(() => {
        message.success(t('common:Successfully Deleted', { resource: t('article:category'), genderSuffix: 'a' }));
        refetch();
        setCategoryForDeleting(null);
      })
      .catch(() => {
        message.error(t('common:Action failed', { action: t('common:Deletion') }));
      });
  };

  const cancelDeleting = () => {
    if (isDeletingCategory) return;
    setCategoryForDeleting(null);
  };

  const renderTile = (row: DataNodeExpand) => {
    return (
      <div className="category-tree-node flex">
        <div className="flex">
          <div className="category-color-preview" style={{ backgroundColor: row.color }} />
          <span>{row.title}</span>
        </div>

        <div className="category-tree-node-actions">
          <Tooltip title={t('common:Edit')}>
            <Button shape="round" icon={<EditTwoTone />} onClick={() => edit(row.key as number)} />
          </Tooltip>
          <Tooltip title={t('common:Delete')}>
            <Button
              shape="round"
              icon={<DeleteTwoTone twoToneColor="#eb2f96" />}
              onClick={() => handleDelete(row.key as number)}
            />
          </Tooltip>
        </div>
      </div>
    );
  };

  return (
    <>
      <div style={{ padding: '10px' }}>
        <Button
          onClick={createNew}
          type="primary"
          icon={<PlusOutlined />}
          disabled={openDrawer}
          style={{ marginBottom: '10px' }}
        >
          {t('common:Add new', { genderSuffix: 'u' })}
        </Button>

        {categoriesLoading || isDeletingCategory ? (
          <div style={{ padding: '10px', textAlign: 'center' }}>
            <Spin size="large" />
          </div>
        ) : treeData.length === 0 ? (
          <div className="no-data-container">
            <p>{t('settings:No categories description')}</p>
          </div>
        ) : (
          <Tree
            className="categories-tree"
            draggable={{
              icon: false,
            }}
            selectable={false}
            blockNode
            titleRender={renderTile}
            onDragEnter={onDragEnter}
            onDrop={onDrop}
            defaultExpandedKeys={expandedKeys}
            treeData={treeData}
          />
        )}
      </div>
      <Modal
        title={t('settings:Delete category')}
        visible={!!categoryForDeleting}
        onOk={confirmDeleting}
        confirmLoading={isDeletingCategory}
        onCancel={cancelDeleting}
      >
        <p>
          {t('common:Delete Modal Text')}
          <b>{` ${categoryForDeleting?.title}?`}</b>
        </p>
      </Modal>
      <CategoryDrawer open={openDrawer} category={editedCategory} refetchCategories={refetch} onCancel={closeDrawer} />
    </>
  );
};
