import { Button, Table, Spin, Row, Space, Tooltip, Modal, Input, message, Tag, InputNumber } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { crosswordsService } from 'config/services';

import { HttpError } from 'helpers/http';

import { PaginationParams } from 'types/common';
import { Crossword, PaginatedCrosswords } from 'types/services/crosswords';

import { transformResource } from './utils';

const { Column } = Table;

export const Crosswords = (props?: any) => {
  const { t } = useTranslation();

  const [pagination, setPagination] = useState<PaginationParams>(
    props.perPage ? { page: 1, perPage: props.perPage } : { page: 1, perPage: 10 },
  );

  const {
    isLoading,
    isFetching,
    isError,
    data: response,
    error,
    refetch,
  } = useQuery<PaginatedCrosswords, HttpError>(['listCrosswords'], () => crosswordsService.list(pagination));

  if (!response) {
    return (
      <div style={{ padding: '10px', textAlign: 'center' }}>
        <Spin size="large" />
      </div>
    );
  }

  console.log(response);

  function onPaginationChange(page: number) {
    setPagination((previousPagination) => ({ ...previousPagination, page }));
  }

  function onShowSizeChange(current: number, pageSize: number) {
    setPagination({ ...pagination, page: current, perPage: pageSize });
  }

  function transform(crosswords: Crossword[]) {
    return crosswords.map((cw) => transformResource(cw));
  }

  const { total } = response.pagination;

  return (
    <Row gutter={[8, 16]}>
      <Table
        bordered
        sticky
        size="middle"
        loading={isLoading || isFetching}
        dataSource={transform(response.data)}
        pagination={{
          onChange: onPaginationChange,
          onShowSizeChange,
          size: 'default',
          position: ['bottomCenter'],
          showSizeChanger: true,
          showLessItems: true,
          current: pagination.page,
          pageSize: pagination.perPage,
          total,
        }}
        // onChange={handleTableChange}
      >
        <Column
          key="id"
          dataIndex="id"
          title={'Id'}
          // filterDropdown={idFilterDropdown}
          width={75}
          render={(text, crossword: any) => (
            <a style={{ color: '#598ad9' }} href={'/crossword/' + crossword.id}>
              {text}
            </a>
          )}
        />

        <Column
          key="body1"
          dataIndex="body1"
          title={'Body'}
          // filterDropdown={idFilterDropdown}
          width={75}
          // render={(text, art: any) => (
          //   <a style={{ color: '#598ad9' }} href={'/article/' + art.id}>
          //     {text}
          //   </a>
          // )}
        />

        <Column
          key="createdAt"
          dataIndex="createdAt"
          title={t('common:Created At') + ' / ' + t('common:Updated At')}
          render={(text, art: any) => (
            <div>
              {art.createdAt} <br /> {art.updatedAt ? art.updatedAt : ''}
            </div>
          )}
          width={170}
        />

        {/* <Column
          title={t('common:Options')}
          align="center"
          fixed="right"
          className="optionsList"
          key="edit"
          render={(_, article: { key: React.Key } & Article) => (
            <Space wrap>
              {article.status === 'approved' && (
                <Tooltip title={t('common:Preview')}>
                  <Button
                    shape="round"
                    icon={<GlobalOutlined twoToneColor="#eb2f96" />}
                    onClick={() => handleArticlePreview(article)}
                  />
                </Tooltip>
              )}
              {permissions.approve && (
                <>
                  {article.status !== 'approved' && user?.role !== 'COMMERCIALIST' && (
                    <Tooltip title={t('article:Approve')}>
                      <Button
                        shape="round"
                        icon={<CheckCircleTwoTone twoToneColor="lime" />}
                        onClick={() => handleArticleApprove(article.id)}
                      />
                    </Tooltip>
                  )}
                  {article.status !== 'rejected' && user?.role !== 'COMMERCIALIST' && (
                    <Tooltip
                      title={
                        article.block !== null && !article?.block?.automatic
                          ? t('article:Article in block')
                          : t('article:Reject')
                      }
                    >
                      <Button
                        shape="round"
                        icon={<CloseCircleTwoTone twoToneColor="#eb2f96" />}
                        onClick={() => handleArticleReject(article.id)}
                        disabled={article.block !== null && !article?.block?.automatic}
                      />
                    </Tooltip>
                  )}
                </>
              )}
              {(article.textType === 'Promo tekst' || article.textType === 'Native tekst') && (
                <Tooltip title={t('common:Analytics')}>
                  <Button shape="round" icon={<EyeOutlined />} onClick={() => handlePreviewClick(article.id)} />
                </Tooltip>
              )}

              {permissions.update && (
                <Tooltip title={t('common:Edit')}>
                  <Button shape="round" icon={<EditTwoTone />} onClick={() => navigateToArticle(article.id)} />
                </Tooltip>
              )}
              {permissions.delete && user?.role !== 'COMMERCIALIST' && (
                <Tooltip
                  title={
                    article.block !== null && !article?.block?.automatic
                      ? t('article:Article in block')
                      : t('common:Delete')
                  }
                >
                  <Button
                    shape="round"
                    icon={<DeleteTwoTone twoToneColor="#eb2f96" />}
                    onClick={() => openDeleteModal(article.id)}
                    disabled={article.block !== null && !article?.block?.automatic}
                  />
                </Tooltip>
              )}
              {permissions.comments && user?.role !== 'COMMERCIALIST' && (
                <Tooltip title={t('article:Comments')}>
                  <Button shape="round" icon={<CommentOutlined />} onClick={() => navigateToComments(article.id)} />
                </Tooltip>
              )}
              {permissions.approve && article.status === 'approved' && user?.role !== 'COMMERCIALIST' && (
                <Tooltip title={t('article:Push')}>
                  <Button
                    shape="round"
                    icon={<UploadOutlined />}
                    onClick={() => openPushModal(article.id, article.push, article.title)}
                  />
                </Tooltip>
              )}
            </Space>
          )}
        /> */}
      </Table>
    </Row>
  );
};
