import debounce from 'lodash/debounce';
import { memo } from 'react';

import { HTMLEditor } from 'components/HTMLEditor/HTMLEditor';

import { TextBlock } from 'types/services/articles';

interface TextBlockInteface {
  content: TextBlock['content'];
  highlighting: boolean;
  onHighLightCalled?: Function;
  onChange: (content: any) => void;
}

const TextBlockComponent = ({ content, highlighting, onChange, onHighLightCalled }: TextBlockInteface) => {
  const handleEditorChange = debounce((htmlText: string) => {
    onChange({ body: htmlText });
  }, 1000);

  return (
    <HTMLEditor
      value={content.body}
      onChange={handleEditorChange}
      highlighting={highlighting}
      onHighLightCalled={onHighLightCalled}
    />
  );
};

export default memo(TextBlockComponent);
