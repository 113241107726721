import { Crossword } from 'types/services/crosswords';

export const transformResource = (resource: Crossword) => {
  console.log(resource);
  return {
    key: resource.id,
    body1: JSON.stringify(resource.body),
    ...resource,
    createdAt: resource.createdAt
      .slice(8, 10)
      .concat(
        '.',
        resource.createdAt.slice(5, 7),
        '.',
        resource.createdAt.slice(0, 4),
        ' - ',
        resource.createdAt.slice(11, 16),
      ), //DateTime.fromISO(resource.createdAt).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS),
    updatedAt: resource.updatedAt
      .slice(8, 10)
      .concat(
        '.',
        resource.updatedAt.slice(5, 7),
        '.',
        resource.updatedAt.slice(0, 4),
        ' - ',
        resource.updatedAt.slice(11, 16),
      ), //DateTime.fromISO(resource.updatedAt).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS),
  };
};
