import { Modal, Button, Input, Form } from 'antd';
import { useTranslation } from 'react-i18next';

export interface ArticleRejectionModalProps {
  visible: boolean;
  onConfirm: (comment: string) => void;
  onCancel: () => void;
}

const ArticleRejectionModal = ({ visible, onCancel, onConfirm }: ArticleRejectionModalProps) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const handleConfirmation = () => {
    onConfirm(form.getFieldValue('comment'));
    form.setFieldsValue({ comment: '' });
  };

  return (
    <Modal
      width={900}
      centered
      mask={true}
      maskClosable={false}
      onCancel={onCancel}
      visible={visible}
      title={
        <div>
          <span>{t('article:Article rejection')}</span>
        </div>
      }
      footer={[
        <Button key="submit" type="primary" onClick={handleConfirmation}>
          {t('common:Confirm')}
        </Button>,
        <Button htmlType="button" onClick={onCancel}>
          {t('common:Cancel')}
        </Button>,
      ]}
    >
      <Form layout="vertical" name="article-rejection-form" form={form}>
        <Form.Item label={t('article:Reject reason')} name="comment" rules={[{ required: true }]}>
          <Input.TextArea rows={3} spellCheck={false} placeholder={t('common:Type text')} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ArticleRejectionModal;
