import { EditTwoTone } from '@ant-design/icons';
import { Button, Table, Spin, Row, Space, Tooltip, Tag } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { rbacService } from 'config/services';

import { HttpError } from 'helpers/http';
import { capitalizeFirstLetter } from 'helpers/shared';

import { RolesResponse, Role } from 'types/services/rbac';

import { EditPermissionsDrawer } from './components';

const { Column } = Table;

export const ListRoles = () => {
  const { t } = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRole, setSelectedRole] = useState('');

  const {
    isLoading,
    isError,
    data: response,
    error,
    refetch,
  } = useQuery<RolesResponse, HttpError>('listRoles', () => rbacService.getRoles());

  function transform(roles: Role[]) {
    return roles.map((role) => ({ key: role.name, ...role }));
  }

  function closeModal() {
    setIsModalVisible(false);
  }

  function openModal(role: string) {
    setIsModalVisible(true);
    setSelectedRole(role);
  }

  if (isError) {
    return (
      <div>
        <pre>{JSON.stringify(error, undefined, 2)}</pre>
      </div>
    );
  }

  if (!response) {
    return (
      <div style={{ padding: '10px', textAlign: 'center' }}>
        <Spin size="large" />
      </div>
    );
  }

  const { roles } = response;

  return (
    <>
      <div style={{ padding: '10px' }}>
        <Row gutter={[8, 16]}>
          <Table
            bordered
            sticky
            size="small"
            loading={isLoading}
            dataSource={transform(roles)}
            pagination={{ hideOnSinglePage: true }}
          >
            <Column
              width={250}
              title={t('users:Role')}
              dataIndex="name"
              key="name"
              render={(value: string) => t('users:' + capitalizeFirstLetter(value))}
            />
            <Column
              title={t('users:Permissions')}
              dataIndex="permissions"
              key="permissions"
              render={(value: string[][]) =>
                value.map(([resource, permission]) => (
                  <Tag style={{ margin: '5px' }}>{`${resource}:${permission}`}</Tag>
                ))
              }
            />
            <Column
              title={t('common:Options')}
              align="center"
              fixed="right"
              width="130px"
              key="edit"
              render={(_, role: { key: React.Key; name: string }) => (
                <Space>
                  <Tooltip title={t('common:Edit')}>
                    <Button shape="round" icon={<EditTwoTone />} onClick={() => openModal(role.name)} />
                  </Tooltip>
                </Space>
              )}
            />
          </Table>
        </Row>
      </div>

      {/* ======================= */}
      {/* EDIT PERMISSIONS DRAWER */}
      {/* ======================= */}
      <EditPermissionsDrawer role={selectedRole} open={isModalVisible} refetchRoles={refetch} onCancel={closeModal} />
    </>
  );
};
