export type ProviderName = 'localStorage' | 'sessionStorage';
export type StorageProvider = typeof localStorage | typeof sessionStorage;

export const getStorage = (name?: ProviderName) => {
  if (name === 'sessionStorage') {
    return sessionStorage;
  }

  if (name === 'localStorage') {
    return localStorage;
  }

  return undefined;
};

export const setItem = (key: string, data: any, storageProvider: StorageProvider = localStorage) => {
  if (data) {
    storageProvider.setItem(key, JSON.stringify(data));
  } else {
    storageProvider.removeItem(key);
  }
};

export const getItem = <T>(key: string, storageProvider: StorageProvider = localStorage): T | undefined => {
  const payload = storageProvider.getItem(key);
  if (payload) {
    try {
      return JSON.parse(payload);
    } catch (error) {
      return undefined;
    }
  }
  return undefined;
};

export const sessionSet = (key: string, data: any) => setItem(key, data, sessionStorage);

export const sessionGet = <T>(key: string) => getItem<T>(key, sessionStorage);
