import { HttpClient } from 'helpers/http';

import { PaginationParams } from 'types/common';

import { CommentOptions, ICommentsService, PaginatedComments } from '../../types/services/comments';

export class CommentsService implements ICommentsService {
  constructor(private client: HttpClient) {}

  API = {
    comments: '/api/comments',
  };

  list = (articleId: number, { page, perPage }: PaginationParams, { sort, commentId }: CommentOptions) => {
    let url = `${this.API.comments}?articleId=${articleId}&page=${page}&perPage=${perPage}`;
    if (sort) url = url + `&sort=${sort}`;
    if (commentId) url = url + `&commentId=${commentId}`;
    return this.client.get<PaginatedComments>(url);
  };

  delete(id: number): Promise<number> {
    const url = `${this.API.comments}/${id}`;
    return this.client.remove<number>(url);
  }
}
