import { FormOutlined } from '@ant-design/icons';
import { Button, Modal, Slider, Select, Checkbox, Input, Steps } from 'antd';
import cx from 'classnames';
import round from 'lodash/round';
import { DateTime } from 'luxon';
import mime from 'mime-types';
import { useState, useCallback, useEffect, useRef } from 'react';
import { Cropper } from 'react-cropper';
import { useTranslation } from 'react-i18next';
import watermark from 'watermarkjs';

import 'cropperjs/dist/cropper.css';

import { FileExtended, UploadFile } from 'types/files';

import { getNewCroppedImg, loadImageAsync } from './helpers';

import 'react-easy-crop/react-easy-crop.css';
import './style.scss';

const { Option } = Select;

export type ModalPromise = {
  resolve: (image: FileExtended | UploadFile) => void;
  reject: (image?: FileExtended | UploadFile) => void;
};

export interface EditImageModalProps {
  visible: boolean;
  image?: FileExtended | UploadFile;
  modalPromise?: ModalPromise;
  isNewImage?: boolean;
  squareThumbnailEdit?: boolean;
  index?: number;
  onConfirm?: (image: FileExtended) => void;
  onCancel: () => void;
  articleType?: string;
}

const aspectRations = [
  { value: 1, label: '1' },
  { value: NaN, label: 'Slobodan crop' },
  { value: round(16 / 9, 6), label: '16/9' },
  { value: round(9 / 16, 6), label: '9/16' },
];

const aspectRationsMain = [{ value: round(16 / 9, 6), label: '16/9' }];
const aspectRationsMega = [{ value: round(16 / 10, 6), label: '16/10' }];
const aspectRationsSpecial = [{ value: round(8 / 10, 6), label: '8/10' }];

export const EditImageModal = ({
  image,
  visible,
  isNewImage,
  squareThumbnailEdit = false,
  onCancel,
  onConfirm,
  modalPromise,
  index,
  articleType,
}: EditImageModalProps) => {
  const { t } = useTranslation();
  const [crop, setCrop] = useState<any>({ x: 0, y: 0 });
  const [cropSize, setCropSize] = useState({ width: 0, height: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(0);
  const [defaultAspectRatio, setDefaultAspectRatio] = useState(
    index === -1
      ? !squareThumbnailEdit
        ? aspectRations[0].value
        : aspectRations[2].value
      : articleType === 'mega' && index === 0
      ? aspectRationsMega[0].value
      : articleType === 'special' && index === 0
      ? aspectRationsSpecial[0].value
      : articleType === 'special' && index === 1
      ? aspectRationsMain[0].value
      : aspectRations[1].value,
  );
  const [aspectRatio, setAspectRation] = useState(defaultAspectRatio);
  const [useWatermark, setUseWatermark] = useState(false);
  const [watermarkLocation, setWatermarkLocation] = useState('upperRight');
  const [localImage, setLocalImage] = useState<string | undefined>(image?.url);
  const [waterMarkFile, setWatermarkFile] = useState({ url: '' });
  const [darkImage, setDarkImage] = useState(true);
  const [imageData, setImageData] = useState({ description: '', source: '', caption: '' });
  const waterMarkApplied = useRef<boolean>(false);
  const imageSize = useRef<{ width: number; height: number } | null>(null);
  const [modalWarning, setModalWarning] = useState(false);
  const [imageWidthWarning, setImageWidthWarning] = useState(false);
  const [sourcePrintscreenWarning, setSourcePrintscreenWarning] = useState(false);
  const cropperRef = useRef<HTMLImageElement>(null);
  const [oldWatermarkDimensions, setOldWatermakrDimensions] = useState({ x: 0, y: 0, width: 0, height: 0 });
  const [current, setCurrent] = useState(0);

  const next = () => {
    //ovdje odradit ono sto se nalazi u onconfirm changes da se dobije cropana slika i njen url smjestit u state
    setCurrent(current + 1);
    getCropData();
  };

  const prev = () => {
    setCurrent(current - 1);
    resetState(true);
  };

  const [cropData, setCropData] = useState('#');
  const [cropper, setCropper] = useState<any>();
  const [cropDataNoWatermark, setCropDataNoWatermark] = useState<string | undefined>('');

  const getCropData = () => {
    if (typeof cropper !== 'undefined') {
      if (cropper.getCroppedCanvas()) setCropData(cropper.getCroppedCanvas().toDataURL());
    }
  };

  useEffect(() => {
    if (cropper && rotation !== undefined) {
      cropper.reset();
      cropper.rotate(rotation);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rotation]);

  useEffect(() => {
    if (cropper !== undefined) cropper.zoomTo(zoom);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zoom]);

  const [imageWidth, setImageWidth] = useState(0);

  const img = new Image();
  img.onload = () => {
    setImageWidth(img.width);
  };
  useEffect(() => {
    //console.log(cropData, 'hajde da vidimo');

    if (cropData !== '#') {
      img.src = cropData;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cropData]);

  useEffect(() => {
    setDefaultAspectRatio(
      squareThumbnailEdit
        ? aspectRations[0].value
        : articleType === 'mega' && index === 0
        ? aspectRationsMega[0].value
        : articleType === 'special' && index === 0
        ? aspectRationsSpecial[0].value
        : articleType === 'special' && index === 1
        ? aspectRationsMain[0].value
        : aspectRations[2].value,
    );
    resetState();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [squareThumbnailEdit, setDefaultAspectRatio]);

  useEffect(() => {
    setLocalImage(image?.url);
    setImageData({
      description: image?.description || '',
      source: image?.source || '',
      caption: image?.caption || '',
    });
    setAspectRation(defaultAspectRatio);
  }, [defaultAspectRatio, image]);

  const onAspectChange = (value: any) => {
    setAspectRation(value);
    cropper.setAspectRatio(value);
  };

  const onWatermarkChange = (e: any) => {
    setCropDataNoWatermark(cropData);
    setUseWatermark(e.target.checked);
    if (e.target.checked) {
      setWatermarkFile({ url: '/avazWM.png' });
    } else {
      if (cropDataNoWatermark !== undefined) setCropData(cropDataNoWatermark);
      setWatermarkFile({ url: '' });
      setWatermarkLocation('upperRight');
      setDarkImage(true);
    }
  };

  const onWatermarkLocationChange = (value: any) => {
    if (cropDataNoWatermark !== undefined) setCropData(cropDataNoWatermark);
    setWatermarkLocation(value);
    //change watermark image depending on the chosen location
    if (value === 'upperRight') {
      setWatermarkFile({ url: '/avazWM.png' });
      setDarkImage(true);
    } else if (value === 'center') {
      setWatermarkFile({ url: '/avazRed.png' });
      setDarkImage(false);
    }
  };

  const setCropProperties = () => {
    const imageElement: any = cropperRef?.current;
    const cropper: any = imageElement?.cropper;
    const widthCrop = cropper.getData().width;
    const heightCrop = cropper.getData().height;
    const xCrop = cropper.getData().x;
    const yCrop = cropper.getData().y;

    setCrop({ x: xCrop, y: yCrop });
    setCropSize({ width: widthCrop, height: heightCrop });
    setOldWatermakrDimensions({ x: xCrop, y: yCrop, width: widthCrop, height: heightCrop });
  };

  const confirmImageChanges = useCallback(async () => {
    try {
      if ((!imageData.description || !imageData.source || !imageData.caption) && !squareThumbnailEdit) {
        setModalWarning(true);
      } else if (imageWidth < 1440 && index === 0 && articleType === 'mega') {
        setImageWidthWarning(true);
        setModalWarning(false);
      } else if (imageData.source.toLowerCase().includes('printscreen')) {
        setSourcePrintscreenWarning(true);
        setImageWidthWarning(false);
        setModalWarning(false);
      } else if (image) {
        let rawImg = await loadImageAsync(cropData as string);

        //const rawImg = await loadImageAsync(localImage as string);
        const type = image.type || (image as UploadFile).response.mimeType;

        const cr = await getNewCroppedImg(
          rawImg,
          type,
          { x: crop.x, y: crop.y, height: cropSize.height, width: cropSize.width },
          0,
          1,
        );

        let file: unknown;
        if (cr) {
          if (image.type === 'image/gif') {
            file = image;
          } else {
            file = new File([cr], `${image.name}.${mime.extension(cr.type)}`, {
              lastModified: new Date().getTime(),
              type: cr.type,
            });
          }
          (file as FileExtended).description = imageData.description;
          (file as FileExtended).caption = imageData.caption;
          (file as FileExtended).source = imageData.source;
          (file as FileExtended).url = '';
        }
        image.description = imageData.description;
        image.source = imageData.source;
        image.caption = imageData.caption;

        if (modalPromise) {
          file || isNewImage ? modalPromise?.resolve((file || image) as FileExtended) : modalPromise?.reject(image);
        } else {
          onConfirm?.((file || image) as FileExtended);
        }
        setCurrent(1);
        getCropData();
        setTimeout(() => {
          resetState();
          setCurrent(0);
        }, 20);
      }
    } catch (e) {
      console.error(e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [image, rotation, modalPromise, imageData, useWatermark, cropData]);

  const resetState = (clearData = false) => {
    setRotation(0);
    setZoom(0);
    setAspectRation(defaultAspectRatio);
    setUseWatermark(false);
    setWatermarkLocation('upperRight');
    setWatermarkFile({ url: '' });
    setDarkImage(true);
    if (!clearData) setImageData({ description: '', source: '', caption: '' });
    resetImage();
    setModalWarning(false);
    setImageWidthWarning(false);
    setSourcePrintscreenWarning(false);
    //if (cropper !== undefined) cropper.zoomTo(0);
  };

  const resetImage = () => {
    waterMarkApplied.current = false;
    setLocalImage('');
    setLocalImage(image?.url);
  };

  const handleCancel = () => {
    setCurrent(1);
    getCropData();
    setTimeout(() => {
      if (modalPromise) {
        onCancel();
      } else {
        onCancel();
      }

      resetState();
      setCurrent(0);
    }, 20);
  };

  const onImageDataChange = (e: any) => {
    let dataValueTemp = String(e.target.value);
    let check = false;
    const forbiddenSources = ['arhiva', 'agencije', 'agencijsk', 'ilustracij'];
    if (
      e.target.name === 'source' &&
      forbiddenSources.some((source) => e.target.value.toLowerCase().includes(source))
    ) {
      dataValueTemp = '';
      check = true;
    }
    setImageData({ ...imageData, [e.target.name]: dataValueTemp });
    if (check) alert('Zabranjeno korištenje potpisa ' + e.target.value + ' kao izvor!');
  };

  const adjustCropSize = useCallback(() => {
    if (imageSize.current) {
      const { width, height } = imageSize.current;
      const ratioWidth = height * aspectRatio;

      if (width > ratioWidth) {
        setCropSize({ width: ratioWidth, height });
      } else {
        setCropSize({ width, height: width / aspectRatio });
      }
    }
  }, [aspectRatio]);

  useEffect(() => {
    adjustCropSize();
  }, [adjustCropSize, aspectRatio]);

  var getX = function (coffee: any, logo: any) {
    return cropSize.width - 115;
  };

  var getY = function (coffee: any, logo: any) {
    return 0;
  };

  useEffect(() => {
    if (cropData && watermarkLocation && waterMarkFile.url !== '') {
      waterMarkApplied.current = true;
      //define opacity of the watermark depending on the image that is used
      let opacity: number;
      darkImage ? (opacity = 0.33) : (opacity = 0.22);
      if (!cropData.includes('data')) {
        const getBlob = async function (data: string, opacity: number, watermarkLocation: string) {
          let blob = await fetch(data).then((r) => r.blob());
          let dataUrl = await new Promise((resolve) => {
            let reader = new FileReader();
            reader.onload = () => resolve(reader.result);
            reader.readAsDataURL(blob);
          });
          watermark([dataUrl, waterMarkFile.url])
            .image((image: any, logo: any) => {
              var context = image.getContext('2d');
              context.save();
              context.globalAlpha = opacity;
              context.drawImage(logo, 10, 10);
              context.restore();
              return image;
            })
            .then(function (img: HTMLImageElement) {
              setLocalImage(img.currentSrc);
            });
          watermark.destroy();
          setLocalImage(dataUrl as string);
        };
        getBlob(cropData, opacity, watermarkLocation).catch((e) => console.log(e));
      } else {
        watermark([cropData, waterMarkFile.url])
          .image(
            watermarkLocation === 'center'
              ? watermark.image[watermarkLocation](opacity)
              : watermark.image.atPos(getX, getY, opacity),
          )
          .then(function (img: HTMLImageElement) {
            setCropData(img.currentSrc);
            //setLocalImage(img.currentSrc);
          });
        watermark.destroy();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [waterMarkFile, watermarkLocation]);

  // const handleToggleAllImages = (checked: boolean) => {
  //   setToggleAllImages(checked);
  // };

  const { Step } = Steps;

  const steps = [
    {
      title: 'Uređivanje slike',
      content: (
        <div className="flex">
          {image && (
            <div className="crop-container" style={{ position: 'relative', height: 400, width: 600 }}>
              <Cropper
                src={localImage}
                rotateTo={rotation}
                initialAspectRatio={
                  squareThumbnailEdit
                    ? aspectRations[0].value
                    : articleType === 'mega' && index === 0
                    ? aspectRationsMega[0].value
                    : articleType === 'special' && index === 0
                    ? aspectRationsSpecial[0].value
                    : articleType === 'special' && index === 1
                    ? aspectRationsMain[0].value
                    : aspectRations[2].value
                }
                aspectRatio={
                  squareThumbnailEdit
                    ? aspectRations[0].value
                    : articleType === 'mega' && index === 0
                    ? aspectRationsMega[0].value
                    : articleType === 'special' && index === 0
                    ? aspectRationsSpecial[0].value
                    : articleType === 'special' && index === 1
                    ? aspectRationsMain[0].value
                    : aspectRations[2].value
                }
                autoCropArea={1}
                autoCrop={true}
                viewMode={1}
                crop={setCropProperties}
                zoomOnWheel={false}
                background={false}
                onInitialized={(instance) => {
                  setCropper(instance);
                  instance.setCropBoxData(oldWatermarkDimensions);
                }}
                ref={cropperRef}
                style={{ height: 400, width: 600 }}
              />
            </div>
          )}
          <div style={{ width: 300, padding: 10 }}>
            {!isNewImage && image?.createdAt && (
              <div>
                {t('common:Created At')}:{' '}
                {DateTime.fromISO(image.createdAt).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)}
              </div>
            )}
            {!squareThumbnailEdit && (
              <div className="flex items-center" style={{ marginBottom: '20px' }}>
                {t('images:Aspect ratio')}
                <Select
                  defaultValue={defaultAspectRatio}
                  value={aspectRatio}
                  className="flex-grow"
                  style={{ marginLeft: 10 }}
                  onChange={onAspectChange}
                >
                  {index === -1
                    ? aspectRationsMain.map((a) => (
                        <Option key={a.label} value={a.value}>
                          {a.label}
                        </Option>
                      ))
                    : articleType === 'mega' && index === 0
                    ? aspectRationsMega.map((a) => (
                        <Option key={a.label} value={a.value}>
                          {a.label}
                        </Option>
                      ))
                    : articleType === 'special' && index === 0
                    ? aspectRationsSpecial.map((a) => (
                        <Option key={a.label} value={a.value}>
                          {a.label}
                        </Option>
                      ))
                    : articleType === 'special' && index === 1
                    ? aspectRationsMain.map((a) => (
                        <Option key={a.label} value={a.value}>
                          {a.label}
                        </Option>
                      ))
                    : aspectRations.map((a) => (
                        <Option key={a.label} value={a.value}>
                          {a.label}
                        </Option>
                      ))}
                </Select>
              </div>
            )}
            <div style={{ marginBottom: '20px' }}>
              {t('images:Rotation')}
              <Slider min={-180} max={180} value={rotation} onChange={setRotation} />
            </div>

            {t('images:Zoom')}
            <Slider min={0} max={3} step={0.1} value={zoom} onChange={setZoom} />
            {/*<Button style={{ marginTop: 10, display: 'block' }} onClick={resetState}>
              {t('images:Undo changes')}
                    </Button>*/}
          </div>
        </div>
      ),
    },
    {
      title: 'Opisi slike',
      content: (
        <div className="flex">
          {image && (
            <div className="flex block">
              <div className="crop-container" style={{ position: 'relative', height: 'auto', width: 590 }}>
                <img
                  src={cropData}
                  alt=""
                  style={{
                    display: 'block',
                    maxWidth: '590px',
                    width: cropSize.width + 'px',
                    margin: 'auto',
                    //height: '400px',
                    height: 'auto',
                    maxHeight: '700px',
                    objectFit: 'contain',
                  }}
                />
              </div>
              {imageWidthWarning && (
                <p style={{ color: 'red', marginTop: '10px', textAlign: 'center', fontWeight: 'bolder' }}>
                  Minimalna širina glavne slike za mega članak je 1440 piksela!
                </p>
              )}
            </div>
          )}
          <div style={{ width: 300, padding: 10 }}>
            {!isNewImage && image?.createdAt && (
              <div>
                {t('common:Created At')}:{' '}
                {DateTime.fromISO(image.createdAt).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)}
              </div>
            )}
            {!squareThumbnailEdit && (
              <>
                {/* <div style={{ marginBottom: '5px', display: 'flex' }}>
            <span style={{ width: '70%' }}>{t('images:Apply to all images')}</span>
            <Switch style={{ marginLeft: 'auto' }} onChange={handleToggleAllImages} />
          </div> */}
                <div className="flex items-center" style={{ marginBottom: 10 }}>
                  {t('images:Caption')}
                  <Input
                    required
                    style={{ marginLeft: 10 }}
                    value={imageData.caption}
                    name="caption"
                    onChange={onImageDataChange}
                  />
                </div>
                <div className="flex items-center" style={{ marginBottom: 10 }}>
                  {t('images:Source')}
                  <Input
                    required
                    style={{ marginLeft: 10 }}
                    value={imageData.source}
                    name="source"
                    onChange={onImageDataChange}
                  />
                </div>
                <div className="flex items-center" style={{ marginBottom: 10 }}>
                  {t('images:Description')}
                  <Input
                    required
                    style={{ marginLeft: 10 }}
                    value={imageData.description}
                    name="description"
                    onChange={onImageDataChange}
                  />
                </div>

                {modalWarning && (
                  <p style={{ color: 'red' }}>{t('images:Source, description and caption are required')}</p>
                )}
                {sourcePrintscreenWarning && (
                  <p style={{ color: 'red' }}>Zabranjeno je unositi vrijednost "Printscreen" u polje Izvor!</p>
                )}
              </>
            )}

            {!squareThumbnailEdit && (
              <div className={cx('image-group-container', { expanded: useWatermark })}>
                <Checkbox
                  checked={useWatermark}
                  onChange={onWatermarkChange}
                  style={{ fontWeight: '300', marginTop: '5px' }}
                >
                  {t('images:Watermark')}
                </Checkbox>
                {useWatermark && (
                  <Select defaultValue="upperRight" style={{ width: 120 }} onChange={onWatermarkLocationChange}>
                    <Option value="upperRight">{t('images:Upper right')}</Option>
                    <Option value="center">{t('images:Center')}</Option>
                  </Select>
                )}
              </div>
            )}
          </div>
        </div>
      ),
    },
  ];

  return (
    <Modal
      width={900}
      centered
      mask={true}
      maskClosable={false}
      onCancel={handleCancel}
      visible={visible}
      title={
        <div>
          <FormOutlined style={{ marginRight: '5px' }} />
          <span>{t('images:Edit image')}</span>
        </div>
      }
      footer={[
        current > 0 && (
          <Button key="submit" type="primary" onClick={confirmImageChanges}>
            {t('common:Confirm')}
          </Button>
        ),
      ]}
    >
      <Steps current={current} style={{ marginBottom: '10px' }}>
        {steps.map((item) => (
          <Step key={item.title} title={item.title} />
        ))}
      </Steps>

      <div className="steps-content">{steps[current].content}</div>

      <div className="steps-action" style={{ display: 'flex' }}>
        {current < steps.length - 1 && (
          <Button type="primary" style={{ marginLeft: 'auto' }} onClick={() => next()}>
            Sljedeći korak
          </Button>
        )}

        {current > 0 && (
          <Button style={{ marginLeft: 'auto' }} onClick={() => prev()}>
            Prethodni korak
          </Button>
        )}
      </div>
    </Modal>
  );
};
