export type CancelablePromise<T> = Promise<T> & { cancel?: () => void };

export function makeCancelablePromise<T>(promise: CancelablePromise<T>) {
  let rejectFn: Function;

  const wrappedPromise: CancelablePromise<T> = new Promise((resolve, reject) => {
    rejectFn = reject;

    Promise.resolve(promise).then(resolve).catch(reject);
  });

  wrappedPromise.cancel = () => {
    rejectFn({ canceled: true });
  };

  return wrappedPromise;
}
