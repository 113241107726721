import './index.scss';
import { DeleteTwoTone, EditTwoTone } from '@ant-design/icons';
import { Button, Space, Tooltip } from 'antd';
import { Draggable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';

export const ArticleBlockItem = ({ block, index, getItemStyle, isDndDisabled, onDelete, onEdit }: any) => {
  const { t } = useTranslation();

  return (
    <Draggable draggableId={block.id.toString()} index={index} isDragDisabled={isDndDisabled}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={getItemStyle(snapshot.isDragging, provided.draggableProps.style, block)}
          className="article-block-row"
        >
          <div className="article-block-left">{block.name}</div>
          {isDndDisabled && (
            <div className="article-block-right">
              <Space>
                <Tooltip title={t('common:Edit')}>
                  <Button shape="round" icon={<EditTwoTone />} onClick={() => onEdit(block.id)} />
                </Tooltip>
                <Tooltip title={t('common:Delete')}>
                  <Button
                    shape="round"
                    icon={<DeleteTwoTone twoToneColor="#eb2f96" />}
                    onClick={() => onDelete(block.id)}
                  />
                </Tooltip>
              </Space>
            </div>
          )}
        </div>
      )}
    </Draggable>
  );
};
