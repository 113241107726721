import produce from 'immer';
import { Reducer } from 'redux';

import { GenericFunction } from 'types/common';
import { ActionCreator, ActionsMapReducer, StoreAction } from 'types/helpers/redux';

export function createAction<T extends GenericFunction>(
  type: string,
  payloadCreator: T,
): ActionCreator<Parameters<T>, ReturnType<T>> {
  if (!payloadCreator) {
    throw new TypeError('Expected a function');
  }

  return (...args: any[]) => ({
    type,
    payload: payloadCreator(...args),
  });
}

export function createReducer<State>(
  actionsMap: ActionsMapReducer<State>,
  defaultState: State,
): Reducer<State, StoreAction> {
  return (state = defaultState, action: StoreAction) =>
    produce(state, (draft: State) => {
      const fn = actionsMap[action.type];

      if (fn) {
        return fn(draft, action);
      }

      return draft;
    });
}
