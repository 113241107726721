import './index.scss';
import { Droppable } from 'react-beautiful-dnd';

import { ArticleBlock } from '../../../../../types/services/article-blocks';
import { ArticleBlockItem } from '../ArticleBlockItem/ArticleBlockItem';

export const ArticleBlocksList = ({
  articleBlocks,
  droppableId,
  getListStyle,
  getItemStyle,
  isDndDisabled,
  onDelete,
  onEdit,
}: any) => {
  return (
    <Droppable droppableId={droppableId} isDropDisabled={isDndDisabled}>
      {(provided, snapshot) => (
        <div ref={provided.innerRef} {...provided.droppableProps} className="article-blocks-container">
          <div className="inner-container" style={getListStyle(snapshot.isDraggingOver)}>
            {articleBlocks.map((block: ArticleBlock, index: number) => (
              <ArticleBlockItem
                block={block}
                index={index}
                key={block.id}
                getItemStyle={getItemStyle}
                isDndDisabled={isDndDisabled}
                onDelete={onDelete}
                onEdit={onEdit}
              />
            ))}
            {provided.placeholder}
          </div>
        </div>
      )}
    </Droppable>
  );
};
