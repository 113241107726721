import { DataNode } from 'antd/es/tree';

import { Category, CategoryUpdate } from 'types/services/categories';

export interface DataNodeExpand extends DataNode {
  modified?: boolean;
  color: string;
  id: number;
  position: number;
  children: DataNodeExpand[];
  hasAds: boolean;
}

export const loop = (
  data: DataNodeExpand[],
  key: number,
  callback: (item: DataNodeExpand, index: number, arr: DataNodeExpand[], parent?: DataNodeExpand) => void,
  parent?: DataNodeExpand,
) => {
  for (let i = 0; i < data.length; i++) {
    if (data[i].key === key) {
      return callback(data[i], i, data, parent);
    }
    if (data[i].children) {
      loop(data[i].children as DataNodeExpand[], key, callback, data[i]);
    }
  }
};

export const transform = (category: Category): DataNodeExpand =>
  ({ ...category, key: category.id, children: (category.children || []).map(transform) } as unknown as DataNodeExpand);

export const flattenCategories = (categories: DataNodeExpand[], parentId: number | null = null) => {
  const categoriesForUpdate: CategoryUpdate[] = [];
  categories.forEach((category) => {
    if (category.modified) {
      categoriesForUpdate.push({
        id: category.key as number,
        title: category.title as string,
        parentId: parentId,
        color: category.color,
        position: category.position,
        hasAds: category.hasAds,
      });
    }
    if (category.children) {
      categoriesForUpdate.push(...flattenCategories(category.children, category.key as number));
    }
  });
  return categoriesForUpdate;
};
