import session, { sessionState } from './session';
import sidebar, { sidebarState } from './sidebar';

export const initialState = {
  session: sessionState,
  sidebar: sidebarState,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ...session,
  ...sidebar,
};
