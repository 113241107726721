import { AxiosRequestConfig } from 'axios';

import { HttpClient } from 'helpers/http';

import { PaginationParams } from 'types/common';
import { Crossword, CrosswordPayload, ICrosswordsService, PaginatedCrosswords } from 'types/services/crosswords';

export class CrosswordsService implements ICrosswordsService {
  constructor(private client: HttpClient) {}

  API = {
    crosswords: '/api/crossword',
    uploadImage: '/api/crossword/crosswordImage',
    uploadBackgroundImage: '/api/crossword/crosswordBackgroundImage',
  };

  list = ({ page, perPage }: PaginationParams) => {
    let url = `${this.API.crosswords}?`;
    const params: Record<string, any> = { page, perPage };

    url =
      url +
      Object.keys(params)
        .map((key) => {
          const value = params[key];
          return Array.isArray(value)
            ? value.map((v) => `${key}=${encodeURIComponent(v)}`).join('&')
            : `${key}=${encodeURIComponent(value)}`;
        })
        .join('&');
    return this.client.get<PaginatedCrosswords>(url);
  };

  create = (crossword: CrosswordPayload) => {
    return this.client.post<Crossword>(this.API.crosswords, { crossword });
  };

  // delete = (id: number, email?: string) => {
  //   let url = `${this.API.crosswords}/${id}`;
  //   if (email) url += '?user=' + email;
  //   return this.client.remove<number>(url);
  // };

  get = (id: number) => {
    const url = `${this.API.crosswords}/${id}`;
    return this.client.get<any>(url);
  };

  patch = (crossword: any) => {
    const url = `${this.API.crosswords}`;
    return this.client.patch<Crossword>(url, { crossword });
  };

  uploadImage = async (options: any) => {
    const { onProgress, file } = options;
    const url = this.API.uploadImage;

    const imageData = [{ description: file.description || '', source: file.source }];

    const formData = new FormData();
    formData.append('images', file);
    formData.append('imagesData', JSON.stringify(imageData));

    const config: AxiosRequestConfig = {
      headers: { 'content-type': 'multipart/form-data' },
      onUploadProgress: onProgress,
    };

    return this.client.post<{ images: any }>(url, formData, config);
  };

  uploadBackgroundImage = async (options: any) => {
    const { onProgress, file } = options;
    const url = this.API.uploadImage;

    const imageData = [{ description: file.description || '', source: file.source }];

    const formData = new FormData();
    formData.append('images', file);
    formData.append('imagesData', JSON.stringify(imageData));

    const config: AxiosRequestConfig = {
      headers: { 'content-type': 'multipart/form-data' },
      onUploadProgress: onProgress,
    };

    return this.client.post<{ images: any }>(url, formData, config);
  };
}
