import { CancelablePromise } from './makeCancelablePromise';

export function delay(delayInms: number, delayedFunction?: Function) {
  let timeoutID: any;
  const cancelablePromise: CancelablePromise<string> = new Promise((resolve) => {
    timeoutID = setTimeout(() => {
      resolve(timeoutID);
      if (typeof delayedFunction === 'function') {
        delayedFunction();
      }
    }, delayInms);
  });
  cancelablePromise.cancel = () => {
    clearTimeout(timeoutID);
  };
  return cancelablePromise;
}
