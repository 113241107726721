import { createReducer } from 'helpers/redux';

import { ActionTypes } from 'literals/index';

import { Item, Submenu, isSubmenu } from 'types/common';
import { StoreAction } from 'types/helpers/redux';
import { SidebarState } from 'types/store';

export const sidebarState: SidebarState = {
  entries: [],
  activeSubmenuKeys: [],
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  sidebar: createReducer<SidebarState>(
    {
      // REGISTER ENTRIES
      [ActionTypes.REGISTER_ENTRIES]: (draft: SidebarState, action: StoreAction<any>) => {
        const entries = action.payload as (Item | Submenu)[];
        const submenus = entries.filter((entry) => isSubmenu(entry)) as Submenu[];
        entries.forEach((entry: any) => {
          const index = draft.entries.findIndex((e) => e.key === entry.key);
          if (index === -1) {
            draft.entries.push(entry);
          }
        });
        submenus.forEach(({ key, items }) => {
          items.findIndex((i) => i.path === window.location.pathname) > -1 && draft.activeSubmenuKeys.push(key);
        });
      },

      // TOGGLE SUBMENU
      [ActionTypes.TOGGLE_SUBMENU]: (draft: SidebarState, action: StoreAction<any>) => {
        const submenuKey = action.payload as string;
        const index = draft.activeSubmenuKeys.findIndex((key) => key === submenuKey);
        index > -1 ? draft.activeSubmenuKeys.splice(index, 1) : draft.activeSubmenuKeys.push(submenuKey);
      },

      // CLOSE ALL SUBMENUS
      [ActionTypes.CLOSE_ALL_SUBMENUS]: (draft: SidebarState, action: StoreAction<any>) => {
        draft.activeSubmenuKeys = [];
      },
    },
    sidebarState,
  ),
};
