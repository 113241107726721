import './index.scss';

export const Popularity = ({ numberOfLikes, numberOfDislikes }: PopularityProps) => {
  return (
    <div className="popularity-container">
      <div className="likes">+{numberOfLikes}</div>
      <div className="dislikes">-{numberOfDislikes}</div>
    </div>
  );
};

export interface PopularityProps {
  numberOfLikes: number;
  numberOfDislikes: number;
}
