import { PlusOutlined, UploadOutlined, PictureOutlined } from '@ant-design/icons';
import { Button, message, Upload } from 'antd';
import { Image as Picture } from 'antd';
import cx from 'classnames';
import { useState, useRef, useEffect, useCallback } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { EditCoverImageModal, ModalPromise } from 'components/EditImageModal/EditCoverImageModal';

import { coverImagesService } from 'config/services';

import { getBase64 } from 'helpers/shared';

import { UploadFile, FileExtended } from 'types/files';
import { Image } from 'types/services/images';

import { mapCoverImageToUploadFile } from '../articles/components/helpers';

import { CoverImageLibraryModal } from './CoverImageLibraryModal';

import './UploadCoverImages.scss';

interface UploadImagesProps {
  value?: Image | Image[];
  onChange?: (images: Image[]) => void;
  maxCount?: number;
  multiple?: boolean;
  draggable?: boolean;
  mode?: 'row' | 'card';
  showSquareThumbnail?: boolean;
}

export const UploadCoverImages = ({
  value,
  maxCount,
  multiple = false,
  draggable = false,
  mode = 'card',
  showSquareThumbnail,
  onChange,
}: UploadImagesProps) => {
  const { t } = useTranslation();
  const [selectedImage, setSelectedImage] = useState<{ isNewImage: boolean; image?: FileExtended | UploadFile }>();
  const editImageModalPromise = useRef<ModalPromise>();
  const [showCoverImageLibraryModal, setShowCoverImageLibraryModal] = useState(false);
  const [refreshCoverImageLibraryModal, setRefreshCoverImageLibraryModal] = useState(false);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [squareImageEdit] = useState(false);
  const { data: latestCover, refetch } = useQuery('getCoverImage', () => coverImagesService.get());
  const [latestURL, setLatestURL] = useState(latestCover?.imageUrl);

  useEffect(() => {
    setLatestURL(latestCover?.imageUrl);
  }, [latestCover]);
  const isCardMode = mode === 'card';

  useEffect(() => {
    setFileList((value ? (value instanceof Array ? value : [value]) : []).map(mapCoverImageToUploadFile));
  }, [value]);

  const handleChange = useCallback(
    (files: UploadFile[]) => {
      setFileList(files);

      if (files.every((f) => f.status === 'done')) {
        onChange?.(files.map((f) => f.response as Image));
      }
    },
    [onChange],
  );

  const handleUploadChange = (info: any) => {
    const { fileList, file } = info;
    if (file.percent === 100) {
      const image = fileList.find((i: UploadFile) => i.uid === file.uid);
      image.url = file.response.imageUrl;
      image.uid = file.response.id;
      image.description = file.response.description;
      image.caption = file.response.caption;
      image.source = file.response.source;
    }
    handleChange(fileList);
  };

  const customRequest = async (options: any) => {
    const { onSuccess = () => {}, onError = () => {} } = options;

    try {
      const { image } = await coverImagesService.create(options);
      onSuccess({
        ...image,
      });
      refetch();
      setRefreshCoverImageLibraryModal(true);
    } catch (error: any) {
      onError(error);
    }
    setRefreshCoverImageLibraryModal(false);
  };

  const closeEditImageModal = () => {
    setSelectedImage(undefined);
  };

  const attachData = (file: UploadFile) => {
    return new Promise<Record<string, any>>(async (resolve, reject) => {
      if (!file.url) {
        const base64String = await getBase64(file as unknown as Blob);
        file.url = base64String;
      }
      resolve(file);
    });
  };

  const beforeUpload = async (file: File, fileList: File[]) => {
    const isImage = ['image/jpg', 'image/jpeg', 'image/png', 'image/gif'].includes(file.type);
    if (!isImage) {
      message.error(`Niste odabrali sliku!`);
      return Upload.LIST_IGNORE;
    }

    const modalPromise = new Promise<any>((resolve, reject) => {
      editImageModalPromise.current = { resolve, reject };
    });
    if (fileList.length > 1) {
      return Promise.resolve(true);
    }
    modalPromise.finally(closeEditImageModal);

    const fileExtended = file as unknown as FileExtended;
    const base64String = await getBase64(file);
    fileExtended.url = base64String;

    setSelectedImage({ isNewImage: true, image: fileExtended });
    return modalPromise;
  };

  const toggleCoverImageLibraryModal = () => {
    setShowCoverImageLibraryModal((value) => !value);
    refetch();
  };

  return (
    <div style={{ padding: '8px' }} className="relative cover">
      <div className="coverRow">
        <p className="newCoverText">{t('settings:Add new cover image')}</p>
        <DndProvider backend={HTML5Backend}>
          <Upload
            listType={isCardMode ? 'picture-card' : 'picture'}
            fileList={fileList}
            onChange={handleUploadChange}
            customRequest={customRequest}
            data={attachData}
            beforeUpload={beforeUpload}
            maxCount={maxCount}
            multiple={multiple}
            accept=".jpg,.jpeg,.png,.gif"
          >
            {isCardMode ? (
              <div>
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>{t('images:upload')}</div>
              </div>
            ) : (
              <Button icon={<UploadOutlined />}>{t('images:upload')}</Button>
            )}
          </Upload>
        </DndProvider>
      </div>
      <div className="currentCover">
        <p className="currentCoverText">{t('settings:Current cover image')}</p>
        <Picture width={287} src={latestURL} />
      </div>

      <Button
        onClick={toggleCoverImageLibraryModal}
        icon={<PictureOutlined />}
        style={{ marginTop: '10px', marginLeft: '25px', marginBottom: '20px', width: '287px' }}
        className={cx({ 'choose-existing-btn': !isCardMode })}
      >
        {t('images:All cover images')}
      </Button>

      <CoverImageLibraryModal
        alreadySelectedIds={fileList.map((f) => parseInt(f.uid))}
        maxCount={maxCount}
        open={showCoverImageLibraryModal}
        refresh={refreshCoverImageLibraryModal}
        onClose={toggleCoverImageLibraryModal}
        onConfirm={toggleCoverImageLibraryModal}
      />

      <EditCoverImageModal
        image={selectedImage?.image}
        visible={!!selectedImage}
        isNewImage={selectedImage?.isNewImage}
        squareThumbnailEdit={squareImageEdit}
        modalPromise={editImageModalPromise.current}
        onCancel={closeEditImageModal}
        isCover={true}
      />
    </div>
  );
};
