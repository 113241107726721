import { memo } from 'react';
import { ImGift } from 'react-icons/im';
import ImageGallery from 'react-image-gallery';

import { ImagesBlock } from 'types/services/articles';
import { Image } from 'types/services/images';

import { UploadImages } from '../UploadImages/UploadImages';

interface ImageBlockInteface {
  content: ImagesBlock['content'];
  isEditing: boolean;
  images: Image[];
  index?: number;
  articleType?: string;
  onChange: (content: any) => void;
  onImagesChange: (images: Image[]) => void;
  onThumnailSelect?: (image: Image) => void;
}

const ImagesBlockComponent = ({
  content,
  images,
  isEditing,
  index,
  articleType,
  onChange,
  onImagesChange,
  onThumnailSelect,
}: ImageBlockInteface) => {
  const selectedImages = content.imageIds
    ? (content.imageIds.map((imageId) => images.find((img) => img.id === imageId)).filter((img) => !!img) as Image[])
    : [];

  const getDesc = (img: any) => {
    let desc = img.caption ? img.caption : '';
    if (desc && img.source) desc += '.';
    if (img.source) desc += img.source;
    return desc;
  };
  const galleryImages = selectedImages.map((img) => ({
    original: img.landscape,
    fullscreen: img.imageUrl,
    description: getDesc(img),
  }));

  const renderImageOrGallery = () => {
    return (
      <>
        {selectedImages.length > 1 ? (
          <div className="article-gallery">
            <ImageGallery items={galleryImages} showIndex showPlayButton={false} useBrowserFullscreen={true} />
          </div>
        ) : (
          <div className="article-image">
            <img src={selectedImages[0]?.imageUrl} alt={selectedImages[0]?.caption} width="100%" />
            <div className="image-caption">
              {selectedImages[0]?.caption ? selectedImages[0]?.caption.trim() : ''}
              {selectedImages[0]?.caption && selectedImages[0].source ? '. ' : ''} <i>{selectedImages[0].source}</i>
            </div>
          </div>
        )}
      </>
    );
  };

  const handleImagesChange = (newImages: Image[]) => {
    onChange({ imageIds: newImages.map((image) => image.id) });
    onImagesChange(newImages);
  };

  return isEditing || !selectedImages.length ? (
    <div className="image-picking-container editing-container">
      <UploadImages
        value={selectedImages}
        onThumbnailSelect={onThumnailSelect}
        index={index}
        multiple
        draggable
        onChange={handleImagesChange}
        articleType={articleType}
      />
    </div>
  ) : (
    renderImageOrGallery()
  );
};

export default memo(ImagesBlockComponent);
