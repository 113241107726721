import { format } from 'date-fns';

import { LoggerOptions } from 'types/helpers/logger';

/**
 * Log grouped messages to the console
 * TODO: disable in production based on env variable - Solved
 */
export function logger(type: string, title: string, data: unknown, options: LoggerOptions = {}): void {
  /* eslint-disable no-console */
  const { collapsed = true, hideTimestamp = false, skip = false, typeColor = 'gray' } = options;
  const groupMethod = collapsed ? console.groupCollapsed : console.group;

  /* istanbul ignore else */
  if (process.env.NODE_ENV === 'development') {
    const parts = [`%c ${type}`];
    const styles = [`color: ${typeColor}; font-weight: lighter;`, 'color: inherit;'];
    const time = format(new Date(), 'HH:mm:ss');

    if (!hideTimestamp) {
      styles.push('color: gray; font-weight: lighter;');
    }

    parts.push(`%c${title}`);

    if (!hideTimestamp) {
      parts.push(`%c@ ${time}`);
    }

    /* istanbul ignore else */
    if (!skip && !window.HIDE_LOGS) {
      groupMethod(parts.join(' '), ...styles);
      console.log(data);
      console.groupEnd();
    }
  }
  /* eslint-enable no-console */
}

export const info = (title: string, data: unknown, options: LoggerOptions = {}) => logger('info', title, data, options);
