import { Row, Table, Spin, DatePicker } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { statisticsService } from 'config/services';

import { HttpError } from 'helpers/http';

import { StatisticsOptions, DateData, PaginatedByDay } from 'types/services/statistics';

import { transformByDay } from './utils';

export const ByDay = () => {
  const { Column } = Table;
  const [filters, setFilters] = useState<StatisticsOptions>({});

  const newDateFrom = new Date();
  newDateFrom.setDate(newDateFrom.getDate() - 7);

  const newDateTo = new Date();
  newDateTo.setDate(newDateTo.getDate() + 1);

  const [date1, setDate1] = useState(newDateFrom.toISOString().replace('T', ' ').substring(0, 10));
  const [date2, setDate2] = useState(newDateTo.toISOString().replace('T', ' ').substring(0, 10));

  const {
    isLoading,
    isFetching,
    isError,
    data: response,
    error,
    refetch,
  } = useQuery<PaginatedByDay, HttpError>(['byDayHits'], () => statisticsService.byDay(filters));

  useEffect(() => {
    refetch();
  }, [filters, refetch]);

  useEffect(() => {
    setFilters({ dateOne: date1, dateTwo: date2 });
  }, [date1, date2, refetch]);

  const handleFromDate = (values: any) => {
    setDate1(values);
  };
  const handleToDate = (values: any) => {
    setDate2(values);
  };

  if (isError) {
    return (
      <div>
        <pre>{JSON.stringify(error, undefined, 2)}</pre>
      </div>
    );
  }

  if (!response) {
    return (
      <div style={{ padding: '10px', textAlign: 'center' }}>
        <Spin size="large" />
      </div>
    );
  }

  function transform(stats: DateData[]) {
    return stats.map((statistic: any) => transformByDay(statistic));
  }

  const { data: statistics } = response;

  const { RangePicker } = DatePicker;
  const dateFormat = 'YYYY/MM/DD';

  // const handlePreuzetiTekstClick = (element: any) => {
  //   const currUser = element.authorName;
  //   const firstName = currUser.split(' ')[0];
  //   const lastName = currUser.slice(currUser.indexOf(' ') + 1);

  //   let isCountZero = false;
  // };

  return (
    <>
      <div style={{ padding: '10px', textAlign: 'center', width: '50%' }}>
        <div style={{ paddingBottom: '10px', textAlign: 'center', fontSize: '20px' }}>
          <p>Broj članaka po danima</p>
        </div>
        <RangePicker
          defaultValue={[moment(response.dateOne, dateFormat), moment(response.dateTwo, dateFormat)]}
          format={dateFormat}
          onChange={(values, e) => {
            handleFromDate(values?.[0]?.format('YYYY-MM-DD'));
            handleToDate(values?.[1]?.format('YYYY-MM-DD'));
          }}
          style={{ marginBottom: 20 }}
        />
        <Row gutter={[1, 2]}>
          <Table
            bordered
            sticky
            size="middle"
            loading={isLoading || isFetching}
            dataSource={transform(statistics)}
            pagination={false}
          >
            <Column key="date" dataIndex="date" title={'Datum'} filterSearch />
            <Column
              key="numberOfHits"
              dataIndex="numberOfHits"
              title={'Broj članaka'}
              filterSearch
              render={(text, record: any) => (
                <button
                  style={{
                    color: '#598ad9',
                    backgroundColor: 'transparent',
                    border: 'none',
                    cursor: 'pointer',
                    textAlign: 'left',
                  }}
                  //onClick={() => handlePreuzetiTekstClick(record)}
                >
                  {text}
                </button>
              )}
            />
          </Table>
        </Row>
        <div style={{ paddingTop: '10px', textAlign: 'center', fontSize: '20px' }}>
          <p>Total: {response.total || 0}</p>
        </div>
      </div>
    </>
  );
};
