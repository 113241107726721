import { SaveOutlined } from '@ant-design/icons';
import { Button, Drawer, Form, Spin, Input, message, Switch } from 'antd';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import { categoriesService } from 'config/services';

import { CategoryUpdate, Category } from 'types/services/categories';

interface CategoryDrawerProps {
  open: boolean;
  category?: CategoryUpdate;
  refetchCategories: () => {};
  onCancel: () => void;
}

export const CategoryDrawer = ({ open, category, refetchCategories, onCancel }: CategoryDrawerProps) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const { isLoading: isCreatingCategory, mutateAsync: createCategory } = useMutation(
    'createCategory',
    ({ title, color, hasAds }: { title: string; color: string; hasAds: boolean }) =>
      categoriesService.create(title, color, hasAds),
  );

  const { isLoading: isUpdatingCategory, mutateAsync: updateCategory } = useMutation(
    'updateCategory',
    (category: CategoryUpdate) => categoriesService.update(category),
  );

  useEffect(() => {
    if (open && category) {
      form.setFieldsValue({
        color: category.color,
        title: category.title,
        hasAds: category.hasAds,
      });
    }
  }, [open, category, form]);

  const onFormSubmit = async (fields: Pick<Category, 'color' | 'title' | 'hasAds'>) => {
    if (fields.hasAds === undefined) {
      fields.hasAds = true;
    }
    try {
      await (category ? updateCategory({ ...category, ...fields }) : createCategory(fields));
      message.success(
        t(`common:Successfully ${category ? 'Updated' : 'Created'}`, {
          resource: t('article:category'),
          genderSuffix: 'a',
        }),
      );
    } catch {
      message.error(t('common:Action failed', { action: t(`common:${category ? 'Update' : 'Creation'}`) }));
    }

    handleCancel();
    refetchCategories();
  };

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  return (
    <Drawer
      width="500"
      placement="right"
      onClose={handleCancel}
      visible={open}
      title={
        <div>
          <span>{t(`settings:${category ? 'Edit' : 'New'} category`)}</span>
        </div>
      }
      footer={[
        <div style={{ float: 'right' }}>
          <Button
            style={{ marginRight: '10px' }}
            key="back"
            onClick={handleCancel}
            disabled={isCreatingCategory || isUpdatingCategory}
          >
            {t('common:Cancel')}
          </Button>

          <Button
            key="submit"
            type="primary"
            icon={<SaveOutlined />}
            disabled={isCreatingCategory || isUpdatingCategory}
            onClick={form.submit}
          >
            {t('common:Save')}
          </Button>
        </div>,
      ]}
    >
      <div>
        {isCreatingCategory || isUpdatingCategory ? (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Spin size="large" />
          </div>
        ) : (
          open && (
            <Form
              form={form}
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 20 }}
              layout="horizontal"
              size="middle"
              onFinish={onFormSubmit}
            >
              <Form.Item name="color" label={t('common:Color')} rules={[{ required: true }]}>
                <Input type="color" className="category-color-input" />
              </Form.Item>
              <Form.Item name="title" label={t('common:Name')} rules={[{ required: true }]}>
                <Input />
              </Form.Item>
              <Form.Item name="hasAds" label={t('common:Ads')}>
                <Switch defaultChecked={category ? category.hasAds : true} style={{ float: 'right' }} />
              </Form.Item>
            </Form>
          )
        )}
      </div>
    </Drawer>
  );
};
