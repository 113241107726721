import jwtDecode from 'jwt-decode';

export type UserKeys = 'sub' | 'name' | 'email' | 'role';
export type TimeKeys = 'exp' | 'iat';

export type UserClaims = { [claim in UserKeys]: string };
export type TimeClaims = { [claim in TimeKeys]: number };
export type Claims = UserClaims & TimeClaims;

export const decode = (accessToken: string) => jwtDecode(accessToken) as Claims;
