import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { Form, Input, Button } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { login } from 'actions/index';

import { StoreState } from 'types/store';

import './index.scss';

export const Login = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const [warning, setWarning] = useState(false);

  const isLoading = useSelector<StoreState, boolean>(({ session }) => session.isLoading);

  const onFinish = (values: any) => {
    const { username, password } = values;
    if (!!dispatch(login({ username, password }))) {
      setWarning(true);
    } else {
      setWarning(false);
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="login-container">
      <div className="container">
        <div className="login-page">
          <Form name="normal_login" className="login-form" initialValues={{ remember: true }} onFinish={onFinish}>
            <p style={{ paddingBottom: '10px' }} className="title">
              {t('users:Avaz Login')}
            </p>
            <Form.Item
              name="username"
              rules={[{ required: true, message: t('users:Please input your Username!') }]}
              style={{ fontFamily: 'DM Sans' }}
            >
              <Input
                style={{ fontFamily: 'DM Sans' }}
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder={t('users:Username')}
              />
            </Form.Item>

            <Form.Item
              name="password"
              rules={[{ required: true, message: t('users:Please input your Password!') }]}
              style={{ fontFamily: 'DM Sans' }}
            >
              <Input
                prefix={<LockOutlined className="site-form-item-icon" />}
                type="password"
                style={{ fontFamily: 'DM Sans' }}
                placeholder={t('users:Password')}
              />
            </Form.Item>

            {warning && (
              <Form.Item name="warningMessage" style={{ fontFamily: 'DM Sans' }}>
                <label style={{ color: 'red' }}>{t('users:Wrong credentials')}</label>
              </Form.Item>
            )}

            <Form.Item>
              <Button
                onClick={() => {
                  history.push('/reset-password/init');
                }}
                style={{ margin: '0 auto', color: '#333', fontFamily: 'DM Sans' }}
                type="link"
              >
                {t('users:Forgot password')}
              </Button>
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" style={{ fontFamily: 'DM Sans' }} className="login-form-button">
                {t('users:Log in')}
              </Button>
            </Form.Item>
          </Form>
        </div>
        <div className="logo">
          <img alt="logo" src="/avaz-logo.png" />
        </div>
      </div>
    </div>
  );
};
