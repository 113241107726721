import { Row, Spin, Table } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import { statisticsService, usersService } from 'config/services';

import { HttpError } from 'helpers/http';

import { Articles } from 'routes/articles/Articles';

import { AuthorStatisticSubType, StatisticsOptions, StatisticSubType } from 'types/services/statistics';
import { PaginatedUsers } from 'types/services/users';

import { transformResourceStatistic } from './utils';

import './index.scss';

export const AuthorStatistics = () => {
  const { Column } = Table;
  const { t } = useTranslation();
  const { authorId } = useParams<{ authorId: string }>();
  const { dateOne } = useParams<{ dateOne: string }>();
  const { dateTwo } = useParams<{ dateTwo: string }>();
  const { count } = useParams<{ count: string }>();
  const [subTypeId, setSubTypeId] = useState();
  const [isBottomVisible, setIsBottomVisible] = useState(false);
  const [filters] = useState<StatisticsOptions>({
    authorId: [parseInt(authorId)],
    dateOne: dateOne,
    dateTwo: dateTwo,
  });
  const [authorName, setAuthorName] = useState('');

  const {
    isLoading,
    isFetching,
    isError,
    data: response,
    error,
  } = useQuery<AuthorStatisticSubType, HttpError>(['listSubTextType'], () =>
    statisticsService.listSubTextType(filters),
  );

  const { data: users } = useQuery<PaginatedUsers, HttpError>('listUsers', () =>
    usersService.list({ page: 1, perPage: 1000, filters: {} }),
  );

  useEffect(() => {
    users?.data.forEach((m) => {
      if (m.id === parseInt(authorId)) {
        setAuthorName(m.firstName + ' ' + m.lastName);
      }
    });
  }, [users, authorId]);

  const handleCellClick = (element: any) => {
    setIsBottomVisible(true);
    setSubTypeId(element.key);
  };

  if (!response || !users) {
    return (
      <div style={{ padding: '10px', textAlign: 'center' }}>
        <Spin size="large" />
      </div>
    );
  }

  if (isError) {
    return (
      <div>
        <pre>{JSON.stringify(error, undefined, 2)}</pre>
      </div>
    );
  }

  function transform(stats: StatisticSubType[]) {
    return stats.map((statistic) => transformResourceStatistic(statistic));
  }

  const { data: articleStatistics } = response;

  return (
    <>
      <span className="totalSpan">
        {t('article:Total number of author articles')}
        <span className="spanAuthor">{' ' + authorName + ': '}</span>
        <span className="spanCount">{count}</span>
      </span>

      <div>
        <Row gutter={[8, 16]}>
          <Table
            bordered
            sticky
            size="middle"
            loading={isLoading || isFetching}
            dataSource={transform(articleStatistics)}
            pagination={false}
          >
            <Column key="subType" dataIndex="subType" title={t('article:Author sub type')} />
            <Column
              key="count"
              dataIndex="count"
              title={t('article:Count')}
              render={(text, record: any) => (
                <button
                  style={{
                    color: '#598ad9',
                    backgroundColor: 'transparent',
                    border: 'none',
                    cursor: 'pointer',
                    textAlign: 'left',
                  }}
                  onClick={() => handleCellClick(record)}
                >
                  {text}
                </button>
              )}
            />
          </Table>
        </Row>
      </div>

      <Articles
        authorId={authorId}
        textType={'Autorski tekst'}
        dateFrom={dateOne}
        dateTo={dateTwo}
        status={['approved']}
        subTypeId={subTypeId}
        visible={isBottomVisible}
      ></Articles>
    </>
  );
};
