import { HttpClient } from 'helpers/http';

import {
  IHomeStatisticsService,
  HomeStats,
  HomeStatisticsOptions,
  TodaysCountStats,
  PendingArticlesCountStats,
  MostReadOptions,
  MostRead,
  AnalyticsCount,
} from 'types/services/homeStatistics';

export class HomeStatisticsService implements IHomeStatisticsService {
  constructor(private client: HttpClient) {}

  API = {
    pieStatistics: '/api/articles/get-count-by-category',
    groupedColumnStatstics: '/api/articles/get-count-by-article-type',
    countByPendingArticle: '/api/articles/get-count-by-status',
    todaysCount: '/api/articles/get-todays-count',
    mostRead: '/api/articles/get-most-read',
    todayVisits: '/api/statistics/get',
  };

  getMostRead = (options: MostReadOptions = {}) => {
    let url = `${this.API.mostRead}?`;
    const params: Record<string, any> = { ...options };

    url =
      url +
      Object.keys(params)
        .filter((key) => !!params[key])
        .map((key) => {
          const value = params[key];
          return Array.isArray(value)
            ? value.map((v) => `${key}=${encodeURIComponent(v)}`).join('&')
            : `${key}=${encodeURIComponent(value)}`;
        })
        .join('&');
    return this.client.get<MostRead>(url);
  };

  get = (options: HomeStatisticsOptions = {}) => {
    let url = `${this.API.pieStatistics}?`;
    const params: Record<string, any> = { ...options };

    url =
      url +
      Object.keys(params)
        .filter((key) => !!params[key])
        .map((key) => {
          const value = params[key];
          return Array.isArray(value)
            ? value.map((v) => `${key}=${encodeURIComponent(v)}`).join('&')
            : `${key}=${encodeURIComponent(value)}`;
        })
        .join('&');
    return this.client.get<HomeStats>(url);
  };

  getGroupedColumns = (options: HomeStatisticsOptions = {}) => {
    let url = `${this.API.groupedColumnStatstics}?`;
    const params: Record<string, any> = { ...options };

    url =
      url +
      Object.keys(params)
        .filter((key) => !!params[key])
        .map((key) => {
          const value = params[key];
          return Array.isArray(value)
            ? value.map((v) => `${key}=${encodeURIComponent(v)}`).join('&')
            : `${key}=${encodeURIComponent(value)}`;
        })
        .join('&');
    return this.client.get<HomeStats>(url);
  };

  getTodaysCount = () => {
    let url = `${this.API.todaysCount}?`;

    return this.client.get<TodaysCountStats>(url);
  };

  getCountByPendingArticle = () => {
    let url = `${this.API.countByPendingArticle}?`;

    return this.client.get<PendingArticlesCountStats>(url);
  };

  getTotalVisits = () => {
    let url = `${this.API.todayVisits}`;
    return this.client.get<AnalyticsCount>(url);
  };
}
