import { createAction } from 'helpers/redux';

import { ActionTypes } from 'literals/index';

import { Item, Submenu } from 'types/common';
import { LoginCredentials } from 'types/services/auth';
import { PermissionsResponse } from 'types/services/rbac';

export const login = createAction(ActionTypes.LOGIN_REQUEST, (credentials: LoginCredentials) => credentials);
export const logout = createAction(ActionTypes.LOGOUT, () => {});
export const validateToken = createAction(ActionTypes.VALIDATE_TOKEN_REQUEST, () => {});

export const registerEntries = createAction(ActionTypes.REGISTER_ENTRIES, (entries: (Item | Submenu)[]) => entries);
export const toggleSubmenu = createAction(ActionTypes.TOGGLE_SUBMENU, (submenuKey: string) => submenuKey);
export const closeAllSubmenus = createAction(ActionTypes.CLOSE_ALL_SUBMENUS, () => {});

export const loadPermissions = createAction(
  ActionTypes.LOAD_PERMISSIONS,
  (permissions: PermissionsResponse) => permissions,
);
