import { CloseOutlined, SaveOutlined, SearchOutlined, UserOutlined } from '@ant-design/icons';
import { Input, Row, Spin, Table, InputRef, Space, Button, Form, Drawer, message, Radio, RadioChangeEvent } from 'antd';
import { isNil, omitBy } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';

import { usersService } from 'config/services';

import { HttpError } from 'helpers/http';

import { PaginationParams, PatchParams } from 'types/common';
import { PaginatedUsersFront, UserFront } from 'types/services/users';

import './style.scss';

export const ListUsersFront = () => {
  const [pagination, setPagination] = useState<PaginationParams>({ page: 1, perPage: 10, filters: {} });
  const [isReloading, setIsReloading] = useState(false);
  const [filters, setFilters] = useState<Partial<UserFront>>({});
  const { t } = useTranslation();
  const [searchedColumn, setSearchedColumn] = useState('');
  const filterInputNode = useRef<InputRef>(null);
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [formEdit] = Form.useForm<UserFront>();
  const [value, setValue] = useState<any>();
  const [deleteUserComments, setDeleteUserComments] = useState(false);

  const { Column } = Table;
  const {
    isLoading,
    isError,
    data: response,
    error,
    refetch,
  } = useQuery<PaginatedUsersFront, HttpError>('listUsersFront', () => usersService.listUsersFront(pagination));

  const { isLoading: isEditing, mutateAsync } = useMutation<UserFront, HttpError, PatchParams<UserFront>>(
    'editUserFront',
    (params) => usersService.patchUserFront(params),
  );

  useEffect(() => {
    async function refetchQuery() {
      setIsReloading(true);
      await refetch();
      setIsReloading(() => false);
    }

    refetchQuery();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination]);

  function onChange(page: number) {
    setPagination((previousPagination) => ({ ...previousPagination, page }));
  }

  function onShowSizeChange(current: number, pageSize: number) {
    setPagination({ page: current, perPage: pageSize });
  }

  function transform(users: UserFront[]) {
    return users.map((user) => ({
      key: user.username,
      ban: user.ban === false ? 'Ne' : 'Da',
      username: user.username,
      id: user.id,
      uid: user.id,
      banType: user.ban,
    }));
  }

  if (isError) {
    return (
      <div>
        <pre>{JSON.stringify(error, undefined, 2)}</pre>
      </div>
    );
  }

  if (!response) {
    return (
      <div style={{ padding: '10px', textAlign: 'center' }}>
        <Spin size="large" />
      </div>
    );
  }

  const getColumnSearchProps = (dataIndex: keyof UserFront) => ({
    filterDropdown: () => (
      <div style={{ padding: 8 }}>
        <Input.Search
          value={String(filters[dataIndex])}
          placeholder={t('common:Search term')}
          onChange={({ target }) => setFilters({ ...filters, [dataIndex]: target.value })}
          onSearch={() => setPagination({ ...pagination, filters, page: 1 })}
          ref={(node) => {
            if (searchedColumn === dataIndex) {
              (filterInputNode as any).current = node;
            }
          }}
        />
      </div>
    ),
    filterIcon: (_: boolean) => {
      const isFiltered = !!filters[dataIndex];
      return <SearchOutlined style={{ color: isFiltered ? '#1890ff' : undefined }} />;
    },
    onFilterDropdownVisibleChange: (visible: boolean) => {
      if (visible) {
        setTimeout(() => {
          setSearchedColumn(dataIndex);
          filterInputNode.current?.select();
        }, 100);
      }
    },
  });

  function openDrawer(data: UserFront) {
    formEdit.setFieldsValue(data);

    setValue(data.banType);

    if (data.banType === true) {
      const banUser = document.getElementsByClassName('banUserClass1');
      for (var i = 0; i < banUser.length; i++) {
        banUser[i].classList.remove('hidden');
      }
    } else {
      setDeleteUserComments(false);
    }

    setIsDrawerVisible(true);
  }

  const { data: users } = response;
  const { total } = response.pagination;

  function closeDrawer() {
    formEdit.resetFields();
    setIsDrawerVisible(false);
  }

  const titleFilterDropdown = () => (
    <div style={{ padding: 8 }}>
      <Input.Search
        value={filters.username}
        placeholder={t('common:Search term')}
        onChange={({ target }) => setFilters((f) => ({ ...f, title: [target.value] }))}
        onSearch={() => setPagination({ ...pagination, filters, page: 1 })}
        ref={(node) => {
          (filterInputNode as any).current = node;
        }}
      />
    </div>
  );

  async function onFormSubmit() {
    const values = { ...formEdit.getFieldsValue() } as UserFront;
    await mutateAsync(values)
      .then((resp: any) => {
        if (resp.errors) {
          setIsDrawerVisible(true);
          message.error(t(`common:Invalid data`));
        } else {
          refetch();
          setIsDrawerVisible(false);
          message.success(t(`common:Successfully Updated`, { resource: t('common:User') }));
          formEdit.resetFields();
        }
      })
      .catch((e) => {});
  }

  const onChangeRadio = (e: any) => {
    if (e.target.value !== true) {
      formEdit.setFieldsValue({
        deleteUserComments: e.target.value,
      });
    }
    setDeleteUserComments(false);
    setValue(e.target.value);
  };

  const onChangeRadioDelete = (e: any) => {
    setDeleteUserComments(e.target.value);
  };

  const handleTableChange = (
    pagination: any,
    filters: {
      username?: string;
    },
    sorter: any,
  ) => {
    let filterValues = omitBy(
      {
        username: filters.username,
      },
      isNil,
    );

    //setPagination((p) => ({ ...p, page: 1 }));
    if (Object.keys(filterValues).length === 0) {
      filterValues.username = filters.username;
    }

    setFilters(filterValues);
  };

  return (
    <>
      <div style={{ padding: '10px', textAlign: 'center' }}>
        <Row gutter={[8, 16]}>
          <Table
            bordered
            sticky
            size="small"
            loading={isLoading || isReloading}
            dataSource={transform(users)}
            pagination={{
              onChange,
              onShowSizeChange,
              size: 'default',
              position: ['bottomCenter'],
              showSizeChanger: true,
              showLessItems: true,
              current: pagination.page,
              pageSize: pagination.perPage,
              total,
            }}
            onChange={handleTableChange}
          >
            <Column
              title={t('users:Username')}
              dataIndex="username"
              key="username"
              {...getColumnSearchProps('username')}
              filterDropdown={titleFilterDropdown}
              filteredValue={filters?.username}
            />
            <Column title={t('users:Banned')} dataIndex="ban" key="ban" />
            <Column
              title={t('common:Options')}
              align="center"
              fixed="right"
              width="180px"
              key="edit"
              render={(_, user: { key: React.Key } & UserFront) => (
                <Space>
                  <Button shape="round" icon={<CloseOutlined />} onClick={() => openDrawer(user)} />
                </Space>
              )}
            />
          </Table>
        </Row>
      </div>

      {/* ================ */}
      {/* EDIT USER BAN */}
      {/* ================ */}

      <Drawer
        width="500"
        placement="right"
        onClose={closeDrawer}
        visible={isDrawerVisible}
        title={
          <div>
            <UserOutlined style={{ marginRight: '5px' }} />
            {<span>{t('users:Edit user')}</span>}
          </div>
        }
        footer={[
          <div style={{ float: 'right' }}>
            <Button style={{ marginRight: '10px' }} key="back" disabled={isEditing} onClick={closeDrawer}>
              {t('common:Cancel')}
            </Button>

            <Button
              key="submit"
              type="primary"
              disabled={isEditing}
              loading={isEditing}
              icon={<SaveOutlined />}
              onClick={formEdit.submit}
            >
              {t('common:Save')}
            </Button>
          </div>,
        ]}
      >
        {/* ================== */}
        {/* EDIT USER FORM */}
        {/* ================== */}
        <div>
          <Form
            form={formEdit}
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
            layout="horizontal"
            size="middle"
            onFinish={onFormSubmit}
          >
            <Form.Item name="id" label="ID" hidden>
              <Input disabled readOnly />
            </Form.Item>
            <Form.Item name="uid" label="ID" hidden>
              <Input disabled readOnly />
            </Form.Item>
            <Form.Item
              name="banType"
              rules={[{ required: true, message: t('users:Ban type required') }]}
              label={t('users:Banned')}
              className={'banUserClass'}
            >
              <Radio.Group onChange={onChangeRadio} value={value} style={{ float: 'right' }}>
                <Radio value={false}>{t('users:No')}</Radio>
                <Radio value={true}>{t('users:Yes')}</Radio>
              </Radio.Group>
            </Form.Item>

            <Form.Item
              name="deleteUserComments"
              label={t('users:Delete user comments')}
              className={`banUserClass1
                  ${!value === true ? 'hidden' : ''}
              `}
            >
              <Radio.Group
                onChange={onChangeRadioDelete}
                value={deleteUserComments}
                style={{ float: 'right' }}
                defaultValue={false}
                disabled={!value}
              >
                <Radio value={false}>{t('users:No')}</Radio>
                <Radio value={true}>{t('users:Yes')}</Radio>
              </Radio.Group>
            </Form.Item>
          </Form>
        </div>
      </Drawer>
    </>
  );
};
