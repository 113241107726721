export class HttpError<T = any> extends Error {
  canceled: boolean;
  message: string;
  network: boolean;
  payload?: T;
  status?: number;
  timeout: boolean;

  constructor(error: Partial<HttpError> = {}) {
    const { canceled = false, message = '', network = true, payload, status, timeout = false } = error;

    super(message);

    this.name = 'HttpError';
    this.canceled = canceled;
    this.message = message;
    this.network = network;
    this.payload = payload;
    this.status = status;
    this.timeout = timeout;
  }
}
