import { registerEntries } from 'actions/index';

import { routes as articlesRoutes, submenu as articlesSubmenu } from './articles/routes';
import { routes as crosswordsRoutes, submenu as crosswordsSubmenu } from './crossword/routes';
import { route as homeRoute, item as homeItem } from './home/route';
import { routes as indexRoutes, submenu as indexSubmenu } from './index/routes';
import { route as loginRoute } from './login/route';
import { routes as reportsRoutes, submenu as reportsSubmenu } from './reports/routes';
import { routes as forgotPasswordRoutes } from './resetPassword/routes';
import { routes as settingsRoutes, submenu as settingsSubmenu } from './settings/routes';
import { routes as usersRoutes, submenu as usersSubmenu } from './users/routes';

export const routes = [
  homeRoute,
  loginRoute,
  ...usersRoutes,
  ...forgotPasswordRoutes,
  ...articlesRoutes,
  ...settingsRoutes,
  ...reportsRoutes,
  ...indexRoutes,
  ...crosswordsRoutes,
];

// REGISTER ROUTES IN SIDEBAR
setTimeout(() => {
  window.store.dispatch(
    registerEntries([
      homeItem,
      usersSubmenu,
      articlesSubmenu,
      settingsSubmenu,
      reportsSubmenu,
      indexSubmenu,
      crosswordsSubmenu,
    ]),
  );
});
