import { HttpClient } from 'helpers/http';

import { PaginationParams } from 'types/common';
import {
  IActivityLogService,
  ActivityLogOptions,
  PaginatedActivityLog,
  ActivityLog,
} from 'types/services/activity-log';

export class ActivityLogService implements IActivityLogService {
  constructor(private client: HttpClient) {}

  API = {
    activityLog: '/api/activity-log',
  };

  list = ({ page, perPage }: PaginationParams, options: ActivityLogOptions = {}) => {
    let url = `${this.API.activityLog}?`;
    const params: Record<string, any> = { page, perPage, ...options };

    url =
      url +
      Object.keys(params)
        .filter((key) => !!params[key])
        .map((key) => {
          const value = params[key];
          return Array.isArray(value)
            ? value.map((v) => `${key}=${encodeURIComponent(v)}`).join('&')
            : `${key}=${encodeURIComponent(value)}`;
        })
        .join('&');

    return this.client.get<PaginatedActivityLog>(url);
  };

  get = (id: number) => {
    const url = `${this.API.activityLog}/${id}`;
    return this.client.get<ActivityLog>(url);
  };
}
