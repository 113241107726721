import { DateTime } from 'luxon';

import { Article } from 'types/services/articles';

import './RelatedArticleCard.scss';

const RelatedArticleCard = ({ article }: { article: Article }) => {
  // const durationObj = DateTime.now()
  //   .diff(DateTime.fromISO(article.createdAt), ['years', 'months', 'days', 'hours', 'minutes'])
  //   .toObject();

  let date = DateTime.fromISO(article.publicationTime as unknown as string).toLocaleString(DateTime.DATE_SHORT);

  // const timeAgo = `${durationObj.years ? `${durationObj.years} Y ` : ''}${
  //   durationObj.months ? `${durationObj.months} M ` : ''
  // }${durationObj.days ? `${durationObj.days} D ` : ''}
  // ${durationObj.hours ? `${durationObj.hours} H ` : ''}${
  //   durationObj.minutes ? `${Math.round(durationObj.minutes)} MIN` : ''
  // }`;
  return (
    <div className="flex relative article-card">
      <img src={article.thumbnailImage?.thumbnailUrl} alt="Article thumbnail" className="article-card-image" />
      <div className="article-card-info">
        <div className="flex justify-between article-card-header">
          <span className="article-card-stamp">{article.stamp}</span>
          <span className="article-card-time-ago">{date}</span>
        </div>
        <div className="article-card-title">{article.title}</div>
      </div>
    </div>
  );
};

export default RelatedArticleCard;
