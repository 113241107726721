import { HttpClient } from 'helpers/http';

export class NotificationsService {
  constructor(private client: HttpClient) {}
  API = {
    notificationsAPI: '/api/notifications',
  };
  fetchNotifications = (pass?: any) => {
    let url = this.API.notificationsAPI;
    url = pass ? url + '?update=true' : url;
    return this.client.get(url);
  };
  clearNotifications = () => {
    const url = `${this.API.notificationsAPI}/delete`;
    return this.client.get(url);
  };
}
