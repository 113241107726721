import { Column } from '@ant-design/plots';
import { Empty } from 'antd';

export const GroupedColumn = (textTypes) => {
  let allTextTypes = [
    { texttype: 'Preuzeti tekst', cnttoday: 0, cntyesterday: 0 },
    { texttype: 'Tekst drugog novinara', cnttoday: 0, cntyesterday: 0 },
    { texttype: 'Agencijski tekst', cnttoday: 0, cntyesterday: 0 },
    { texttype: 'Autorski tekst', cnttoday: 0, cntyesterday: 0 },
    { texttype: 'Prevedeni tekst', cnttoday: 0, cntyesterday: 0 },
    { texttype: 'Promo tekst', cnttoday: 0, cntyesterday: 0 },
  ];

  allTextTypes.forEach((element) => (element.texttype = element.texttype.split(' ')[0]));

  const data = [];
  let dataDiff = [];
  if (textTypes.props !== undefined) {
    for (let i = 0; i < allTextTypes.length; i++) {
      for (let j = 0; j < textTypes.props.data.length; j++) {
        if (textTypes.props.data[j].texttype.split(' ')[0] === allTextTypes[i].texttype) {
          allTextTypes[i].cnttoday = textTypes.props.data[j].cnttoday;
          allTextTypes[i].cntyesterday = textTypes.props.data[j].cntyesterday;
        }
      }
    }

    for (let i = 0; i < allTextTypes.length; i++) {
      data.push({
        name: 'Jučer',
        textType: allTextTypes[i].texttype,
        value: Number(allTextTypes[i].cntyesterday),
      });
      data.push({
        name: 'Danas',
        textType: allTextTypes[i].texttype,
        value: Number(allTextTypes[i].cnttoday),
      });

      dataDiff.push({
        diff: allTextTypes[i].cnttoday - allTextTypes[i].cntyesterday,
      });
    }
  }
  // tekst drugog novinara predstavljeno kao 'Asistencija'
  if (data !== undefined && data[2] !== undefined && data[3] !== undefined) {
    data[2].textType = 'Asistencija';
    data[3].textType = 'Asistencija';
  }

  const config = {
    data,
    isGroup: true,
    xField: 'textType',
    yField: 'value',
    seriesField: 'name',
    legend: {
      layout: 'horizontal',
      position: 'top',
    },
    color: [
      'l(270) 0:rgba(139, 139, 139, 0.2) 5.56% 1:#8B8B8B 100%',
      'l(270) 0:rgba(18, 206, 120, 0.2) 5.56% 1:#12CE78 100%',
    ],
    yAxis: {
      label: null,
      grid: {
        line: {
          style: {
            stroke: 'black',
            lineWidth: 0,
          },
        },
      },
    },
    maxColumnWidth: 20,
    padding: 55,

    label: {
      position: 'middle',
      layout: [
        {
          type: 'interval-adjust-position',
        },
        {
          type: 'interval-hide-overlap',
        },
        {
          type: 'adjust-color',
        },
      ],
    },
    interactions: [
      {
        type: 'legend-filter',
        enable: false,
      },
    ],
  };
  return <>{data && data.length > 0 ? <Column {...config} /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}</>;
};
