import { HttpClient } from 'helpers/http';

import { ArticleSubType, ArticleSubTypeOptions } from 'types/services/articleSubType';

export class ArticleSubTypeService {
  constructor(private client: HttpClient) {}

  API = {
    articles: '/api/articleSubType',
  };

  list = (options: ArticleSubTypeOptions = {}) => {
    let url = `${this.API.articles}/get-sub-type?`;
    const params: Record<string, any> = { ...options };

    url =
      url +
      Object.keys(params)
        .map((key) => {
          const value = params[key];

          return Array.isArray(value)
            ? value.map((v) => `${key}=${encodeURIComponent(v)}`).join('&')
            : `${key}=${encodeURIComponent(value)}`;
        })
        .join('&');
    return this.client.get<ArticleSubType>(url);
  };
}
