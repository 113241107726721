import { User, IUsersService } from 'types/services/users';

export class UsersMockService implements IUsersService {
  constructor(private users: User[]) {}

  list = () =>
    Promise.resolve({
      data: this.users,
      pagination: { page: 1, perPage: 10, total: 10 },
    });
}
