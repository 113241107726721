import { UploadFile } from 'types/files';
import { CoverImage } from 'types/services/coverImages';
import { Image } from 'types/services/images';

export const mapImageToUploadFile = (image: Image): UploadFile =>
  ({
    name: 'image',
    status: 'done',
    uid: image.id.toString(),
    url: image.imageUrl,
    description: image.description,
    source: image.source,
    caption: image.caption,
    response: image,
    createdAt: image.createdAt,
  } as UploadFile);

export const mapCoverImageToUploadFile = (coverImage: CoverImage): UploadFile =>
  ({
    name: 'image',
    status: 'done',
    uid: coverImage.id.toString(),
    url: coverImage.imageUrl,
    response: coverImage,
    createdAt: coverImage.createdAt,
  } as UploadFile);
