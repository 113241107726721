import { SaveOutlined, LockOutlined } from '@ant-design/icons';
import { Button, Table, Drawer, Form, Checkbox, Spin } from 'antd';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import { rbacService } from 'config/services';

import { capitalizeFirstLetter } from 'helpers/shared';

import { PermissionsResponse } from 'types/services/rbac';

const { Column } = Table;

interface EditPermissionsModalProps {
  open: boolean;
  role: string;
  refetchRoles: () => {};
  onCancel: () => void;
}

interface PermissionItem {
  key: string;
  action: string;
  checked: boolean;
}

interface PermissionRow {
  key: string;
  resource: string;
  permissions: PermissionItem[];
}

export const EditPermissionsDrawer = (props: EditPermissionsModalProps) => {
  const { t } = useTranslation();
  const [permissionsRows, setPermissionsRows] = useState<PermissionRow[]>([]);
  const [initialValues, setInitialValues] = useState({});
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const { open, role, refetchRoles, onCancel } = props;

  const { isLoading: isSubmitting, mutateAsync } = useMutation('editRole', (params: PermissionsResponse) =>
    rbacService.updatePermissions(role, params),
  );

  useEffect(() => {
    const fetchResource = async () => {
      setLoading(true);
      const { resources } = await rbacService.getResources();
      const { permissions } = await rbacService.getPermissions(role);

      const map: { [key: string]: string[] } = {};
      const initialValues: { [keys: string]: boolean } = {};
      const rows: PermissionRow[] = [];

      resources.forEach(({ resource }) => {
        map[resource] = [];
      });

      permissions.forEach(([resource, action]) => {
        map[resource].push(action);
      });

      resources.forEach(({ resource, actions }) => {
        rows.push({
          key: resource,
          resource,
          permissions: actions.map((action) => {
            const fieldKey = `${resource}:${action}`;
            const value = map[resource].includes(action);
            initialValues[fieldKey] = value;
            return {
              key: fieldKey,
              action,
              checked: value,
            };
          }),
        });
      });

      setInitialValues(initialValues);
      setPermissionsRows(rows);
      setLoading(false);
    };

    if (open && role) {
      form.resetFields();
      fetchResource();
    }
  }, [open, role, form]);

  const onFormSubmit = async (permMap: { [key: string]: true | undefined }) => {
    const payload = [];
    for (const key in permMap) {
      const checked = permMap[key];
      if (checked) {
        payload.push(key.split(':'));
      }
    }
    await mutateAsync({ permissions: payload });
    handleCancel();
    refetchRoles();
  };

  const handleCancel = () => {
    setPermissionsRows([]);
    setInitialValues({});
    form.resetFields();
    onCancel();
  };

  return (
    <Drawer
      width="500"
      placement="right"
      onClose={handleCancel}
      visible={open}
      title={
        <div>
          <LockOutlined style={{ marginRight: '5px' }} />
          <span>
            {t('users:Edit permissions')}: {capitalizeFirstLetter(role)}
          </span>
        </div>
      }
      footer={[
        <div style={{ float: 'right' }}>
          <Button style={{ marginRight: '10px' }} key="back" onClick={handleCancel} disabled={isSubmitting}>
            {t('common:Cancel')}
          </Button>

          <Button key="submit" type="primary" icon={<SaveOutlined />} disabled={isSubmitting} onClick={form.submit}>
            {t('common:Save')}
          </Button>
        </div>,
      ]}
    >
      <div>
        {loading ? (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Spin size="large" />
          </div>
        ) : (
          <Form
            form={form}
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 20 }}
            layout="horizontal"
            size="middle"
            initialValues={initialValues}
            onFinish={onFormSubmit}
          >
            <Table
              loading={loading}
              size="middle"
              dataSource={permissionsRows}
              pagination={{ hideOnSinglePage: true, defaultPageSize: 200 }}
            >
              <Column
                title={t('users:Resource')}
                dataIndex="resource"
                key="resource"
                render={(resource: string) => capitalizeFirstLetter(resource)}
              />
              <Column
                title={t('users:Permissions')}
                align="center"
                key="permissions"
                render={(_, { permissions }: PermissionRow) =>
                  permissions.map((perm: PermissionItem) => (
                    <Form.Item key={perm.key} name={perm.key} noStyle valuePropName="checked">
                      <Checkbox style={{ display: 'flex', marginLeft: 0 }}>{perm.action}</Checkbox>
                    </Form.Item>
                  ))
                }
              />
            </Table>
          </Form>
        )}
      </div>
    </Drawer>
  );
};
