import { AxiosRequestConfig } from 'axios';
import { objectToParamString } from 'utility';

import { HttpClient } from 'helpers/http';

import { PaginationParams } from 'types/common';
import { IImagesService, Image, ImageUpdate, PaginatedImages } from 'types/services/images';

export class ImagesService implements IImagesService {
  constructor(private client: HttpClient) {}

  API = {
    root: '/api/images',
  };

  list = ({ page, perPage, filters }: PaginationParams) => {
    let url = objectToParamString(filters, `${this.API.root}?page=${page}&perPage=${perPage}`);
    return this.client.get<PaginatedImages>(url);
  };

  upload = async (options: any) => {
    const { onProgress, file } = options;

    const url = this.API.root;
    const imageData = [{ description: file.description || '', source: file.source || '' }];

    const formData = new FormData();
    formData.append('images', file);
    formData.append('imagesData', JSON.stringify(imageData));

    const config: AxiosRequestConfig = {
      headers: { 'content-type': 'multipart/form-data' },
      onUploadProgress: onProgress,
    };

    return this.client.post<{ images: Image[] }>(url, formData, config);
  };

  updateSquareThumbnail = (id: number, file: File) => {
    const url = `${this.API.root}/squareThumbnail`;
    const formData = new FormData();
    formData.append('image', file);
    formData.append('imageId', id.toString());

    const config: AxiosRequestConfig = {
      headers: { 'content-type': 'multipart/form-data' },
      onUploadProgress: () => {},
    };
    return this.client.post<{ image: Image }>(url, formData, config);
  };

  update = (image: ImageUpdate) => this.client.put<void>(`${this.API.root}/${image.id}`, { image });
}
