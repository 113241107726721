import { Customer, ICustomersService } from 'types/services/customers';

export class CustomersMockService implements ICustomersService {
  constructor(private customers: Customer[]) {}

  list = () =>
    Promise.resolve({
      data: this.customers,
      pagination: { page: 1, perPage: 10, total: 10 },
    });
}
