import {
  EditTwoTone,
  DeleteTwoTone,
  CommentOutlined,
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  EyeOutlined,
  UploadOutlined,
  GlobalOutlined,
} from '@ant-design/icons';
import { Button, Table, Spin, Row, Space, Tooltip, Modal, Input, message, Tag, InputNumber } from 'antd';
import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';
import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useMutation } from 'react-query';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { articlesService, categoriesService, usersService } from 'config/services';

import { HttpError } from 'helpers/http';

import { PaginationParams } from 'types/common';
import {
  Article,
  ArticleOptions,
  articleStatuses,
  ArticleStatusPayload,
  articleTextTypes,
  PaginatedArticles,
} from 'types/services/articles';
import { PermissionsMap } from 'types/services/auth';
import { Category, CategoryResponse } from 'types/services/categories';
import { PaginatedUsers } from 'types/services/users';
import { SessionState, StoreState } from 'types/store';

import ArticleRejectionModal from './components/ArticleRejectionModal';
import { routes } from './routes';
import { transformResource, textTypes } from './utils';

import './index.scss';

const { Column } = Table;

export const Articles = (props?: any) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [pagination, setPagination] = useState<PaginationParams>(
    props.perPage ? { page: 1, perPage: props.perPage } : { page: 1, perPage: 10 },
  );

  const [showArticleRejectionModal, setShowArticleRejectionModal] = useState(false);
  const selectedArticleId = useRef<number | undefined>();
  const filterInputNode = useRef<typeof Input>(null);

  const permissionMap = useSelector<StoreState, PermissionsMap>(({ session }) => session.permissions);
  const permissions = permissionMap['/articles'];

  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [deleteArticleId, setDeleteArticleId] = useState(0);
  const [isDeletingArticle, setIsDeletingArticle] = useState(false);
  const [isBottomVisible, setIsBottomVisible] = useState(true);
  const [pushModal, setPushModal] = useState(false);
  const [pushed, setPushed] = useState(false);
  const [pushedTitle, setPushedTitle] = useState('');

  const [pushArticle, setPushArticle] = useState(0);
  const { user } = useSelector<StoreState, SessionState>(({ session }) => session);
  const [isDisabled, setIsDisabled] = useState(false);
  const {
    isError: isErrorUsers,
    data: paginatedUsers,
    error: usersError,
  } = useQuery<PaginatedUsers, HttpError>('listUsers', () =>
    usersService.list({ page: 1, perPage: 1000, filters: {} }),
  );
  const users = paginatedUsers?.data;

  const newDateFrom = new Date();
  newDateFrom.setDate(newDateFrom.getDate() - 3);

  const newDateTo = new Date();
  newDateTo.setDate(newDateTo.getDate() + 1);

  const [date1, setDate1] = useState(newDateFrom.toISOString().replace('T', ' ').substring(0, 10));
  const [date2, setDate2] = useState(newDateTo.toISOString().replace('T', ' ').substring(0, 10));
  const [allIds, setAllIds] = useState<number[]>([]);

  const [alfaTVIDS, setAlfaTVIDS] = useState<number[]>([]);

  const [filters, setFilters] = useState<ArticleOptions>(
    props.authorId !== -1 && props.authorId !== undefined
      ? { authorId: props.authorId, textType: props.textType }
      : user?.role === 'JOURNALIST'
      ? { email: user?.email }
      : user?.role === 'COMMERCIALIST'
      ? { textType: ['Promo tekst', 'Native tekst'], email: user?.email }
      : user?.role === 'ALFATV'
      ? { categoryIds: alfaTVIDS, email: user?.email }
      : props?.location?.search === '?status=pending'
      ? { status: ['pending'] }
      : {},
  );

  useEffect(() => {
    if (user?.role === 'COMMERCIALIST') {
      setFilters({ textType: ['Promo tekst', 'Native tekst'], email: user?.email });
    }
    if (user?.role === 'ALFATV') {
      setFilters({ categoryIds: alfaTVIDS, email: user?.email });
    }
  }, [pagination]);

  const {
    isLoading,
    isFetching,
    isError,
    data: response,
    error,
    refetch,
  } = useQuery<PaginatedArticles, HttpError>(['listArticles'], () => articlesService.list(pagination, filters));
  //property perPage = 1000 due to retriving all users

  const { data: categoriesData } = useQuery<CategoryResponse, HttpError>('listCategories', () =>
    categoriesService.list(),
  );

  useEffect(() => {
    if (user?.role === 'ALFATV') {
      const alfaTVCategory = categoriesData?.data.filter((category) => category.slug === 'prijateljski-portali');
      if (alfaTVCategory) {
        alfaTVCategory[0].children.forEach((childCategory) => {
          if (childCategory.title === 'Alfa TV') alfaTVIDS.push(childCategory.id);
        });
      }
    }
  }, [categoriesData]);

  const { mutateAsync: updateArticleStatus } = useMutation(
    'setArticleStatus',
    ({ id, status }: { id: number; status: ArticleStatusPayload }) => articlesService.setStatus(id, status),
  );

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      refetch();
    }, 1000);
    return () => clearTimeout(timeOutId);
  }, [pagination, filters, refetch]);

  useEffect(() => {
    const x: number[] = [];
    response?.data.forEach((r) => x.push(r.id));
    setAllIds(x);
  }, [response?.data]);

  const {
    isError: multipleIsError,
    data: multipleData,
    error: multipleError,
    isLoading: multipleIsLoading,
    refetch: multipleRefetch,
  } = useQuery<any, HttpError>('multiple', () => articlesService.multiple(allIds), {
    enabled: allIds.length > 0,
  });

  useEffect(() => {
    if (allIds.length > 0) multipleRefetch();
  }, [allIds]);

  useEffect(() => {
    if (props.authorId !== -1 && props.authorId !== undefined) {
      setFilters({
        ...filters,
        authorId: [props.authorId],
        textType: [props.textType],
        dateFrom: props.dateFrom as Date,
        dateTo: props.dateTo as Date,
        status: props.status,
      });
      if (props.subTypeId !== undefined) {
        setFilters({ ...filters, subTypeId: props.subTypeId });
      }
      setPagination(props.perPage ? { page: 1, perPage: props.perPage } : { page: 1, perPage: 10 });
    }
    if (props.visible !== undefined) {
      setIsBottomVisible(props.visible);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  function onPaginationChange(page: number) {
    setPagination((previousPagination) => ({ ...previousPagination, page }));
  }

  function onShowSizeChange(current: number, pageSize: number) {
    setPagination({ ...pagination, page: current, perPage: pageSize });
  }

  function transform(arts: Article[], stats: any) {
    return arts.map((article) => transformResource(article, stats));
  }

  const showDeleteArticleError = () => {
    message.error(t('article:Delete article error description'));
  };

  const showDeleteArticleSuccess = () => {
    message.success(` ${t('article:Delete article success description')}`);
  };

  const showPushArticleSuccess = () => {
    message.success(` ${t('article:Push successful')}`);
  };

  const showPushArticleError = () => {
    message.error(t('article:Push unsuccesful'));
  };

  const handleArticleDelete = async () => {
    try {
      setIsDeletingArticle(true);
      await articlesService.delete(deleteArticleId, user?.email);
      await refetch();
      showDeleteArticleSuccess();
    } catch (e) {
      showDeleteArticleError();
    } finally {
      setIsDeletingArticle(false);
      setDeleteModalVisible(false);
    }
  };

  const handleArticlePush = async () => {
    let willBePushed = true;
    const x = Math.floor(Math.random() * (9999 - 1000)) + 1000;
    if (pushed) {
      let person = prompt('Unesite ' + x + ' ukoliko želite ponovno poslati notifikaciju');
      if (person !== String(x)) willBePushed = false;
    }
    if (willBePushed) {
      setPushModal(false);
      try {
        setIsDisabled(true);
        setTimeout(() => {
          setIsDisabled(false);
        }, 7000);
        const response = (await articlesService.push(pushArticle)) as any;
        if (response === 'success') {
          await refetch();
          showPushArticleSuccess();
        } else {
          showPushArticleError();
        }
      } catch (e) {
        showPushArticleError();
      } finally {
        setPushModal(false);
      }
    } else {
      showPushArticleError();
    }
  };

  const handleArticleDeleteCancel = () => {
    setDeleteModalVisible(false);
  };

  const handleArticlePushCancel = () => {
    setPushModal(false);
  };

  const openDeleteModal = (id: number) => {
    setDeleteArticleId(id);
    setDeleteModalVisible(true);
  };

  const openPushModal = (id: number, pushedArticle: boolean, title: string) => {
    setPushArticle(id);
    setPushed(pushedArticle);
    setPushedTitle(title);
    setPushModal(true);
  };

  const handleArticleApprove = async (id: number) => {
    await updateArticleStatus({ id, status: { status: 'approved' } });
    refetch();
    message.success(` ${t('article:Status change success')}`);
  };

  const handleArticleReject = (id: number) => {
    setShowArticleRejectionModal(true);
    selectedArticleId.current = id;
  };

  const handlePreviewClick = async (id: number) => {
    let hash = Buffer.from(String(id)).toString('base64');
    window.open(process.env.SHAZAM_APP_API_URL + '/report/article/' + hash, '_blank', 'noopener,noreferrer');
  };

  const handleArticleRejection = async (comment: string) => {
    if (selectedArticleId.current) {
      const response = await updateArticleStatus({
        id: selectedArticleId.current,
        status: { status: 'rejected', comment },
      });

      if (response.message === 'Article in block') {
        message.error(`Članak se nalazi u bloku!`);
      } else {
        message.success(` ${t('article:Status change success')}`);
      }
      setShowArticleRejectionModal(false);
      refetch();
    }
  };

  const closeArticleRejectionModal = () => {
    setShowArticleRejectionModal(false);
  };

  const translateStatus = (status: string) => {
    let color = 'green';
    if (status === 'rejected') {
      color = 'red';
    } else if (status === 'pending') {
      color = 'orange';
    }

    return <Tag color={color}>{t(`article:Status ${status}`)}</Tag>;
  };

  function navigateToArticle(id: number) {
    const articleRoute = (routes.find((r) => r.key === 'article')?.path as string | undefined)?.replace(
      ':id',
      id.toString(),
    );
    articleRoute && history.push(articleRoute);
  }

  function handleArticlePreview(article: any) {
    window.open(process.env.SHAZAM_APP_API_URL + article.external_link, '_blank', 'noopener,noreferrer');
  }

  function navigateToComments(id: number) {
    const commentsRoute = (routes.find((r) => r.key === 'article-comments')?.path as string | undefined)?.replace(
      ':id',
      id.toString(),
    );
    commentsRoute && history.push(commentsRoute);
  }

  const handleTableChange = (
    pagination: any,
    filters: {
      status?: string[];
      category?: number[];
      author?: null | number[];
      textType?: string[];
      title?: string[];
    },
    sorter: any,
  ) => {
    let filterValues = omitBy(
      {
        status: filters.status,
        categoryIds: filters.category,
        authorId: filters.author,
        textType: filters.textType,
        title: filters.title,
      },
      isNil,
    );

    //setPagination((p) => ({ ...p, page: 1 }));
    if (Object.keys(filterValues).length === 0) {
      if (props.authorId !== -1 && props.authorId !== undefined) {
        filterValues.authorId = props.authorId;
        filterValues.textType = props.textType;
        filterValues.dateFrom = props.dateFrom;
        filterValues.dateTo = props.dateTo;
        filterValues.status = props.status;
        filterValues.title = props.title;
      }
    }

    if (props.dateFrom && props.dateTo) {
      filterValues.dateFrom = props.dateFrom;
      filterValues.dateTo = props.dateTo;
    }

    if (user?.role === 'JOURNALIST') filterValues.email = [user.email];
    setFilters(filterValues);
  };

  if (isError || isErrorUsers) {
    return (
      <div>
        <pre>{JSON.stringify(error || usersError, undefined, 2)}</pre>
      </div>
    );
  }

  if (!response || !paginatedUsers || !categoriesData || !textTypes) {
    return (
      <div style={{ padding: '10px', textAlign: 'center' }}>
        <Spin size="large" />
      </div>
    );
  }

  const categories = categoriesData.data;

  const textTypeFilterOptions = articleTextTypes.map((t) => ({ text: t.valueOf(), value: t.valueOf() }));

  const userFilterOptions = users?.map((user) => ({ text: `${user.firstName} ${user.lastName}`, value: user.id }));
  const mapCategory = (category: Category): any => ({
    text: category.title,
    value: category.id,
    children: category.children?.map(mapCategory),
  });
  const categoriesFilterOptions = categories.map(mapCategory);
  const statusFilterOptions = articleStatuses.map((status) => ({ text: translateStatus(status), value: status }));

  const titleFilterDropdown = () => (
    <div style={{ padding: 8 }}>
      <Input.Search
        value={filters.title}
        placeholder={t('common:Search term')}
        onChange={({ target }) => {
          setFilters((f) => ({ ...f, title: [target.value] }));
        }}
        onSearch={() => setPagination({ ...pagination, filters, page: 1 })}
        ref={(node) => {
          (filterInputNode as any).current = node;
        }}
      />
    </div>
  );

  const idFilterDropdown = () => (
    <div style={{ padding: 8 }}>
      <InputNumber
        value={filters.id}
        placeholder={t('common:Search term')}
        onChange={(target) => {
          if (target) {
            setFilters((f) => ({ ...f, id: target }));
          } else {
            setFilters(() => ({}));
          }
        }}
        ref={(node) => {
          (filterInputNode as any).current = node;
        }}
      />
    </div>
  );

  const handleUserClick = (event: any) => {
    const currUser = event.target.innerText;
    const firstName = currUser.split(' ')[0];
    const lastName = currUser.slice(currUser.indexOf(' ') + 1);

    users?.forEach((m) => {
      if (m.firstName === firstName && m.lastName === lastName) {
        setFilters({ authorId: [m.id] });
      }
    });
  };

  const { data: articles } = response;
  const { total } = response.pagination;

  return (
    <>
      <div style={{ padding: '10px', textAlign: 'center' }}>
        {isBottomVisible && (
          <Row gutter={[8, 16]}>
            <Table
              bordered
              sticky
              size="middle"
              loading={isLoading || isFetching}
              dataSource={transform(articles, multipleData)}
              pagination={{
                onChange: onPaginationChange,
                onShowSizeChange,
                size: 'default',
                position: ['bottomCenter'],
                showSizeChanger: true,
                showLessItems: true,
                current: pagination.page,
                pageSize: pagination.perPage,
                total,
              }}
              onChange={handleTableChange}
            >
              <Column
                key="id"
                dataIndex="id"
                title={t('article:Id')}
                filterDropdown={idFilterDropdown}
                width={75}
                render={(text, art: any) => (
                  <a style={{ color: '#598ad9' }} href={'/article/' + art.id}>
                    {text}
                  </a>
                )}
              />
              <Column
                key="title"
                dataIndex="title"
                title={t('article:title')}
                filterDropdown={titleFilterDropdown}
                //#0000EE}
                render={(text, art: any) => (
                  <a style={{ color: '#598ad9' }} href={'/article/' + art.id}>
                    {text}
                  </a>
                )}
                filteredValue={filters?.title}
              />
              <Column
                key="openRate"
                dataIndex="openRate"
                title={'Otvaranja sa naslovnice u zadnjih 30 min.'}
                width={120}
              />
              {user?.role === 'JOURNALIST' || user?.role === 'COMMERCIALIST' || user?.role === 'ALFATV' ? (
                <Column
                  key="author"
                  dataIndex="author"
                  title={t('article:author')}
                  filteredValue={filters.authorId}
                  width={150}
                  render={(text, art: any) => (
                    <button
                      style={{
                        color: '#598ad9',
                        backgroundColor: 'transparent',
                        border: 'none',
                        cursor: 'pointer',
                        textAlign: 'left',
                      }}
                      onClick={handleUserClick}
                    >
                      {text}
                    </button>
                  )}
                />
              ) : (
                <Column
                  key="author"
                  dataIndex="author"
                  title={t('article:author')}
                  filters={userFilterOptions}
                  filterSearch
                  filteredValue={filters.authorId}
                  width={150}
                  render={(text, art: any) => (
                    <button
                      style={{
                        color: '#598ad9',
                        backgroundColor: 'transparent',
                        border: 'none',
                        cursor: 'pointer',
                        textAlign: 'left',
                      }}
                      onClick={handleUserClick}
                    >
                      {text}
                    </button>
                  )}
                />
              )}
              {user?.role === 'ALFATV' ? (
                <Column
                  key="category"
                  dataIndex="category"
                  title={t('article:category')}
                  filteredValue={filters.categoryIds}
                  width={200}
                />
              ) : (
                <Column
                  key="category"
                  dataIndex="category"
                  title={t('article:category')}
                  filters={categoriesFilterOptions}
                  filterMode="tree"
                  filteredValue={filters.categoryIds}
                  width={200}
                />
              )}
              <Column key="numberOfHits" dataIndex="numberOfHits" width={80} title={t('article:hits')} align={'left'} />
              {user?.role === 'COMMERCIALIST' ? (
                <Column
                  key="textType"
                  dataIndex="textType"
                  title={t('article:textType')}
                  filteredValue={filters.textType}
                  width={100}
                />
              ) : (
                <Column
                  key="textType"
                  dataIndex="textType"
                  title={t('article:textType')}
                  filters={textTypeFilterOptions}
                  filteredValue={filters.textType}
                  width={100}
                />
              )}
              <Column
                key="createdAt"
                dataIndex="createdAt"
                title={t('common:Created At') + ' / ' + t('common:Updated At')}
                render={(text, art: any) => (
                  <div>
                    {art.createdAt} <br /> {art.updatedAt ? art.updatedAt : ''}
                  </div>
                )}
                width={170}
              />
              <Column
                key="status"
                dataIndex="status"
                title={t('common:Status')}
                render={translateStatus}
                filters={statusFilterOptions}
                align={'center'}
                filteredValue={filters.status}
                width={90}
              />
              <Column
                title={t('common:Options')}
                align="center"
                fixed="right"
                className="optionsList"
                key="edit"
                render={(_, article: { key: React.Key } & Article) => (
                  <Space wrap>
                    {article.status === 'approved' && (
                      <Tooltip title={t('common:Preview')}>
                        <Button
                          shape="round"
                          icon={<GlobalOutlined twoToneColor="#eb2f96" />}
                          onClick={() => handleArticlePreview(article)}
                        />
                      </Tooltip>
                    )}
                    {permissions.approve && (
                      <>
                        {article.status !== 'approved' && user?.role !== 'COMMERCIALIST' && (
                          <Tooltip title={t('article:Approve')}>
                            <Button
                              shape="round"
                              icon={<CheckCircleTwoTone twoToneColor="lime" />}
                              onClick={() => handleArticleApprove(article.id)}
                            />
                          </Tooltip>
                        )}
                        {article.status !== 'rejected' && user?.role !== 'COMMERCIALIST' && (
                          <Tooltip
                            title={
                              article.block !== null && !article?.block?.automatic
                                ? t('article:Article in block')
                                : t('article:Reject')
                            }
                          >
                            <Button
                              shape="round"
                              icon={<CloseCircleTwoTone twoToneColor="#eb2f96" />}
                              onClick={() => handleArticleReject(article.id)}
                              disabled={article.block !== null && !article?.block?.automatic}
                            />
                          </Tooltip>
                        )}
                      </>
                    )}
                    {(article.textType === 'Promo tekst' || article.textType === 'Native tekst') && (
                      <Tooltip title={t('common:Analytics')}>
                        <Button shape="round" icon={<EyeOutlined />} onClick={() => handlePreviewClick(article.id)} />
                      </Tooltip>
                    )}

                    {permissions.update && (
                      <Tooltip title={t('common:Edit')}>
                        <Button shape="round" icon={<EditTwoTone />} onClick={() => navigateToArticle(article.id)} />
                      </Tooltip>
                    )}
                    {permissions.delete && user?.role !== 'COMMERCIALIST' && (
                      <Tooltip
                        title={
                          article.block !== null && !article?.block?.automatic
                            ? t('article:Article in block')
                            : t('common:Delete')
                        }
                      >
                        <Button
                          shape="round"
                          icon={<DeleteTwoTone twoToneColor="#eb2f96" />}
                          onClick={() => openDeleteModal(article.id)}
                          disabled={article.block !== null && !article?.block?.automatic}
                        />
                      </Tooltip>
                    )}
                    {permissions.comments && user?.role !== 'COMMERCIALIST' && (
                      <Tooltip title={t('article:Comments')}>
                        <Button
                          shape="round"
                          icon={<CommentOutlined />}
                          onClick={() => navigateToComments(article.id)}
                        />
                      </Tooltip>
                    )}
                    {permissions.approve &&
                      article.status === 'approved' &&
                      user?.role !== 'COMMERCIALIST' &&
                      user?.role !== 'ALFATV' && (
                        <Tooltip title={t('article:Push')}>
                          <Button
                            shape="round"
                            icon={<UploadOutlined />}
                            onClick={() => openPushModal(article.id, article.push, article.title)}
                          />
                        </Tooltip>
                      )}
                  </Space>
                )}
              />
            </Table>
          </Row>
        )}
      </div>
      <Modal
        title={t('article:Delete article')}
        visible={deleteModalVisible}
        onOk={handleArticleDelete}
        confirmLoading={isDeletingArticle}
        onCancel={handleArticleDeleteCancel}
      >
        <p>{`${t('common:Delete Modal Text')} članak?`}</p>
      </Modal>
      <ArticleRejectionModal
        visible={showArticleRejectionModal}
        onConfirm={handleArticleRejection}
        onCancel={closeArticleRejectionModal}
      />
      <Modal
        title={t('article:Send push')}
        visible={pushModal}
        // onOk={handleArticlePush}
        onCancel={handleArticlePushCancel}
        footer={[
          <Button type="default" htmlType="button" onClick={handleArticlePushCancel}>
            {t('common:Cancel')}
          </Button>,
          <Button type="primary" htmlType="submit" onClick={handleArticlePush} disabled={isDisabled}>
            {t('article:Send push')}
          </Button>,
        ]}
      >
        {pushed && (
          <b>
            '{pushedTitle}'<br /> <span style={{ color: 'red' }}>već ima push notifikaciju.</span>
            <br />
          </b>
        )}
        <p>{`${t('common:Push Modal')} članak?`}</p>
      </Modal>
    </>
  );
};
