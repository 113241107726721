import React from 'react';
import { render } from 'react-dom';

import { Crash } from 'components/Crash';

import { ErrorBus } from 'helpers/ErrorBus';
import { HttpError } from 'helpers/http';
import { logger } from 'helpers/logger';

// TODO: Remove handler for HTTP/401 event

interface Props {
  bus: ErrorBus;
}

export class ErrorBoundary extends React.PureComponent<Props> {
  appUncaughtSub!: string;
  badRequestErrorSub!: string;
  forbiddenErrorSub!: string;
  networkErrorSub!: string;
  notFoundErrorSub!: string;
  offlineErrorSub!: string;
  serverErrorSub!: string;
  timeoutErrorSub!: string;
  unauthorizedErrorSub!: string;
  windowOnErrorSub!: string;

  componentDidMount() {
    const { bus } = this.props;

    // FIXME: Remove unknown conversion
    this.appUncaughtSub = bus.subscribeOnce('APP/UNCAUGHT', this.handleAppUncaughtError) as unknown as string;

    this.badRequestErrorSub = bus.subscribe('HTTP/400', this.handleServerErrors);
    this.forbiddenErrorSub = bus.subscribe('HTTP/403', this.handleForbiddenError);
    this.networkErrorSub = bus.subscribe('HTTP/NETWORK', this.handleNetworkError);
    this.notFoundErrorSub = bus.subscribe('HTTP/404', this.handleNotFoundError);
    this.offlineErrorSub = bus.subscribe('HTTP/OFFLINE', this.handleOfflineError);
    this.serverErrorSub = bus.subscribe('HTTP/5XX', this.handleServerErrors);
    this.timeoutErrorSub = bus.subscribe('HTTP/TIMEOUT', this.handleTimeoutError);
    this.unauthorizedErrorSub = bus.subscribe('HTTP/401', this.handleUnauthorizedError);
    this.windowOnErrorSub = bus.subscribe('WINDOW/ONERROR', this.handleWindowOnError);
  }

  componentWillUnmount() {
    const { bus } = this.props;

    bus.unsubscribe(this.appUncaughtSub);

    bus.unsubscribe(this.badRequestErrorSub);
    bus.unsubscribe(this.forbiddenErrorSub);
    bus.unsubscribe(this.networkErrorSub);
    bus.unsubscribe(this.notFoundErrorSub);
    bus.unsubscribe(this.offlineErrorSub);
    bus.unsubscribe(this.serverErrorSub);
    bus.unsubscribe(this.timeoutErrorSub);
    bus.unsubscribe(this.unauthorizedErrorSub);
  }

  handleServerErrors = (message: string, error: HttpError) => {
    logger('Error Boundry', 'Server error', error);
  };

  handleForbiddenError = (message: string, error: HttpError) => {
    logger('Error Boundry', 'Request forbidden', error);
  };

  handleTimeoutError = (message: string, error: HttpError) => {
    logger('Error Boundry', 'Timeout exceeded', error);
  };

  handleOfflineError = (message: string, error: HttpError) => {
    logger('Error Boundry', 'Check your internet connection', error);
  };

  handleNetworkError = (message: string, error: HttpError) => {
    logger('Error Boundry', 'Network problem detected', error);
  };

  handleNotFoundError = (message: string, error: HttpError) => {
    logger('Error Boundry', 'Page not found error', error);
  };

  handleUnauthorizedError = (message: string, error: HttpError) => {
    logger('Error Boundry', 'Unauthorized access', error);
  };

  handleWindowOnError = (message: string, error: Error) => {
    logger('Error Boundry', 'WINDOW/ONERROR', error);
  };

  handleAppUncaughtError = (message: string, error: Error) => {
    logger('Error Boundry', 'Uncaught error', error);

    render(<Crash />, document.getElementById('root'));
  };

  componentDidCatch = (error: Error) => {
    this.handleAppUncaughtError('APP/UNCAUGHT', error);
  };

  render() {
    return this.props.children;
  }
}
