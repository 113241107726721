export function sleep(seconds = 1) {
  return new Promise((resolve) => setTimeout(resolve, seconds * 1000));
}

export function getUnixtime(): number {
  return Math.floor(Date.now() / 1000);
}

export function hasValidCache(lastUpdated: number, max = 10): boolean {
  if (!navigator.onLine) {
    return true;
  }

  return lastUpdated + max * 60 > getUnixtime();
}

export function flattenObject(ob: {}) {
  var toReturn = {};

  for (var i in ob) {
    if (!ob.hasOwnProperty(i)) continue;

    if (typeof (ob as any)[i] == 'object' && (ob as any)[i] !== null) {
      var flatObject = flattenObject((ob as any)[i]);
      for (var x in flatObject) {
        if (!flatObject.hasOwnProperty(x)) continue;

        (toReturn as any)[i + '.' + x] = (flatObject as any)[x];
      }
    } else {
      (toReturn as any)[i] = (ob as any)[i];
    }
  }
  return toReturn;
}

export function unflattedObject(ob: {}) {
  let toReturn = {} as any;

  for (const [key, value] of Object.entries(ob)) {
    if (key.includes('.')) {
      const [parent, child] = key.split('.');
      if (!toReturn[parent]) {
        toReturn[parent] = {};
      }
      toReturn[parent][child] = value;
    } else {
      toReturn[key] = value;
    }
  }

  return toReturn;
}

export function getBase64(file: Blob | File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
}

export function capitalizeFirstLetter(text: string) {
  return text.charAt(0).toUpperCase() + text.slice(1).toLocaleLowerCase();
}
