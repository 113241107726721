import { HttpClient } from 'helpers/http';

import { IRSSService, RSSResponse } from 'types/services/rss';
export class RSSService implements IRSSService {
  constructor(private client: HttpClient) {}
  API = {
    rssAPI: '/api/rss',
  };
  fetchRSS = () => {
    const url = this.API.rssAPI;
    return this.client.get<RSSResponse>(url);
  };
}
