import { IRoute } from 'types/common';

import { InitResetPassword } from './InitResetPassword';
import { ResetPassword } from './ResetPassword';

export const routes: IRoute[] = [
  {
    component: InitResetPassword,
    exact: true,
    key: 'reset-password-init',
    path: '/reset-password/init',
  },
  {
    component: ResetPassword,
    exact: true,
    key: 'reset-password',
    path: '/reset-password',
  },
];
