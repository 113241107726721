import { RefSelectProps } from 'antd/es/select';
import { useRef, useState, useCallback, createElement, useEffect } from 'react';
import { useMutation } from 'react-query';

import { tagsService } from 'config/services';

import { Tag } from 'types/services/tags';

import { ThrottledSelect } from '../ThrottledSelect';

import CustomLabel from './CustomLabel';

interface Option {
  label: any;
  value: number;
}

const mapOption = (tag: Tag) =>
  ({
    value: tag.id,
    label: createElement(CustomLabel, { label: tag.name, count: tag.count }, null),
  } as Option);

const mapToTag = (option: Option) => {
  if (option.label.props === undefined) {
    return { id: option.value, name: option.label, count: 0 } as Tag;
  } else {
    return { id: option.value, name: option.label.props.label, count: option.label.props.count } as Tag;
  }
};

async function fetchTags(name: string): Promise<Option[]> {
  return tagsService.list({ page: 1, perPage: 20, name }).then((response) => response.data.map(mapOption));
}

interface TagInputProps {
  value?: Tag[];
  onChange?: (value: Tag[]) => void;
}

export const TagInput = ({ value = [], onChange }: TagInputProps) => {
  const innerValue = value.map(mapOption);
  const valueChanged = useRef(false);
  const options = useRef<Option[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const selectRef = useRef<RefSelectProps>(null);
  const listSelectedRef = useRef(false);

  const { isLoading, mutateAsync } = useMutation('createTag', (name: string) => tagsService.create(name));
  useEffect(() => {
    valueChanged.current = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleKeyDown = (e: any) => {
    const inputValue = e.target.value;
    const addNewTag = (t: Option) => {
      if (!innerValue.find((o) => o.value === t.value)) {
        onChange?.([...innerValue, t].map(mapToTag));
      }
      setSearchValue('');
    };

    const checkForExistingTag = (opts?: Option[]) => {
      let t = (opts || options.current).find((o) => o.label === inputValue);

      if (t) {
        addNewTag(t);
        return true;
      }
      return false;
    };

    if (e.key === 'Enter' && !isLoading) {
      e.target.value = '';

      setTimeout(() => {
        if (!valueChanged.current) {
          //if user selects an existing tag for nonexisting value entered, selected tag will have priority over creating a new tag
          if (listSelectedRef.current) {
            listSelectedRef.current = false;
            return;
          }

          if (inputValue && !checkForExistingTag()) {
            mutateAsync(inputValue)
              .then((newTag) => {
                addNewTag({ value: newTag.id, label: newTag.name });
                valueChanged.current = false;
              })
              .catch((e) => {
                if (e.payload !== undefined) {
                  if (e?.payload.error.detail?.includes('already exist')) {
                    tagsService.list({ page: 1, perPage: 10, name: inputValue }).then((response) => {
                      checkForExistingTag(response.data.map(mapOption));
                      response.data.forEach((r) => {
                        if (r.name === inputValue && r.count !== undefined) {
                          addNewTag({ label: { props: { label: searchValue, count: r.count } }, value: r.id });
                          valueChanged.current = false;
                          setSearchValue('');
                        }
                      });
                    });
                    return;
                  }
                }
                throw e;
              });
          }
        }
        valueChanged.current = false;
      }, 100);
    }
  };

  const handleOnSelect = () => {
    valueChanged.current = false;
    setSearchValue('');
    listSelectedRef.current = true;
  };

  const handleOptionsFetched = useCallback((newOptions: Option[]) => {
    options.current = newOptions;
  }, []);

  const handleSearchValueChange = (value: string) => {
    setSearchValue(value);
  };

  const handleBlur = () => {
    setSearchValue('');
  };

  return (
    <>
      <ThrottledSelect
        innerRef={selectRef}
        mode="multiple"
        value={innerValue}
        searchValue={searchValue}
        fetchOptions={fetchTags}
        onChange={(newValue) => {
          valueChanged.current = true;
          onChange?.(newValue.map(mapToTag));
        }}
        style={{ width: '100%' }}
        tokenSeparators={[',']}
        hideSelected
        defaultActiveFirstOption={false}
        onInputKeyDown={handleKeyDown}
        loading={isLoading}
        onSelect={handleOnSelect}
        onSearch={handleSearchValueChange}
        onOptionsFetched={handleOptionsFetched}
        onBlur={handleBlur}
        className="tag-input"
        /* tagRender={(props) => {
        return (
          <span className="ant-select-selection-item">
            <span className="ant-select-selection-item-content">
              <span className="1">{props.label}</span>
            </span>
            <span
              className="ant-select-selection-item-remove"
              unselectable="on"
              aria-hidden="true"
              style={{ userSelect: 'none' }}
              onClick={props.onClose}
            >
              <CloseOutlined />
            </span>
          </span>
        );
      }} */
      />
    </>
  );
};
