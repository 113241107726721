import { Input } from 'antd';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import ReactPlayer from 'react-player';

import { VideoBlock } from 'types/services/articles';

interface VideoBlockInteface {
  content: VideoBlock['content'];
  isEditing: boolean;
  onChange: (content: any) => void;
}

const VideoBlockComponent = ({ content, onChange, isEditing }: VideoBlockInteface) => {
  const { t } = useTranslation();
  const handleChange = (e: any) => {
    onChange({ src: e.target.value });
  };

  return isEditing ? (
    <div className="editing-container">
      <label htmlFor="video-url">{t('common:Link')}</label>
      <Input id="video-url" onChange={handleChange} defaultValue={content.src} />
    </div>
  ) : (
    <ReactPlayer url={content.src} />
  );
};

export default memo(VideoBlockComponent);
