import { Row, Table, Spin, DatePicker } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { statisticsService, usersService } from 'config/services';

import { HttpError } from 'helpers/http';

import { Articles } from 'routes/articles/Articles';

import { PaginationParams } from 'types/common';
import { PaginatedStatistics, StatisticsOptions, Statistic } from 'types/services/statistics';
import { PaginatedUsers } from 'types/services/users';

import { transformResource } from './utils';

export const Statistics = () => {
  const { Column } = Table;
  const { t } = useTranslation();
  const [pagination, setPagination] = useState<PaginationParams>({ page: 1, perPage: 10 });
  const [filters, setFilters] = useState<StatisticsOptions>({});

  const newDateFrom = new Date();
  newDateFrom.setDate(newDateFrom.getDate() - 7);

  const newDateTo = new Date();
  newDateTo.setDate(newDateTo.getDate() + 1);

  const [date1, setDate1] = useState(newDateFrom.toISOString().replace('T', ' ').substring(0, 10));
  const [date2, setDate2] = useState(newDateTo.toISOString().replace('T', ' ').substring(0, 10));
  const [authorId, setAuthorId] = useState<Number>(-1);
  const [textType, setTextType] = useState('');
  const [isBottomVisible, setIsBottomVisible] = useState(false);

  const {
    isLoading,
    isFetching,
    isError,
    data: response,
    error,
    refetch,
  } = useQuery<PaginatedStatistics, HttpError>(['listStatistics'], () => statisticsService.list(pagination, filters));

  const {
    isError: isErrorUsers,
    data: paginatedUsers,
    error: usersError,
  } = useQuery<PaginatedUsers, HttpError>('listUsers', () =>
    usersService.list({ page: 1, perPage: 1000, filters: {} }),
  );

  useEffect(() => {
    refetch();
  }, [pagination, filters, refetch]);

  useEffect(() => {
    setFilters({ dateOne: date1, dateTwo: date2, authorId: filters.authorId });
  }, [date1, date2, filters.authorId, refetch]);

  function onPaginationChange(page: number) {
    setPagination((previousPagination) => ({ ...previousPagination, page }));
  }

  function onShowSizeChange(current: number, pageSize: number) {
    setPagination({ ...pagination, page: current, perPage: pageSize });
  }

  let userArr: Array<number> = [];

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    setFilters({ authorId: filters.authorName });
    setPagination((p) => ({ ...p, page: 1 }));
  };

  const handleFromDate = (values: any) => {
    setDate1(values);
  };
  const handleToDate = (values: any) => {
    setDate2(values);
  };

  if (isError || isErrorUsers) {
    return (
      <div>
        <pre>{JSON.stringify(error || usersError, undefined, 2)}</pre>
      </div>
    );
  }

  if (!response || !paginatedUsers) {
    return (
      <div style={{ padding: '10px', textAlign: 'center' }}>
        <Spin size="large" />
      </div>
    );
  }

  const users = paginatedUsers.data;
  const userFilterOptions = users.map((user) => ({ text: `${user.firstName} ${user.lastName}`, value: user.id }));

  userFilterOptions.map((u) => userArr.push(u.value));

  function transform(stats: Statistic[]) {
    return stats.map((statistic) => transformResource(statistic));
  }

  const { data: statistics } = response;

  const { RangePicker } = DatePicker;
  const dateFormat = 'YYYY/MM/DD';

  const handlePreuzetiTekstClick = (element: any) => {
    setIsBottomVisible(true);
    setTextType('Preuzeti tekst');
    const currUser = element.authorName;
    const firstName = currUser.split(' ')[0];
    const lastName = currUser.slice(currUser.indexOf(' ') + 1);

    let isCountZero = false;

    users.forEach((m) => {
      if (m.firstName === firstName && m.lastName === lastName) {
        setAuthorId(m.id);
        isCountZero = true;
      }
    });
    if (isCountZero === false) {
      setAuthorId(-1);
    }
  };

  //function for text type: "Tekst drugog novinara"
  const handleTDRClick = (element: any) => {
    setIsBottomVisible(true);
    setTextType('Tekst drugog novinara');
    const currUser = element.authorName;
    const firstName = currUser.split(' ')[0];
    const lastName = currUser.slice(currUser.indexOf(' ') + 1);

    let isCountZero = false;

    users.forEach((m) => {
      if (m.firstName === firstName && m.lastName === lastName) {
        setAuthorId(m.id);
        isCountZero = true;
      }
    });
    if (isCountZero === false) {
      setAuthorId(-1);
    }
  };

  const handleAgencijskiTekstClick = (element: any) => {
    setIsBottomVisible(true);
    setTextType('Agencijski tekst');
    const currUser = element.authorName;
    const firstName = currUser.split(' ')[0];
    const lastName = currUser.slice(currUser.indexOf(' ') + 1);

    let isCountZero = false;

    users.forEach((m) => {
      if (m.firstName === firstName && m.lastName === lastName) {
        setAuthorId(m.id);
        isCountZero = true;
      }
    });
    if (isCountZero === false) {
      setAuthorId(-1);
    }
  };

  const handleAutorskiTekstClick = (element: any) => {
    setTextType('Autorski tekst');
    const currUser = element.authorName;
    const firstName = currUser.split(' ')[0];
    const lastName = currUser.slice(currUser.indexOf(' ') + 1);

    let isCountZero = false;

    users.forEach((m) => {
      if (m.firstName === firstName && m.lastName === lastName) {
        setAuthorId(m.id);
        isCountZero = true;
      }
    });
    if (isCountZero === false) {
      setAuthorId(-1);
    }
  };

  const handlePrevedeniTekstClick = (element: any) => {
    setIsBottomVisible(true);
    setTextType('Prevedeni tekst');
    const currUser = element.authorName;
    const firstName = currUser.split(' ')[0];
    const lastName = currUser.slice(currUser.indexOf(' ') + 1);

    let isCountZero = false;

    users.forEach((m) => {
      if (m.firstName === firstName && m.lastName === lastName) {
        setAuthorId(m.id);
        isCountZero = true;
      }
    });
    if (isCountZero === false) {
      setAuthorId(-1);
    }
  };

  const handlePromoTekstClick = (element: any) => {
    setIsBottomVisible(true);
    setTextType('Promo tekst');
    const currUser = element.authorName;
    const firstName = currUser.split(' ')[0];
    const lastName = currUser.slice(currUser.indexOf(' ') + 1);

    let isCountZero = false;

    users.forEach((m) => {
      if (m.firstName === firstName && m.lastName === lastName) {
        setAuthorId(m.id);
        isCountZero = true;
      }
    });
    if (isCountZero === false) {
      setAuthorId(-1);
    }
  };

  return (
    <>
      <div style={{ padding: '10px', textAlign: 'center' }}>
        <RangePicker
          defaultValue={[moment(response.dateOne, dateFormat), moment(response.dateTwo, dateFormat)]}
          format={dateFormat}
          onChange={(values, e) => {
            handleFromDate(values?.[0]?.format('YYYY-MM-DD'));
            handleToDate(values?.[1]?.format('YYYY-MM-DD'));
          }}
          style={{ marginBottom: 20 }}
        />
        <Row gutter={[8, 16]}>
          <Table
            bordered
            sticky
            size="middle"
            loading={isLoading || isFetching}
            dataSource={transform(statistics)}
            pagination={{
              onChange: onPaginationChange,
              onShowSizeChange,
              size: 'default',
              position: ['bottomCenter'],
              showSizeChanger: true,
              showLessItems: true,
              defaultPageSize: 10,
            }}
            onChange={handleTableChange}
          >
            <Column
              key="authorName"
              dataIndex="authorName"
              title={t('article:author')}
              filters={userFilterOptions}
              filterSearch
            />
            <Column
              key="autorskiTekst"
              dataIndex="autorskiTekst"
              title={t('article:textType4')}
              filterSearch
              render={(text, record: any) => (
                <a
                  style={{ color: '#598ad9' }}
                  onClick={() => handleAutorskiTekstClick(record)}
                  href={'/authorStatistics/' + date1 + '/' + date2 + '/' + authorId + '/' + record.autorskiTekst}
                >
                  {text}
                </a>
              )}
            />
            <Column
              key="preuzetiTekst"
              dataIndex="preuzetiTekst"
              title={t('article:textType1')}
              filterSearch
              render={(text, record: any) => (
                <button
                  style={{
                    color: '#598ad9',
                    backgroundColor: 'transparent',
                    border: 'none',
                    cursor: 'pointer',
                    textAlign: 'left',
                  }}
                  onClick={() => handlePreuzetiTekstClick(record)}
                >
                  {text}
                </button>
              )}
            />
            <Column
              key="tekstDrugogNovinara"
              dataIndex="tekstDrugogNovinara"
              title={t('article:textType3')}
              filterSearch
              render={(text, record: any) => (
                <button
                  style={{
                    color: '#598ad9',
                    backgroundColor: 'transparent',
                    border: 'none',
                    cursor: 'pointer',
                    textAlign: 'left',
                  }}
                  onClick={() => handleTDRClick(record)}
                >
                  {text}
                </button>
              )}
            />
            <Column
              key="agencijskiTekst"
              dataIndex="agencijskiTekst"
              title={t('article:textType2')}
              filterSearch
              render={(text, record: any) => (
                <button
                  style={{
                    color: '#598ad9',
                    backgroundColor: 'transparent',
                    border: 'none',
                    cursor: 'pointer',
                    textAlign: 'left',
                  }}
                  onClick={() => handleAgencijskiTekstClick(record)}
                >
                  {text}
                </button>
              )}
            />

            <Column
              key="prevedeniTekst"
              dataIndex="prevedeniTekst"
              title={t('article:textType5')}
              filterSearch
              render={(text, record: any) => (
                <button
                  style={{
                    color: '#598ad9',
                    backgroundColor: 'transparent',
                    border: 'none',
                    cursor: 'pointer',
                    textAlign: 'left',
                  }}
                  onClick={() => handlePrevedeniTekstClick(record)}
                >
                  {text}
                </button>
              )}
            />

            <Column
              key="promoTekst"
              dataIndex="promoTekst"
              title={t('article:textType6')}
              filterSearch
              render={(text, record: any) => (
                <button
                  style={{
                    color: '#598ad9',
                    backgroundColor: 'transparent',
                    border: 'none',
                    cursor: 'pointer',
                    textAlign: 'left',
                  }}
                  onClick={() => handlePromoTekstClick(record)}
                >
                  {text}
                </button>
              )}
            />
          </Table>
        </Row>
      </div>

      {authorId && (
        <Articles
          authorId={authorId}
          textType={textType}
          dateFrom={date1}
          dateTo={date2}
          status={['approved']}
          visible={isBottomVisible}
        ></Articles>
      )}
    </>
  );
};
