import editSvg from '@ant-design/icons-svg/inline-namespaced-svg/outlined/edit.svg';
//@ts-ignore
import MediumEditor from 'medium-editor';
//@ts-ignore
import rangy from 'rangy';
import 'rangy/lib/rangy-classapplier';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import Editor from 'react-medium-editor';

import './style.scss';

// @ts-ignore
var HighlighterButton = MediumEditor.extensions.button.extend({
  name: 'highlighter',
  tagNames: ['mark'],
  contentDefault: `<img src="${editSvg}" />`,
  contentFA: '<i class="fa fa-paint-brush"></i>',
  classList: ['custom-class-h2'],
  aria: 'Highlight',
  action: 'highlight',

  init: function () {
    // @ts-ignore
    MediumEditor.extensions.button.prototype.init.call(this);
    // @ts-ignore
    rangy.init();
    // @ts-ignore
    this.classApplier = rangy.createClassApplier('highlight', {
      elementTagName: 'span',
      normalize: true,
    });
  },

  handleClick: function (event: any) {
    this.classApplier.toggleSelection();
    this?.onHighLightCalled();

    // Ensure the editor knows about an html change so watchers are notified
    // ie: <textarea> elements depend on the editableInput event to stay synchronized
    this.base.checkContentChanged();
  },
});

export interface HTMLEditorProps {
  value?: string;
  initialValue?: string;
  highlighting: boolean;
  onHighLightCalled?: Function;
  onChange?: (value: string) => void;
  className?: string;
}

export const HTMLEditor = memo((props: HTMLEditorProps) => {
  const { t } = useTranslation();
  const { value, onChange, className, highlighting, onHighLightCalled } = props;

  const handleChange = (bodyText: string) => {
    onChange?.(bodyText);
  };

  const buttons = ['bold', 'italic', 'underline', 'link', 'anchor', 'h2'];
  if (highlighting) {
    buttons.push('highlighter');
  }

  const highlighterExtension = new HighlighterButton();
  highlighterExtension.onHighLightCalled = onHighLightCalled;

  return (
    <Editor
      className={className}
      tag="div"
      text={value}
      onChange={handleChange}
      options={{
        spellcheck: false,
        toolbar: {
          buttons,
        },
        placeholder: !!value
          ? false
          : {
              text: t('common:Type text'),
              hideOnClick: true,
            },
        keyboardCommands: {
          /* This example includes the default options for keyboardCommands,
                 if nothing is passed this is what it used */
          commands: [
            {
              command: 'bold',
              key: 'B',
              meta: true,
              shift: false,
              alt: false,
            },
            {
              command: 'italic',
              key: 'I',
              meta: true,
              shift: false,
              alt: false,
            },
            {
              command: 'underline',
              key: 'U',
              meta: true,
              shift: false,
              alt: false,
            },
          ],
        },
        extensions: {
          highlighter: highlighterExtension,
        },
      }}
    />
  );
});
