import { Redirect, Route } from 'react-router-dom';

import { IRoute } from 'types/common';

interface Props extends IRoute {
  isAuthenticated: boolean;
  to?: string;
}

export function RoutePublic({ component: Component, isAuthenticated, to = '/', ...rest }: Props) {
  return (
    <Route
      {...rest}
      render={(props) => (isAuthenticated ? <Redirect to={{ pathname: to }} /> : Component && <Component {...props} />)}
    />
  );
}
