import { HomeOutlined } from '@ant-design/icons';

import { withNavigation } from 'containers/Navigation';

import { IRoute, Item } from 'types/common';

import { Home } from './Home';

export const route: IRoute = {
  component: withNavigation(Home),
  exact: true,
  isPrivate: true,
  key: 'home',
  path: '/',
};

export const item: Item = {
  key: route.key,
  path: route.path as string,
  icon: HomeOutlined,
  rank: 1,
};
