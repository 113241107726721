import { PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, InputNumber, Upload, message } from 'antd';
import { Image as Picture } from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';
import { SetStateAction, useCallback, useEffect, useRef, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useMutation, useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';

import { EditCoverImageModal, ModalPromise } from 'components/EditImageModal/EditCoverImageModal';

import { crosswordsService } from 'config/services';

import { HttpError } from 'helpers/http';
import { getBase64 } from 'helpers/shared';

import { FileExtended } from 'types/files';
import { Image } from 'types/services/images';

import './Crossword.scss';

interface CrosswordI {
  dimensions: string;
  data: RowI[];
}

interface CellI {
  value: string;
  type: string;
}

interface RowI {
  row: CellI[];
}

interface UploadImagesProps {
  value?: Image | Image[];
  onChange?: (images: Image[]) => void;
  mode?: 'row' | 'card';
  maxCount?: number;
  multiple?: boolean;
}

export const Crossword = ({ value, mode = 'card', maxCount, multiple = false, onChange }: UploadImagesProps) => {
  const history = useHistory();
  const [crosswordWidth, setCrosswordWidth] = useState(1);
  const [crosswordHeight, setCrosswordHeight] = useState(1);
  const [cellType, setCellType] = useState('Odgovor');
  const [crossword, setCrossword] = useState<CrosswordI>({ dimensions: '0x0', data: [] });
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const editImageModalPromise = useRef<ModalPromise>();
  const editBackgroundImageModalPromise = useRef<ModalPromise>();
  const [selectedImage, setSelectedImage] = useState<{ isNewImage: boolean; image?: FileExtended | UploadFile }>();
  const [selectedBackgroundImage, setSelectedBackgroundImage] =
    useState<{ isNewImage: boolean; image?: FileExtended | UploadFile }>();

  const isCardMode = mode === 'card';

  const [latestURL, setLatestURL] = useState('');
  const [latestBackgroundImageURL, setLatestBackgroundImageURL] = useState('');

  const [imageQuestion, setImageQuestion] = useState('');

  const handleChange = useCallback(
    (files: UploadFile[]) => {
      setFileList(files);

      if (files.every((f) => f.status === 'done')) {
        onChange?.(files.map((f) => f.response as Image));
      }
    },
    [onChange],
  );

  const handleUploadChange = (info: any) => {
    const { fileList, file } = info;
    if (file.percent === 100) {
      const image = fileList.find((i: UploadFile) => i.uid === file.uid);
      image.url = file.response.imageUrl;
      image.uid = file.response.id;
      image.description = file.response.description;
      image.caption = file.response.caption;
      image.source = file.response.source;
    }
    handleChange(fileList);
  };

  const handleBackgroundUploadChange = (info: any) => {
    const { fileList, file } = info;
    if (file.percent === 100) {
      const image = fileList.find((i: UploadFile) => i.uid === file.uid);
      image.url = file.response.imageUrl;
      image.uid = file.response.id;
      image.description = file.response.description;
      image.caption = file.response.caption;
      image.source = file.response.source;
    }
    handleChange(fileList);
  };

  const customRequest = async (options: any) => {
    const { onSuccess = () => {}, onError = () => {} } = options;

    try {
      const uploadResult = await crosswordsService.uploadImage(options);
      setLatestURL(uploadResult.images[0].imageUrl);
      onSuccess({
        ...uploadResult,
      });
    } catch (error: any) {
      onError(error);
    }
  };

  const customBackgroundImageRequest = async (options: any) => {
    const { onSuccess = () => {}, onError = () => {} } = options;

    try {
      const uploadResult = await crosswordsService.uploadBackgroundImage(options);
      setLatestBackgroundImageURL(uploadResult.images[0].imageUrl);
      onSuccess({
        ...uploadResult,
      });
    } catch (error: any) {
      onError(error);
    }
  };

  const attachData = (file: UploadFile) => {
    return new Promise<Record<string, any>>(async (resolve, reject) => {
      if (!file.url) {
        const base64String = await getBase64(file as unknown as Blob);
        file.url = base64String;
      }
      resolve(file);
    });
  };

  const closeEditImageModal = () => {
    setSelectedImage(undefined);
  };

  const closeEditBackgroundImageModal = () => {
    setSelectedBackgroundImage(undefined);
  };

  const beforeUpload = async (file: File, fileList: File[]) => {
    const isImage = ['image/jpg', 'image/jpeg', 'image/png', 'image/gif'].includes(file.type);
    if (!isImage) {
      message.error(`Niste odabrali sliku!`);
      return Upload.LIST_IGNORE;
    }

    const modalPromise = new Promise<any>((resolve, reject) => {
      editImageModalPromise.current = { resolve, reject };
    });
    if (fileList.length > 1) {
      return Promise.resolve(true);
    }
    modalPromise.finally(closeEditImageModal);

    const fileExtended = file as unknown as FileExtended;
    const base64String = await getBase64(file);
    fileExtended.url = base64String;

    setSelectedImage({ isNewImage: true, image: fileExtended });
    return modalPromise;
  };

  const beforeBackgroundImageUpload = async (file: File, fileList: File[]) => {
    const isImage = ['image/jpg', 'image/jpeg', 'image/png', 'image/gif'].includes(file.type);
    if (!isImage) {
      message.error(`Niste odabrali sliku!`);
      return Upload.LIST_IGNORE;
    }

    const modalPromise = new Promise<any>((resolve, reject) => {
      editBackgroundImageModalPromise.current = { resolve, reject };
    });
    if (fileList.length > 1) {
      return Promise.resolve(true);
    }
    modalPromise.finally(closeEditBackgroundImageModal);

    const fileExtended = file as unknown as FileExtended;
    const base64String = await getBase64(file);
    fileExtended.url = base64String;

    setSelectedBackgroundImage({ isNewImage: true, image: fileExtended });
    return modalPromise;
  };

  const { id } = useParams<{ id?: string }>();

  const {
    isLoading,
    data: fetchedCrossword,
    refetch,
  } = useQuery<any, HttpError>(['getArticle', id], () => crosswordsService.get(parseInt(id || '')), {
    enabled: typeof id !== 'undefined' && !Number.isNaN(parseInt(id)),
  });

  const { isLoading: isLoadingCreate, mutateAsync: createCrossword } = useMutation(
    'createCrossword',
    ({
      crossword,
      imageUrl,
      backgroundImageUrl,
      imageQuestion,
    }: {
      crossword: any;
      imageUrl: string;
      backgroundImageUrl: string;
      imageQuestion: string;
    }) => crosswordsService.create({ crossword, imageUrl, backgroundImageUrl, imageQuestion }),
  );

  const { isLoading: isLoadingUpdate, mutateAsync: updateCrossword } = useMutation(
    'updateCrossword',
    ({
      crossword,
      imageUrl,
      backgroundImageUrl,
      imageQuestion,
      id,
    }: {
      crossword: any;
      imageUrl: string;
      backgroundImageUrl: string;
      imageQuestion: string;
      id: number;
    }) => crosswordsService.patch({ crossword, imageUrl, backgroundImageUrl, imageQuestion, id }),
  );

  useEffect(() => {
    if (fetchedCrossword) console.log('vrijednost data u fetchedCrossword', fetchedCrossword);
    if (fetchedCrossword) {
      setCrossword(fetchedCrossword[0].body);
      // console.log(fetchedCrossword[0].body.dimensions, 'dimenzije');
      const dimensions = fetchedCrossword[0].body.dimensions.split('x');
      // console.log(dimensions, 'splitane dimenzije');
      setCrosswordWidth(Number(dimensions[0]));
      setCrosswordHeight(Number(dimensions[1]));
      setLatestURL(fetchedCrossword[0].imageUrl);
      setImageQuestion(fetchedCrossword[0].imageQuestion);
      const textArea = document.getElementById('imageQuestion');
      if (textArea) textArea.innerHTML = fetchedCrossword[0].imageQuestion;
      setLatestBackgroundImageURL(fetchedCrossword[0].backgroundImageUrl);
    }
  }, [fetchedCrossword]);

  const saveCrossword = () => {
    const isUpdate = id && fetchedCrossword;

    const stringifiedCrossword = JSON.stringify(crossword);

    return isUpdate
      ? updateCrossword({
          crossword: stringifiedCrossword,
          imageUrl: latestURL,
          backgroundImageUrl: latestBackgroundImageURL,
          imageQuestion: imageQuestion,
          id: Number(id),
        })
      : createCrossword({
          crossword: stringifiedCrossword,
          imageUrl: latestURL,
          backgroundImageUrl: latestBackgroundImageURL,
          imageQuestion: imageQuestion,
        });
  };

  const onFinish = () => {
    const promise = saveCrossword();
    const isUpdate = id && fetchedCrossword;
    promise
      .then((fetchedCrossword: { id: any }) => {
        message.success(`Uspješno ${isUpdate ? 'ažurirana' : 'kreirana'} križaljka.`);
        if (!isUpdate) {
          const x = JSON.parse(JSON.stringify(fetchedCrossword));
          history.push(`/crossword/${x.createdCw[0].max}`);
        }
      })
      .catch((e: any) => {
        message.error(`Neuspješno ${isUpdate ? 'ažurirana' : 'kreirana'} križaljka.`);
        console.error(e);
      });
  };

  const saveCellValue = (row: number, column: number) => {
    const cell = document.getElementById('cell' + row + column) as HTMLInputElement;
    const cellValue =
      cellType === 'Odgovor' || cellType === 'Specijalni odgovor' ? cell?.value.replace(/ /g, '') : cell?.value;

    if (crossword.data.length > 0) {
      crossword.data[row].row[column].value = cellValue;
      crossword.data[row].row[column].type = cellType;
      cell.style.background =
        cellType === 'Pitanje'
          ? 'grey'
          : cellType === 'Duplo pitanje'
          ? '#c070db'
          : cellType === 'Prazno polje'
          ? 'green'
          : cellType === 'Specijalni odgovor'
          ? '#e36464'
          : 'white';
    }
  };

  const Cell = (row: number, column: number) => {
    const cellTypeLocal = crossword.data[row].row[column]?.type || cellType;

    return (
      <>
        <input
          id={'cell' + row + column}
          style={{
            width: 100,
            height: 100,
            background:
              cellTypeLocal === 'Pitanje'
                ? 'grey'
                : cellTypeLocal === 'Duplo pitanje'
                ? '#c070db'
                : cellTypeLocal === 'Prazno polje'
                ? 'green'
                : cellTypeLocal === 'Specijalni odgovor'
                ? '#e36464'
                : 'white',

            fontSize:
              cellTypeLocal === 'Pitanje'
                ? '1rem'
                : cellTypeLocal === 'Duplo pitanje'
                ? '1rem'
                : cellTypeLocal === 'Odgovor'
                ? '2.5rem'
                : cellTypeLocal === 'Prazno polje'
                ? '1rem'
                : cellTypeLocal === 'Duplo pitanje'
                ? '1rem'
                : cellTypeLocal === 'Specijalni odgovor'
                ? '2.5rem'
                : '1rem',
          }}
          maxLength={50}
          onChange={() => saveCellValue(row, column)}
          defaultValue={id && !isLoading ? crossword.data[row].row[column]?.value : ''}
        ></input>
      </>
    );
  };

  const renderRow = (rowIndex: number) => {
    const row = [];
    if (crosswordWidth < crossword.data[rowIndex].row.length) {
      crossword.data[rowIndex].row.length = crosswordWidth;
    }
    for (let i = 0; i < crosswordWidth; i++) {
      row.push(Cell(rowIndex, i));
      let cell: CellI = { value: '', type: 'Odgovor' };
      if (crossword.data[rowIndex].row.length < crosswordWidth) crossword.data[rowIndex].row.push(cell);
    }
    return (
      <div key={rowIndex} style={{ display: 'flex' }}>
        {row}
      </div>
    );
  };

  const renderGrid = () => {
    const grid = [];
    if (crosswordHeight < crossword.data.length) {
      crossword.data.length = crosswordHeight;
    }
    for (let i = 0; i < crosswordHeight; i++) {
      let row: RowI = { row: [] };
      if (crossword.data.length < crosswordHeight) crossword.data.push(row);
      grid.push(renderRow(i));
    }
    return grid;
  };

  useEffect(() => {
    console.log('vrijednost data u crossword', crossword);
  }, [crossword]);

  useEffect(() => {
    crossword.dimensions = crosswordWidth + 'x' + crosswordHeight;
    console.log(crosswordWidth, crosswordHeight, 'sirina i visina');
  }, [crosswordWidth, crosswordHeight, crossword]);

  const handleCellTypeChange = (changeEvent: { target: { value: SetStateAction<string> } }) => {
    setCellType(changeEvent.target.value);
  };

  return (
    <>
      <span>Molimo odaberite veličinu križaljke:</span>
      <br></br>
      <span>Širina: </span>
      <InputNumber
        min={1}
        max={15}
        value={crosswordWidth}
        onChange={(val: any) => {
          setCrosswordWidth(val);
        }}
      />
      <br></br>
      <span>Visina: </span>
      <InputNumber
        min={1}
        max={15}
        value={crosswordHeight}
        onChange={(val: any) => {
          setCrosswordHeight(val);
        }}
      />

      <br></br>
      <br></br>
      <div style={{ width: '300px' }}>
        <div className="radio" style={{ backgroundColor: 'white' }}>
          <label>
            <input type="radio" value="Odgovor" checked={cellType === 'Odgovor'} onChange={handleCellTypeChange} />
            Odgovor
          </label>
        </div>
        <div className="radio" style={{ backgroundColor: 'grey' }}>
          <label>
            <input type="radio" value="Pitanje" checked={cellType === 'Pitanje'} onChange={handleCellTypeChange} />
            Pitanje
          </label>
        </div>
        <div className="radio" style={{ backgroundColor: '#c070db' }}>
          <label>
            <input
              type="radio"
              value="Duplo pitanje"
              checked={cellType === 'Duplo pitanje'}
              onChange={handleCellTypeChange}
            />
            Duplo pitanje (Razdvojiti pitanja sa | )
          </label>
        </div>
        <div className="radio" style={{ backgroundColor: 'green' }}>
          <label>
            <input
              type="radio"
              value="Prazno polje"
              checked={cellType === 'Prazno polje'}
              onChange={handleCellTypeChange}
            />
            Prazno polje
          </label>
        </div>
        <div className="radio" style={{ backgroundColor: '#e36464' }}>
          <label>
            <input
              type="radio"
              value="Specijalni odgovor"
              checked={cellType === 'Specijalni odgovor'}
              onChange={handleCellTypeChange}
            />
            Specijalni odgovor
          </label>
        </div>
      </div>
      <br></br>
      <div>
        <Button type="primary" className="publish-article-button" htmlType="submit" onClick={onFinish}>
          {id ? 'Spasi izmjene' : 'Kreiraj'}
        </Button>
      </div>

      <br></br>

      {renderGrid()}

      <br></br>

      <div>
        <p>Pitanje/tekst koji će se nalaziti uz sliku:</p>

        <textarea
          id="imageQuestion"
          name="imageQuestion"
          rows={5}
          cols={33}
          placeholder="Npr. Znate li ko je glumac na slici?"
          onChange={(event: any) => {
            setImageQuestion(event.target.value);
          }}
        ></textarea>
      </div>

      <div className="coverRow">
        <p className="newCoverText">{'Dodaj novu sliku uz pitanje/tekst za križaljku'}</p>
        <DndProvider backend={HTML5Backend}>
          <Upload
            listType={isCardMode ? 'picture-card' : 'picture'}
            fileList={fileList}
            onChange={handleUploadChange}
            customRequest={customRequest}
            data={attachData}
            beforeUpload={beforeUpload}
            maxCount={maxCount}
            multiple={multiple}
            accept=".jpg,.jpeg,.png,.gif"
          >
            {isCardMode ? (
              <div>
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>{'Upload'}</div>
              </div>
            ) : (
              <Button icon={<UploadOutlined />}>{'Upload'}</Button>
            )}
          </Upload>
        </DndProvider>
      </div>
      {latestURL && (
        <div className="currentCover">
          <p className="currentCoverText">{'Trenutna slika'}</p>
          <Picture width={287} src={latestURL} />
        </div>
      )}

      <br></br>

      <div className="coverRow">
        <p className="newCoverText">{'Dodaj novu pozadinu za križaljku - min. širina mora biti 1440px'}</p>
        <DndProvider backend={HTML5Backend}>
          <Upload
            listType={isCardMode ? 'picture-card' : 'picture'}
            fileList={fileList}
            onChange={handleBackgroundUploadChange}
            customRequest={customBackgroundImageRequest}
            data={attachData}
            beforeUpload={beforeBackgroundImageUpload}
            maxCount={maxCount}
            multiple={multiple}
            accept=".jpg,.jpeg,.png,.gif"
          >
            {isCardMode ? (
              <div>
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>{'Upload'}</div>
              </div>
            ) : (
              <Button icon={<UploadOutlined />}>{'Upload'}</Button>
            )}
          </Upload>
        </DndProvider>
      </div>
      {latestBackgroundImageURL && (
        <div className="currentCover">
          <p className="currentCoverText">{'Trenutna pozadina'}</p>
          <Picture width={287} src={latestBackgroundImageURL} />
        </div>
      )}

      {/* crossword question image */}
      <EditCoverImageModal
        image={selectedImage?.image}
        visible={!!selectedImage}
        isNewImage={selectedImage?.isNewImage}
        squareThumbnailEdit={false}
        modalPromise={editImageModalPromise.current}
        onCancel={closeEditImageModal}
        isCover={false}
        isCrossword={true}
      />

      {/* background image */}
      <EditCoverImageModal
        image={selectedBackgroundImage?.image}
        visible={!!selectedBackgroundImage}
        isNewImage={selectedBackgroundImage?.isNewImage}
        squareThumbnailEdit={false}
        modalPromise={editBackgroundImageModalPromise.current}
        onCancel={closeEditBackgroundImageModal}
        isCover={false}
        isCrossword={true}
        isCrosswordBackground={true}
      />
    </>
  );
};
