import { SettingOutlined } from '@ant-design/icons';

import { withNavigation } from 'containers/Navigation';

import { IRoute, Submenu } from 'types/common';

import { Crossword } from './Crossword';
import { Crosswords } from './Crosswords';

export const routes: IRoute[] = [
  {
    component: withNavigation(Crosswords),
    exact: true,
    key: 'list-crosswords',
    path: '/crosswords',
    isPrivate: true,
  },
  {
    component: withNavigation(Crossword),
    exact: true,
    key: 'create-crosswords',
    path: '/crossword/new',
    isPrivate: true,
  },
  {
    component: withNavigation(Crossword),
    exact: true,
    key: 'crossword',
    path: '/crossword/:id',
    isPrivate: true,
  },
];

export const submenu: Submenu = {
  key: 'crossword',
  icon: SettingOutlined,
  rank: 6,
  items: routes.slice(0, 2).map((route) => ({ key: route.key, path: route.path as string })),
};
