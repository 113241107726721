import omit from 'lodash/omit';
import { useState, useEffect, useRef } from 'react';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';

import { BlockType } from 'types/services/articles';
import { Image } from 'types/services/images';

import Block, { isEditable } from './Block';
import Toolbar from './Toolbar';

import './style.scss';

export type ExtendedBlockType = BlockType & { editable: boolean; isEditing: boolean; key: number };

interface BlocksEditorProps {
  blocks: BlockType[];
  images: Image[];
  articleId?: number;
  highlighting: boolean;
  onHighLightCalled?: Function;
  articleType?: string;
  onChange: (blocks: BlockType[]) => void;
  onImagesChange: (images: Image[]) => void;
  onThumnailSelect: (image: Image) => void;
}

const BlocksEditor = ({
  blocks,
  images,
  articleId,
  highlighting = false,
  onHighLightCalled,
  articleType,
  onChange,
  onImagesChange,
  onThumnailSelect,
}: BlocksEditorProps) => {
  const [internalBlocks, setInternalBlocks] = useState<ExtendedBlockType[]>([]);
  const indexInEdit = useRef<{ [key: string]: boolean }>({});
  const disableGallery = !!blocks.find((block) => block.type === 'images' && block.content.imageIds.length > 1);
  useEffect(() => {
    setInternalBlocks(
      blocks.map(
        (block, index) =>
          ({
            ...block,
            isEditing: indexInEdit.current[index],
            key: index,
            editable: isEditable(block.type),
          } as ExtendedBlockType),
      ),
    );
  }, [blocks]);

  const handleChange = (blocks: ExtendedBlockType[]) => {
    onChange((blocks || internalBlocks).map((block) => omit(block, ['isEditing', 'key', 'editable']) as BlockType));
  };

  const handleBlockChange = (block: ExtendedBlockType, index: number) => {
    const copy = [...internalBlocks];
    copy.splice(index, 1, block);
    setInternalBlocks(copy);
    handleChange(copy);
  };

  const handleSetEditing = (index: number, isEditing: boolean) => {
    const copy = [...internalBlocks];
    copy[index].isEditing = isEditing;
    if (isEditing) {
      indexInEdit.current[index] = true;
    } else {
      delete indexInEdit.current[index];
    }

    setInternalBlocks(copy);
  };

  const handleAddNewBlock = (type: string) => {
    let newBlock: any;
    switch (type) {
      case 'text':
        newBlock = { content: { body: '' } };
        break;
      case 'images':
        newBlock = { content: { imageIds: [] } };
        indexInEdit.current[internalBlocks.length] = true;
        break;
      case 'video':
        newBlock = { content: { src: '' } };
        indexInEdit.current[internalBlocks.length] = true;
        break;
      case 'quote':
        newBlock = { content: { text: '', author: '' } };
        break;
      case 'related':
        newBlock = {
          type: 'related',
          content: {
            id: null,
          },
        };
        indexInEdit.current[internalBlocks.length] = true;
        break;
      case 'embedded':
        newBlock = { content: { body: '' } };
        indexInEdit.current[internalBlocks.length] = true;
        break;
      case 'lineBreak':
        newBlock = { content: { body: '<hr>' } };
        break;
    }
    if (newBlock) {
      newBlock.type = type;
      onChange([...internalBlocks, newBlock]);
    }
  };

  const handleRemove = (index: number) => {
    const copy = [...internalBlocks];
    copy.splice(index, 1);
    setInternalBlocks(copy);
    handleChange(copy);
  };

  const onDragEnd = (result: DropResult) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const newList = [...internalBlocks];
    newList.splice(result.destination.index, 0, newList.splice(result.source.index, 1)[0]);

    setInternalBlocks(newList);
    handleChange(newList);
  };

  return (
    <div className="blocks-editor">
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div className="blocks-editor-body" {...provided.droppableProps} ref={provided.innerRef}>
              {internalBlocks.map((block, index) => (
                <>
                  {articleType === 'special' && index === 0 && (
                    <div style={{ color: 'red' }}>Molimo da unesete jednu naslovnu sliku za desktop formata 8:10</div>
                  )}
                  {articleType === 'special' && index === 1 && (
                    <div style={{ color: 'red' }}>Molimo da unesete jednu naslovnu sliku za mobitel formata 16:9</div>
                  )}
                  <Block
                    key={block.key}
                    block={block}
                    index={index}
                    images={images}
                    articleId={articleId}
                    highlighting={highlighting}
                    isEditing={block.isEditing}
                    onHighLightCalled={onHighLightCalled}
                    onChange={handleBlockChange}
                    onImagesChange={onImagesChange}
                    onDelete={handleRemove}
                    onSetEditing={handleSetEditing}
                    onThumnailSelect={onThumnailSelect}
                    articleType={articleType}
                  />
                </>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {articleType !== 'video' && <Toolbar onClick={handleAddNewBlock} disableGallery={disableGallery} />}
    </div>
  );
};

export default BlocksEditor;
