import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useRef } from 'react';

export interface FileUploadButtonProps {
  handleFile: (file: File) => void;
  accept?: string;
}

export const FileUploadButton = ({ handleFile, accept = 'image/*' }: FileUploadButtonProps) => {
  const hiddenFileInput = useRef<HTMLInputElement>(null);

  const handleClick = (event: any) => {
    hiddenFileInput.current?.click();
  };

  const handleChange = (event: any) => {
    const fileUploaded = event.target.files[0];
    handleFile(fileUploaded);
  };
  return (
    <>
      <Button type="primary" icon={<PlusOutlined />} onClick={handleClick} />
      <input type="file" ref={hiddenFileInput} onChange={handleChange} style={{ display: 'none' }} accept={accept} />
    </>
  );
};
