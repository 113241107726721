import { PlusOutlined, SearchOutlined, CaretRightOutlined, DeleteTwoTone, CloseOutlined } from '@ant-design/icons';
import { Form, Button, Table, Spin, Input, InputRef, Space, Tooltip, Modal, message } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { FormInstance } from 'antd/lib/form';
import { useState, useEffect, useRef, useContext, createContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useMutation } from 'react-query';

import { tagsService } from 'config/services';

import { HttpError } from 'helpers/http';

import { Tag, PaginatedTags, TagPaginationParams } from 'types/services/tags';

import './style.scss';

const EditableContext = createContext<FormInstance<any> | null>(null);

interface EditableRowProps {
  index: number;
}

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

interface EditableCellProps {
  title: React.ReactNode;
  isEditing: boolean;
  children: React.ReactNode;
  dataIndex: keyof Tag;
  record: Tag;
  handleSave: (record: Tag) => void;
  handleCancel: () => void;
}

const EditableCell: React.FC<EditableCellProps> = ({
  title,
  isEditing,
  children,
  dataIndex,
  record,
  handleSave,
  handleCancel,
  ...restProps
}) => {
  const inputRef = useRef<InputRef>(null);
  const form = useContext(EditableContext)!;
  const { t } = useTranslation();

  const save = async () => {
    try {
      const values = await form.validateFields();

      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  const handleOnKeyDown = (event: any) => {
    if (event.keyCode === 27) {
      handleCancel();
    }
  };

  let childNode = children;

  childNode = isEditing ? (
    <Form.Item
      style={{ margin: 0 }}
      name={dataIndex}
      rules={[
        {
          required: true,
          message: t('common:Field is required'),
        },
      ]}
    >
      <Input ref={inputRef} onPressEnter={save} onBlur={save} onKeyDown={handleOnKeyDown} />
    </Form.Item>
  ) : (
    children
  );

  return <td {...restProps}>{childNode}</td>;
};

export const Tags = () => {
  const { t } = useTranslation();
  const [pagination, setPagination] = useState<TagPaginationParams>({ page: 1, perPage: 8, name: '' });
  const [filters, setFilters] = useState<Partial<Tag>>({});
  const filterInputNode = useRef<InputRef>(null);
  const [searchedColumn, setSearchedColumn] = useState('');
  const [tags, setTags] = useState<Tag[]>([]);
  const [isAddingNew, setIsAddingNew] = useState(false);
  const [tagsForMerge, setTagsForMerge] = useState<Tag[]>([]);
  const [tagIdForDeleting, setTagIdForDeleting] = useState<number>(0);
  const selectedTagForMerge = useRef<Tag | undefined>();

  const {
    isLoading,
    isFetching,
    isError,
    data: response,
    error,
    refetch,
  } = useQuery<PaginatedTags, HttpError>(['listTags'], () => tagsService.list(pagination));

  const { isLoading: isCreatingTag, mutateAsync: createArticle } = useMutation('createTag', (name: string) =>
    tagsService.create(name),
  );

  const { isLoading: isMergingTags, mutateAsync: mergeTags } = useMutation(
    'mergeTags',
    ({ tagIds, id }: { tagIds: number[]; id: number }) => tagsService.merge(tagIds, id),
  );

  const { isLoading: isDeletingTag, mutateAsync: deleteTag } = useMutation('deleteTag', (id: number) =>
    tagsService.remove(id),
  );

  function transform(tags: Tag[]) {
    return tags.map((tag) => ({
      key: tag.id,
      id: tag.id,
      name: tag.name,
      count: tag.count,
      createdAt: tag.createdAt
        .slice(8, 10)
        .concat('.', tag.createdAt.slice(5, 7), '.', tag.createdAt.slice(0, 4), ' - ', tag.createdAt.slice(11, 16)),
      updatedAt: tag.updatedAt
        .slice(8, 10)
        .concat('.', tag.updatedAt.slice(5, 7), '.', tag.updatedAt.slice(0, 4), ' - ', tag.updatedAt.slice(11, 16)),
    }));
  }

  useEffect(() => {
    refetch();
  }, [pagination, refetch]);

  useEffect(() => {
    if (response?.data) {
      setTags(transform(response?.data));
    }
  }, [response]);

  function onPaginationChange(page: number) {
    setPagination((previousPagination) => ({ ...previousPagination, page }));
  }

  function onShowSizeChange(current: number, pageSize: number) {
    setPagination({ ...pagination, page: current, perPage: pageSize });
  }

  const getColumnSearchProps = (dataIndex: keyof Tag) => ({
    filterDropdown: () => (
      <div style={{ padding: 8 }}>
        <Input.Search
          value={filters[dataIndex]}
          placeholder={t('common:Search term')}
          onChange={({ target }) => setFilters({ ...filters, [dataIndex]: target.value })}
          onSearch={() => setPagination({ ...pagination, ...filters, page: 1 })}
          ref={(node) => {
            if (searchedColumn === dataIndex) {
              (filterInputNode as any).current = node;
            }
          }}
        />
      </div>
    ),
    filterIcon: (_: boolean) => {
      const isFiltered = !!filters[dataIndex];
      return <SearchOutlined style={{ color: isFiltered ? '#1890ff' : undefined }} />;
    },
    onFilterDropdownVisibleChange: (visible: boolean) => {
      if (visible) {
        setTimeout(() => {
          setSearchedColumn(dataIndex);
          filterInputNode.current?.select();
        }, 100);
      }
    },
  });

  // rowSelection object indicates the need for row selection
  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: Tag[]) => {
      selectedTagForMerge.current = selectedRows[0];
    },
  };

  const handleSave = (row: Tag) => {
    createArticle(row.name)
      .then((tag) => {
        setTags([...transform([tag]), ...tags.slice(1)]);
        message.success(t('common:Successfully Created', { resource: t('article:Tag') }));
        setIsAddingNew(false);
      })
      .catch((e) => {
        message.error(
          t('common:Action failed', {
            action: t('common:Creation'),
            resource: t('article:Tag').toLowerCase(),
            suffix: 'a',
          }),
        );
      });
  };

  const cancelAdding = () => {
    setIsAddingNew(false);
    setTags(tags.slice(1));
  };

  const selectTagForMerge = (tag: Tag) => {
    if (!tagsForMerge.find((t) => t.id === tag.id)) {
      setTagsForMerge([...tagsForMerge, tag]);
    }
  };

  const deselectTagForMerge = (tag: Tag) => {
    setTagsForMerge(tagsForMerge.filter((t) => t.id !== tag.id));
  };

  const handleMergeTags = async () => {
    const selectedTagId = selectedTagForMerge.current?.id;
    if (selectedTagForMerge.current && selectedTagId) {
      mergeTags({ tagIds: tagsForMerge.map((t) => t.id).filter((id) => id !== selectedTagId), id: selectedTagId })
        .then(() => {
          selectedTagForMerge.current = undefined;
          setTagsForMerge([]);
          refetch();
          message.success(t('common:Successfully Updated', { resource: t('article:tags') }));
        })
        .catch(() => {
          message.error(t('settings:Merge tags failed'));
        });
    }
  };

  const handleDeleteTag = async (id: number) => {
    setTagIdForDeleting(id);
  };

  const confirmTagDelete = () => {
    deleteTag(tagIdForDeleting)
      .then((tag) => {
        message.success(t('common:Successfully Deleted', { resource: t('article:Tag') }));
        setIsAddingNew(false);
        refetch();
        setTagsForMerge(tagsForMerge.filter((t) => t.id !== tagIdForDeleting));
        if (selectedTagForMerge.current?.id === tagIdForDeleting) {
          selectedTagForMerge.current = undefined;
        }
        setTagIdForDeleting(0);
      })
      .catch((e) => {
        message.error(
          t('common:Action failed', {
            action: t('common:Deletion'),
            resource: t('article:Tag').toLowerCase(),
            suffix: 'a',
          }),
        );
      });
  };

  const cancelTagDelete = () => {
    if (isDeletingTag) return;
    setTagIdForDeleting(0);
  };

  const createNew = () => {
    setIsAddingNew(true);
    setTags([{ id: 0, name: '', createdAt: '', updatedAt: '' }, ...tags]);
  };

  const tagColumns = [
    {
      key: 'name',
      title: t('common:Name'),
      dataIndex: 'name',
      onCell: (record: Tag) => ({
        record,
        isEditing: isAddingNew && !record.name,
        title: t('common:Name'),
        dataIndex: 'name',
        handleSave: handleSave,
        handleCancel: cancelAdding,
      }),
      ...getColumnSearchProps('name'),
    },
    {
      key: 'createdAt',
      dataIndex: 'createdAt',
      title: t('common:Created At'),
    },
    {
      key: 'updatedAt',
      dataIndex: 'updatedAt',
      title: t('common:Updated At'),
    },
    {
      key: 'count',
      dataIndex: 'count',
      title: t('common:Count'),
    },
    {
      title: t('common:Options'),
      width: '130px',
      key: 'edit',
      render: (_: any, tag: Tag) => (
        <Space>
          <Tooltip title={t('common:Select')}>
            <Button shape="round" icon={<CaretRightOutlined />} onClick={() => selectTagForMerge(tag)} />
          </Tooltip>
          <Tooltip title={t('common:Delete')}>
            <Button
              shape="round"
              icon={<DeleteTwoTone twoToneColor="#eb2f96" />}
              onClick={() => handleDeleteTag(tag.id)}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];

  const tagMergeColumns: ColumnsType<Tag> = [
    ...tagColumns.slice(0, -1),
    {
      title: t('common:Options'),
      key: 'edit',
      align: 'right',
      render: (_: any, tag: Tag) => (
        <Space>
          <Tooltip title={t('common:Delete')}>
            <Button shape="round" icon={<CloseOutlined />} onClick={() => deselectTagForMerge(tag)} />
          </Tooltip>
        </Space>
      ),
    },
  ];

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  if (isError) {
    return (
      <div>
        <pre>{JSON.stringify(error, undefined, 2)}</pre>
      </div>
    );
  }

  if (!response) {
    return (
      <div style={{ padding: '10px', textAlign: 'center' }}>
        <Spin size="large" />
      </div>
    );
  }

  const { total } = response.pagination;

  return (
    <>
      <div style={{ padding: '10px' }} className="flex">
        <Table
          className="w-1/2"
          bordered
          sticky
          size="middle"
          loading={isLoading || isFetching || isCreatingTag || isDeletingTag}
          dataSource={tags}
          title={() => (
            <Button
              onClick={createNew}
              type="primary"
              icon={<PlusOutlined />}
              disabled={isAddingNew}
              id="create-new-tag"
            >
              {t('common:Add new', { genderSuffix: 'i' })}
            </Button>
          )}
          columns={tagColumns}
          components={components}
          pagination={{
            onChange: onPaginationChange,
            onShowSizeChange,
            size: 'default',
            position: ['bottomCenter'],
            showSizeChanger: true,
            showLessItems: true,
            current: pagination.page,
            pageSize: pagination.perPage,
            total,
            disabled: isAddingNew,
          }}
        />
        <Table
          className="w-1/2"
          style={{ marginLeft: '10px' }}
          bordered
          sticky
          size="middle"
          dataSource={tagsForMerge}
          title={() => (
            <Button
              onClick={handleMergeTags}
              type="primary"
              disabled={tagsForMerge.length < 2 || isMergingTags || isLoading || isCreatingTag}
            >
              {t('settings:Merge tags')}
            </Button>
          )}
          rowSelection={{
            type: 'radio',
            ...rowSelection,
          }}
          pagination={false}
          columns={tagMergeColumns}
        />
      </div>
      <Modal
        title={t('common:Delete Resource', { resource: t('article:Tag'), genderSuffix: 'a' })}
        visible={!!tagIdForDeleting}
        onOk={confirmTagDelete}
        confirmLoading={isDeletingTag}
        onCancel={cancelTagDelete}
      >
        <p>
          {t('common:Delete Modal Text')}
          <b>{` ${tags.find((tag) => tag.id === tagIdForDeleting)?.name}?`}</b>
        </p>
      </Modal>
    </>
  );
};
