import { SaveOutlined } from '@ant-design/icons';
import { Button, Drawer, Form, Spin, Input, message } from 'antd';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import { imagesService } from 'config/services';

import { ImageUpdate, Image } from 'types/services/images';

interface ImageDrawerProps {
  open: boolean;
  image?: ImageUpdate;
  refetchImages: () => {};
  onCancel: () => void;
}

export const ImageDrawer = ({ open, image, refetchImages, onCancel }: ImageDrawerProps) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const { isLoading: isUpdatingImage, mutateAsync: updateImage } = useMutation('updateImage', (image: ImageUpdate) =>
    imagesService.update(image),
  );

  useEffect(() => {
    if (open && image) {
      form.setFieldsValue({
        source: image.source,
        description: image.description,
      });
    }
  }, [open, image, form]);

  const onFormSubmit = async (fields: Pick<Image, 'source' | 'description'>) => {
    try {
      if (image) await updateImage({ ...image, ...fields });
      message.success(
        t(`common:Successfully Updated`, {
          resource: t('images:Image'),
          genderSuffix: 'a',
        }),
      );
    } catch {
      message.error(t('common:Action failed', { action: t(`common:Update`) }));
    }

    handleCancel();
    refetchImages();
  };

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  return (
    <Drawer
      width="500"
      placement="right"
      onClose={handleCancel}
      visible={open}
      title={
        <div>
          <span>{t(`settings:Edit image`)}</span>
        </div>
      }
      footer={[
        <div style={{ float: 'right' }}>
          <Button style={{ marginRight: '10px' }} key="back" onClick={handleCancel} disabled={isUpdatingImage}>
            {t('common:Cancel')}
          </Button>

          <Button key="submit" type="primary" icon={<SaveOutlined />} disabled={isUpdatingImage} onClick={form.submit}>
            {t('common:Save')}
          </Button>
        </div>,
      ]}
    >
      <div>
        {isUpdatingImage ? (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Spin size="large" />
          </div>
        ) : (
          open && (
            <Form
              form={form}
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 20 }}
              layout="horizontal"
              size="middle"
              onFinish={onFormSubmit}
            >
              <Form.Item name="source" label={t('common:Source')} rules={[{ required: true }]}>
                <Input />
              </Form.Item>
              <Form.Item name="description" label={t('common:Description')} rules={[{ required: true }]}>
                <Input />
              </Form.Item>
            </Form>
          )
        )}
      </div>
    </Drawer>
  );
};
