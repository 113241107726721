import { HttpClient } from 'helpers/http';

import { ArticleBlockTypes, IArticleBlockTypesService } from '../../types/services/article-blocks';

export class ArticleBlockTypesService implements IArticleBlockTypesService {
  constructor(private client: HttpClient) {}

  API = {
    articleBlockTypes: '/api/article-block-types',
  };

  list = () => {
    const url = this.API.articleBlockTypes;
    return this.client.get<ArticleBlockTypes>(url);
  };
}
