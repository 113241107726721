import { EyeInvisibleOutlined, EyeTwoTone, SmileOutlined } from '@ant-design/icons';
import { Form, Input, Button, notification } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { authService } from 'config/services';

import './index.scss';

export const ResetPassword = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const history = useHistory();
  const [form] = Form.useForm();

  const onFinish = async (values: any) => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries()).token;
    const { password } = values;
    setIsLoading(true);
    await authService.resetPassword(password, params);
    openNotification();
    setIsLoading(false);
    history.push('/login');
  };

  const onFinishFailed = (errorInfo: any) => {
    // TODO: Add error handler
    // console.log('Failed:', errorInfo);
  };

  const openNotification = () => {
    notification.open({
      message: t('users:Password change confirmed!'),
      description: t('users:Please proceed to login with your new credentials!'),
      icon: <SmileOutlined style={{ color: 'red' }} />,
    });
  };

  return (
    <div className="reset-container">
      <div className="container">
        <div className="reset-page">
          <Form
            name="normal_reset"
            className="reset-form"
            form={form}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              name="password"
              rules={[{ required: true, message: t('users:Please enter your new password!') }]}
            >
              <Input.Password
                placeholder={t('users:Enter your new password!')}
                iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
              />
            </Form.Item>
            <Form.Item
              name="confirm-password"
              rules={[
                { required: true, message: t('users:Confirm your new password!') },
                {
                  validator: async (_) => {
                    var password = form.getFieldValue('password');
                    var newPassword = form.getFieldValue('confirm-password');
                    if (password !== newPassword) {
                      setIsDisabled(true);
                      return Promise.reject(t('users:Passwords do not match!'));
                    }
                    setIsDisabled(false);
                  },
                },
              ]}
            >
              <Input.Password
                placeholder={t('users:Confirm your new password!')}
                iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
              />
            </Form.Item>
            <Form.Item>
              <Button
                disabled={isDisabled}
                type="primary"
                loading={isLoading}
                htmlType="submit"
                className="reset-form-button"
              >
                {t('users:Change password')}
              </Button>
            </Form.Item>
            <Button
              onClick={() => {
                history.push('/login');
              }}
            >
              {t('users:Back to login screen')}
            </Button>
          </Form>
        </div>
        <div className="logo">
          <img alt="logo" src="/avaz-logo.png" />
        </div>
      </div>
    </div>
  );
};
