import { CheckCircleTwoTone, EyeOutlined, FormOutlined, SelectOutlined } from '@ant-design/icons';
import { Image, ImageProps, Tooltip } from 'antd';
import cx from 'classnames';
import { useRef, MutableRefObject, MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import colors from 'theme.override';

import './style.scss';
const primaryColor = colors['primary-color'];

export const SelectableImage = (
  props: ImageProps & {
    uid: string;
    createAt: string;
    description?: string;
    selected?: boolean;
    onSelection: (image: any) => void;
    onImageEdit: (uid: string) => void;
  },
) => {
  const { t } = useTranslation();
  const { uid, selected, onSelection, onImageEdit, createAt, description, ...rest } = props;
  const maskRef = useRef(null) as MutableRefObject<HTMLDivElement | null>;

  const onClick: MouseEventHandler<HTMLDivElement> = (e) => {
    stopEvent(e);
    onSelection(props.id);
  };

  const stopEvent = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const handleEditImage: MouseEventHandler<HTMLDivElement> = (e) => {
    stopEvent(e);
    onImageEdit(uid);
  };

  return (
    <div className="relative flex flex-col">
      <Image
        preview={{
          mask: (
            <div
              ref={maskRef}
              className="ant-image-mask relative flex flex-wrap h-full w-full"
              role="button"
              aria-hidden="true"
              tabIndex={0}
            >
              <div className="image-action-btn h-1/4 w-1/2" role="button" aria-hidden="true" tabIndex={0}>
                <Tooltip title={t('common:View')}>
                  <EyeOutlined />
                </Tooltip>
              </div>
              <div
                className="image-action-btn h-1/4 w-1/2"
                role="button"
                aria-hidden="true"
                tabIndex={0}
                onClick={handleEditImage}
              >
                <Tooltip title={t('common:Edit')}>
                  <FormOutlined />
                </Tooltip>
              </div>
              <div
                className="image-action-btn h-1/2 w-full"
                role="button"
                aria-hidden="true"
                tabIndex={0}
                onClick={onClick}
              >
                <Tooltip title={t('common:Select')}>
                  <SelectOutlined />
                </Tooltip>
              </div>
            </div>
          ),
        }}
        {...rest}
      />
      {selected && (
        <CheckCircleTwoTone twoToneColor={primaryColor} className={cx('absolute', 'selected-image-badge')} />
      )}
      <span className="text-center">
        {/* {createAt
          .slice(8, 10)
          .concat('.', createAt.slice(5, 7), '.', createAt.slice(0, 4), ' - ', createAt.slice(11, 16))} */}
        {description?.slice(0, 22)}
      </span>
    </div>
  );
};
